import React from 'react';
import '../App.css';

export default class TableHeaderlist extends React.Component {
    render() {
        const classes = "Table-header-title " + this.props.className
        return (
            !this.props.widthout ?
                <th className={classes} style={{ ...this.props.style, ...this.props.title === "#" && { width: 60 } }}>
                    {this.props.title}
                </th> :
                <div style={{ ...this.props.style, ...this.props.title === "#" && { width: 60, paddingTop: 8, paddingLeft: 6, color: '#fff' }, paddingTop: 8, paddingLeft: 6, color: '#fff', fontSize: 12 }}>
                    {this.props.title}
                </div >
        )
    }
}