import { server } from './config'
import client from './client'

export default {
  save(data, config = {}) {
    return client.post(`${server}/plan/mantenimiento`, data, config)
  },
  edit(data, config = {}) {
    return client.put(`${server}/plan/mantenimiento`, data, config)
  },
  update(data, config = {}) {
    return client.put(`${server}/plan/mantenimiento`, data, config)
  },
  delete(planMantenimientoId, config = {}) {
    return client.delete(`${server}/plan/mantenimiento/${planMantenimientoId}`)
  },
  getPlanById(planMantenimientoId, config = {}) {
    return client.get(`${server}/plan/mantenimiento/${planMantenimientoId}`)
  },
  generateOrder(data, config = {}) {
    return client.post(`${server}/plan/orden`, data, config)
  },
  validateOrder(data, config = {}) {
    return client.post(`${server}/plan/validateOT`, data, config)
  },
  listUserByRol(data, config = {}) {
    return client.get(`${server}/user/rol/${data.id}`)
  },
  listActivoBySearch(data, config = {}) {
    return client.get(`${server}/search/activo/${data.search}?p=${data.page}`)
  },
  calendar(data) {
    return client.get(`${server}/plan/calendario?from=${data.from}&to=${data.to}`)
  },
  list(data) {
    return client.get(`${server}/plan/mantenimiento?p=${data.page}&c=${15}`)
  },
  search(data) {
    return client.get(`${server}/search/plan/${data.search}?p=${data.page}&c=${15}`)
  },
  listUnidadesMedicion(config = {}) {
    return client.get(`${server}/unidades/medicion`)
  },
  getChildrenTree(data, config = {}) {
    return client.get(`${server}/plan/childrenTree/${data.id}?p=${data.page}&codigoBarra=${data.codigoBarra}&busqueda=${data.busqueda}&categoria=${data.categoria}&tipoActivo=${data.tipoActivo}&tecnico=${data.tecnico}`)
  },
  getTree(config = {}) {
    return client.get(`${server}/plan/tree`)
  },
  getTreeCategorias(config = {}) {
    return client.get(`${server}/tree/tipoActivos`)
  },
  getTreeActivos(data, config = {}) {
    return client.get(`${server}/tree/activos/${data.tipoActivoId}`)
  },
  getMantenimientosFromTree(data, config = {}) {
    return client.get(`${server}/plan/mantenimientos/${data.Id}?filter=${data.filter}&busqueda=${data.busqueda}&categoria=${data.categoria}&tipoActivo=${data.tipoActivo}&tecnico=${data.tecnico}&p=${data.page}`)
  },
  //get /report/mantenimientos 
  getReportMantenimientos(data, config = {}) {
    return client.post(`${server}/report/mantenimientos`, data, config)
  }
}