import React from 'react';
import Loading from "../home/Loading"
import Container from "../Container"
import {
    Input,
    Button,
    Col,
    FormGroup,
    Row,
    Card,
    Label,
    Breadcrumb,
    BreadcrumbItem
    , Spinner
} from 'reactstrap';
import Api from "../modules/user"
import Auth from "../modules/authentication"
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvForm, AvField } from 'availity-reactstrap-validation';

export default class MyProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            correo: "",
            oldPassword: "",
            password: "",
            nombre: "",
            apellido: "",
            id: "",
            telefono: "",
            celular: "",
            direccion: "",
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            editing: false,
            usuarioId: undefined,
            recibirNotificaciones: false,
            showOtherAlert: false,
            preview: "",
            isLoading: false
        }
    }

    componentDidMount() {
        const id = localStorage.getItem('id_token')
        Api.get({ id }).then((response) => {
            if (response) {
                this.setState({
                    correo: response.correo,
                    oldPassword: "",
                    password: "",
                    nombre: response.persona.nombre,
                    apellido: response.persona.apellido,
                    id: response.persona.identificacion,
                    telefono: response.persona.telefono,
                    celular: "",
                    direccion: response.persona.direccion,
                    usuarioId: response.usuarioId,
                    recibirNotificaciones: response.recibirNotificaciones,
                    preview: response.fotoUri
                })
            }
        }).finally(() => {
            this.setState({ loading: false }, () => {
                if (this.state.preview !== "" && this.dropzone) {
                    this.dropzone.className = "file-wrapper has-preview"
                }
            })
        })
    }

    update = () => {
        this.setState({ isLoading: true })
        const { nombre, apellido, id, telefono, direccion, recibirNotificaciones, usuarioId } = this.state;
        const data = {
            usuarioId,
            recibirNotificaciones,
            persona: {
                nombre,
                apellido,
                identificacion: id,
                telefono,
                direccion
            }
        }
        Api.update(data).then((res) => {
            if (res) {
                this.setState({
                    message: "Perfil Actualizado",
                    showOtherAlert: true
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    removeImage = () => {
        this.setState({
            preview: ""
        })
        this.dropzone.className = "file-wrapper"
    }

    onDrop = (acceptedFiles) => {
        const { usuarioId } = this.state;
        this.setState({
            preview: URL.createObjectURL(acceptedFiles[0]),
            file: acceptedFiles[0]
        }, () => {
            const data = new FormData();
            data.append('picture', acceptedFiles[0]);
            Api.upload(data).then((response) => {
                if (response && response.success) {
                    localStorage.setItem('user_photo', response.donwloadUrl);
                    Api.update({ usuarioId, fotoUri: response.donwloadUrl }).then((res) => { })
                }
            })
        })
        this.dropzone.className = "file-wrapper has-preview"
    }

    updateSecurity() {
        const { correo, oldPassword, password, usuarioId } = this.state;
        if (oldPassword !== "" && password !== "") {
            Auth.checkPassword({ usuarioId, clave: oldPassword }).then((res) => {
                if (res) {
                    if (res.success) {
                        Api.update({ usuarioId, correo, clave: password }).then((res) => {
                            if (res) {
                                this.setState({
                                    message: res.message,
                                    showOtherAlert: true
                                })
                            }
                        })
                    } else {
                        this.setState({
                            message: res.message,
                            showOtherAlert: res.warning
                        })
                    }
                }
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        } else {
            Api.update({ usuarioId, correo }).then((res) => {
                if (res) {
                    this.setState({
                        message: res.message,
                        showOtherAlert: true
                    })
                }
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }


    render() {
        if (this.state.loading) return <Loading />;
        return (
            <Container menuId="profile">
                <section className="content">
                    <div className="body_scroll">
                        <div className="block-header">
                            <Row >
                                <Col lg={7} md={6} sm={12}>
                                    <h2>Mi Perfil</h2>
                                    <Breadcrumb tag="ul" className="breadcrumb">
                                        <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                        <BreadcrumbItem className="breadcrumb-item active" tag="li">Mi Perfil</BreadcrumbItem>
                                    </Breadcrumb>
                                    <Button color={"primary"} className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="container-fluid">
                            <Row className="clearfix">
                                <Col sm={6} lg={3} md={12} >
                                    <Card className="mcard_3">
                                        <div className="body">
                                            <Dropzone onDrop={this.onDrop} multiple={false} accept="image/*">
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="file-wrapper" ref={e => this.dropzone = e} style={{ height: 280 }}>
                                                        <div {...getRootProps({ className: 'file-message' })}>
                                                            <AiOutlineCloudUpload size={50} color={"#CCC"} />
                                                            <p>Arrastra la imagen o hacer click aqui</p>
                                                        </div>
                                                        <input {...getInputProps()} className={"form-control-file"} />
                                                        <Button className="file-clear" onClick={() => this.removeImage()}>Quitar</Button>
                                                        <div className="file-preview" style={{ display: this.state.preview && this.state.preview !== "" ? "block" : "none" }}>
                                                            <img src={this.state.preview} alt=""
                                                                style={{
                                                                    resizeMode: "contain",
                                                                }}
                                                            />
                                                            <div className="file-infos">
                                                                <div className="file-infos-inner">
                                                                    <p className="file-filename"><span className="file-icon"></span> <span className="file-filename-inner">1caf54794d2ce9c62f00ee45f4f9d963.jpg</span></p>
                                                                    <p className="file-infos-message">Arrastra la imagen o hacer click para reemplazar</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <h4 className="m-t-10">{this.state.nombre + " " + this.state.apellido}</h4>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={9} md={12} style={{ marginTop: -20 }}>
                                    <Card className="card">
                                        <AvForm>
                                            <div className="header">
                                                <h2><strong>Configuración</strong> de Seguridad</h2>
                                            </div>
                                            <div className="body">
                                                <Row className="row">
                                                    <Col lg={4} md={12}>
                                                        <FormGroup>
                                                            <label>Correo Electronico</label>
                                                            <AvField name="correo"
                                                                type="text"
                                                                value={this.state.correo}
                                                                onChange={(event) => this.setState({ correo: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Correo Electronico"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Ingrese el correo electronico del usuario' },
                                                                    email: { value: true, errorMessage: 'Ingrese un correo electronico válido' },
                                                                    maxLength: { value: 50, errorMessage: 'El correo debe tener menos de 50 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={4} md={12}>
                                                        <FormGroup >
                                                            <label>Contraseña Actual</label>
                                                            <Input type="password" value={this.state.oldPassword} onChange={(event) => this.setState({ oldPassword: event.target.value })} className="form-control" placeholder="Contraseña Actual" required />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={4} md={12}>
                                                        <FormGroup >
                                                            <label>Nueva Contraseña</label>
                                                            <Input type="password" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} className="form-control" placeholder="Nueva Contraseña" required />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-12">
                                                        <Button color="info" onClick={() => this.updateSecurity()}>{this.state.isLoading ? <Spinner color="light" size="sm" /> : "GUARDAR CAMBIOS"}</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </AvForm>
                                    </Card>
                                    <Card className="card">
                                        <AvForm onValidSubmit={() => this.update()}
                                            ref={e => this.form = e}>
                                            <div className="header">
                                                <h2><strong>Configuración</strong> de Cuenta</h2>
                                            </div>
                                            <div className="body">
                                                <Row className="clearfix">
                                                    <Col lg={6} md={12}>
                                                        <FormGroup >
                                                            <label>Nombre</label>
                                                            <AvField name="nombre"
                                                                type="text"
                                                                value={this.state.nombre}
                                                                onChange={(event) => this.setState({ nombre: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Nombre"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'El nombre no puede estar vacío' },
                                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                                    maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6} md={12}>
                                                        <FormGroup >
                                                            <label>Apellido</label>
                                                            <AvField name="apellido"
                                                                type="text"
                                                                value={this.state.apellido}
                                                                onChange={(event) => this.setState({ apellido: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Apellido "
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'El apellido no puede estar vacío' },
                                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El apellido debe contener solo letras y numeros' },
                                                                    maxLength: { value: 50, errorMessage: 'El apellido debe tener menos de 50 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6} md={12}>
                                                        <FormGroup >
                                                            <label>Identificación</label>
                                                            <AvField name="id"
                                                                type="text"
                                                                value={this.state.id}
                                                                onChange={(event) => this.setState({ id: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Identificación"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'La identificación no puede estar vacío' },
                                                                    pattern: { value: '^[A-z0-9]+$', errorMessage: 'La identificación debe contener solo letras y numeros' },
                                                                    maxLength: { value: 15, errorMessage: 'La identificación debe tener menos de 15 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6} md={12}>
                                                        <FormGroup >
                                                            <label>Teléfono Principal</label>
                                                            <AvField name="telefono"
                                                                type="text"
                                                                value={this.state.telefono}
                                                                onChange={(event) => this.setState({ telefono: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Teléfono Principal"
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'El telefono no puede estar vacío' },
                                                                    pattern: { value: '^[0-9  -+]+$', errorMessage: 'El telefono contiene caracteres inválidos' },
                                                                    maxLength: { value: 50, errorMessage: 'El telefono debe tener menos de 50 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormGroup >
                                                            <label>Dirección</label>
                                                            <AvField name="direccion"
                                                                type="textarea"
                                                                rows={4}
                                                                value={this.state.direccion}
                                                                onChange={(event) => this.setState({ direccion: event.target.value })}
                                                                className="form-control"
                                                                placeholder="Dirección"
                                                                validate={{
                                                                    required: { value: false, errorMessage: '' },
                                                                    // eslint-disable-next-line
                                                                    pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'La dirección deben contener solo letras y numeros' },
                                                                    maxLength: { value: 150, errorMessage: 'La dirección debe tener menos de 150 caracteres' }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="checkbox" >
                                                            <Input id="procheck1" type="checkbox" checked={this.state.recibirNotificaciones} onChange={() => this.setState({ recibirNotificaciones: !this.state.recibirNotificaciones })} />
                                                            <Label htmlFor="procheck1">Recibir Notificaciones</Label>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Button color="info" onClick={() => this.form.submit()}>{this.state.isLoading ? <Spinner color="light" size="sm" /> : "GUARDAR CAMBIOS"}</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </AvForm>
                                    </Card>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </section>
                <SweetAlert
                    warning
                    title={this.state.message}
                    onConfirm={() => this.setState({ showOtherAlert: false })}
                    onCancel={() => this.setState({ showOtherAlert: false })}
                    show={this.state.showOtherAlert}
                />
            </Container>
        )
    }
}