import Proveedor from "../../../modules/proveedor"
import User from "../../../modules/user"


//eslint-disable-next-line
const findPrioridad = (value) => {
    let prioridad = '';
    switch (value) {
        case 1:
            prioridad = { label: 'Alta', value: 1 };
            break;
        case 2:
            prioridad = { label: 'Media', value: 2 };
            break;
        case 3:
            prioridad = { label: 'Baja', value: 3 };
            break;
        default:
            break;
    }
    return prioridad;
}

//eslint-disable-next-line
const fintTipoMantenimiento = (value) => {
    let tipoMantenimiento = '';
    switch (value) {
        case 1:
            tipoMantenimiento = { label: 'Preventivo', value: 1 };
            break;
        case 2:
            tipoMantenimiento = { label: 'Correctivo', value: 2 };
            break;
        case 3:
            tipoMantenimiento = { label: 'Predictivo', value: 3 };
            break;
        case 4:
            tipoMantenimiento = { label: 'Calibración', value: 4 };
            break;
        default:
            break;
    }
    return tipoMantenimiento;
}

//eslint-disable-next-line
const findEstado = (value) => {
    let estado = '';
    switch (value) {
        case 1:
            estado = { value: "1", label: "Abierta" };
            break;
        case 2:
            estado = { value: "2", label: "En Progreso" };
            break;
        case 3:
            estado = { value: "3", label: "Completada" };
            break;
        case 4:
            estado = { value: "4", label: "No Completada" };
            break;
        case 5:
            estado = { value: "Vencido", label: "Vencido" };
            break;
        default:
            break;
    }
    // setStatus(estado);
    return estado;
}

//eslint-disable-next-line
const findTipoOrden = (value) => {
    let tipoOrden = '';
    switch (value) {
        case 1:
            tipoOrden = { value: 1, label: "Orden de Trabajo" };
            break;
        case 2:
            tipoOrden = { value: 2, label: "Orden de Servicio" };
            break;
        default:
            break;
    }
    return tipoOrden;
}

//eslint-disable-next-line
const findCondicion = (value) => {
    let condicion = '';
    switch (value) {
        case 'Bueno':
            condicion = { label: 'Bueno', value: 'Bueno' };
            break;
        case 'Regular':
            condicion = { label: 'Regular', value: 'Regular' };
            break;
        case 'Malo':
            condicion = { label: 'Malo', value: 'Malo' };
            break;
        default:
            break;
    }
    return condicion;
}

const getProveedores = async () => {
    let proveedores = []
    const res = await Proveedor.listBy({ id: 0 })
    if (res) {
        for (const key in res) {
            proveedores.push({
                value: res[key].proveedorId,
                label: res[key].persona.nombre + " " + res[key].persona.apellido,
                address: res[key].persona.direccion,
            })
        }
    }
    return proveedores;
}

const getTecnicos = async () => {
    let tecnicos = []
    const res = await User.getTechs()
    // console.log("🚀 ~ file: index.js:126 ~ getTecnicos ~ response", res)
    if (res) {
        // const res = response.data
        for (const key in res) {
            tecnicos.push({
                value: res[key].personaId,
                label: res[key].nombre + " " + res[key].apellido
            })
        }
    }
    return tecnicos;
}

const findTecnico = async (usuario) => {
    // console.log("🚀 ~ file: index.js:139 ~ findTecnico ~ usuario", usuario)
    let tecnico = null;
    const d2 = await getTecnicos();
    if (d2) {
        let usuarioAsignado = usuario?.personaId;
        if (usuarioAsignado !== null) {
            d2.forEach((item) => {
                if (item.value === usuarioAsignado) {
                    tecnico = item;
                }
            });
            return tecnico;
        }
    }
}

const findAutorizador = async (usuario) => {
    let autorizador = null;
    const d2 = await getTecnicos();
    if (d2) {
        let usuarioAutorizador = usuario?.personaId;
        if (usuarioAutorizador !== null) {
            d2.forEach((item) => {
                if (item.value === usuarioAutorizador) {
                    autorizador = item;
                }
            });
            return autorizador;
        }
    }
}


const findProveedor = async (proveedor) => {
    let proveedorSel = null;
    const d1 = await getProveedores();
    if (d1) {
        let proveedorId = proveedor?.proveedorId;
        if (proveedorId !== null) {
            d1.forEach((item) => {
                if (item.value === proveedorId) {
                    proveedorSel = item;
                }
            });
            return proveedorSel;
        }
    }
}

const addNewUser = async (data) => {
    const res = await User.newUser(data);
    return res;
}

const addNewProveedor = async (data) => {
    const res = await Proveedor.save(data);
    return res;
}


export {
    findPrioridad,
    fintTipoMantenimiento,
    findEstado,
    findTipoOrden,
    findCondicion,
    getProveedores,
    getTecnicos,
    findTecnico,
    findProveedor,
    addNewUser,
    addNewProveedor,
    findAutorizador
}