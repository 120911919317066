import OrdenTrabajo from '../services/ordenTrabajo'


export default {
    
    async save(data, editable){
      let result;
      try {
        result = await OrdenTrabajo.save(data,{}, editable)
      } catch (error) {
        console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async addTaskItem(data){
      let result;
      try {
        result = await OrdenTrabajo.addTaskItem(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async addProductItem(data){
      let result;
      try {
        result = await OrdenTrabajo.addProductItem(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async removeProductItem(data){
      let result;
      try {
        result = await OrdenTrabajo.removeProductItem(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async sendRequest(data){
      let result;
      try {
        result = await OrdenTrabajo.sendRequest(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async update(data,editable){
      let result;
      try {
        result = await OrdenTrabajo.update(data,{},editable)
      } catch (error) {
        console.log(error,"error al actualizar")
        result = false;
      }
      return result ? result.data : false;
    },
    async updateTask(data){
      let result;
      try {
        result = await OrdenTrabajo.updateTask(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listById (data) {
      let result;
      try{
        result = await OrdenTrabajo.listById(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async list(data){
      let result;
      if(!data) data = { filtro : 0}
      try{
        result = await OrdenTrabajo.list(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listByFilter(data){
      let result;
      try{
        result = await OrdenTrabajo.listByFilter(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listByFilterService(data){
      let result;
      try{
        result = await OrdenTrabajo.listByFilterService(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async search(data){
      let result;
      try{
        result = await OrdenTrabajo.search(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listTask(data){
      let result;
      try{
        const id = localStorage.getItem('id_token')
        result = await OrdenTrabajo.listTask({id , displayAll: data.displayAll});
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },

    async listOrdersRequests(data){
      let result;
      if(!data) data = { page : 1, count: 10}
      try{
        result = await OrdenTrabajo.listOrdersRequests(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async lastOt(data){
      let result;
      try{
        result = await OrdenTrabajo.lastOt(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async revisionOT(data){
      let result;
      try{
        result = await OrdenTrabajo.revisionOT(data);
      } catch(error){
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    }
}