import React, { useEffect, useState, createRef } from "react";
import { Col, Row, Button, Spinner } from "reactstrap";
import moment from "moment";
//eslint-disable-next-line
import Plan from "../../modules/plan"
import Api from "../../modules/activos";
import OrdenTrabajo from "../../modules/ordenTrabajo"
import ApiPlan from "../../modules/plan"
import { InformacionBasica, Warning, Actividades, InformacionComplementaria, Copyright } from './components';
import { terminado } from "./constants";
import { getProveedores, getTecnicos, findTecnico, findProveedor, addNewUser, addNewProveedor, findAutorizador } from "./functions";
import ModaladdUser from "../../components/Modal/ModaladdUser";
import ModaladdUserProveedor from "../../components/Modal/ModaladdUserProveedor";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from '@ant-design/icons';
import { Button as ButtonAnt } from "antd";
import { DATOS_BASICOS } from '../../constants/index';

const ReporteOrdenTrabajo = (props) => {
    const ref = createRef();
    const [loading, setLoading] = useState(true);
    const [loadingTitle, setLoadingTitle] = useState(true);
    const [fecha, setFecha] = useState();
    const [activo, setActivo] = useState({});
    const [ordenTrabajo, setOrdenTrabajo] = useState({});
    //eslint-disable-next-line
    const [planMantenimiento, setPlanMantenimiento] = useState({});
    const [actividades, setActividades] = useState([]);
    const [actividadesCache, setActividadesCache] = useState([]);
    const [observaciones, setObservaciones] = useState({});
    const [editable, setEditable] = useState(false);
    const [tecnicos, setTecnicos] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    //eslint-disable-next-line
    const [tipoOrderCache, setTipoOrderCache] = useState(1);
    const [ordenTrabajoId, setOrdenTrabajoId] = useState(-1);
    const [id_plan, setIdPlan] = useState(0);
    const [id_activo, setIdActivo] = useState(0);
    const [estadoOrdenSel, setEstadoOrdenSel] = useState(1);
    //eslint-disable-next-line
    const [prioridadSel, setPrioridadSel] = useState(1);
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [newUser, setNewUser] = useState({});
    const [modalProveedor, setModalProveedor] = useState(false);
    const [newProveedor, setNewProveedor] = useState({});
    const [ultimaRevision, setUltimaRevision] = useState(null);
    const state = props?.location?.state;
    //eslint-disable-next-line
    const [titulo, setTitulo] = useState('');
    const [tipo, setTipo] = useState('Trabajo');
    const [showButton, setShowButton] = useState(false);

    const queryString = require('query-string');
    useEffect(() => {
        setShowButton(props.show);
    }, [props.show]);

    useEffect(() => {
        const parsed = queryString.parse(props?.location?.search);
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:41 ~ useEffect ~ parsed", props.data)
        const orden_id = props.data ? props.data.ordenTrabajoId : parseInt(parsed.ordenTrabajoId);
        const activo_id = props.data ? props.data.activo_id : parseInt(parsed.activo_id);
        const plan_id = props.data ? props.data.plan_id : parseInt(parsed.plan_id);
        const tipo_ot = props.data ? props.data.tipo_ot : parseInt(parsed.tipo_ot);
        const fecha = props.data ? props.data.fechaOT : parsed.fecha;
        if (props.data.showButton) {
            setShowButton(true);
        }
        setTipoOrderCache(tipo_ot);
        setOrdenTrabajoId(orden_id);
        setIdPlan(plan_id);
        setIdActivo(activo_id);
        consulta();
        if (tipo_ot === 1) {
            if (orden_id < 0) {
                //Crear nueva orden de trabajo;
                // const fecha = moment().format('DD/MM/YYYY');
                setFecha(fecha);
                getActivo(activo_id, plan_id, orden_id, tipo_ot);
                //1.- obtener activo;
                //2.- obtener plan de mantenimiento;
            }
            if (orden_id > 0) {
                //Editar orden de trabajo;
                console.log(`Orden de trabajo #${orden_id}`);
                getOrdenTrabajo(orden_id, plan_id);
                //1.- obtener orden de trabajo;
            }
        } else {
            //correctivo
            if (orden_id < 0) {
                setFecha(fecha);
                getActivo(activo_id, plan_id, orden_id, tipo_ot);
            } else {
                console.log(`Orden de trabajo #${orden_id}`);
                getOrdenTrabajo(orden_id, plan_id);
            }
        }
        let tipoMantenimiento = tipoOrderCache === 2 ? '- Correctiva' : '';

        setTitle(
            tipo === 'Servicio' ?
                <p>{`Orden de Servicio ${titulo} ${tipoMantenimiento}`}</p> :
                <p>{`Orden de Trabajo ${titulo} ${tipoMantenimiento}`}</p>
        )
        //eslint-disable-next-line
    }, [state, props.data]);

    const consulta = async () => {
        const d1 = await getProveedores();
        const d2 = await getTecnicos();
        setProveedores(d1);
        setTecnicos(d2);
    };

    //get orden de trabajo when editing
    const getOrdenTrabajo = async (orden_id) => {
        const orden = await OrdenTrabajo.listById({ id: orden_id });
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:91 ~ getOrdenTrabajo ~ orden:", orden)
        if (orden) {
            const activo = orden.activo;
            const checkList = orden.checkList;
            const fecha = orden.fechaInicio !== null ? moment(orden.fechaInicio).format('DD/MM/YYYY') : '';
            if (tipoOrderCache === 1) {
                setTitulo(orden?.planMantenimiento?.descripcion ? `- ${orden?.planMantenimiento?.descripcion} ` : '');
                const ultimaRevision = await OrdenTrabajo.lastOt({ id: activo.activoId });
                // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:111 ~ getOrdenTrabajo ~ ultimaRevision:", ultimaRevision)
                if (ultimaRevision.length > 0) {
                    // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:113 ~ getOrdenTrabajo ~ ultimaRevision[0].fechaInicio:", ultimaRevision[0].fechaInicio)

                    if (ultimaRevision[0].fechaInicio !== null) {
                        let fecha = moment(ultimaRevision[0].fechaInicio).format('DD/MM/YYYY');
                        setUltimaRevision(fecha);
                    }
                };
            }
            if (orden?.ordenTrabajoNumero > 0) {
                setEditable(true);
            }
            if (orden.tipoOrden === 2) {
                setTipo('Servicio');
                setLoadingTitle(false);
            }
            setFecha(fecha);
            setEstadoOrdenSel(orden?.estado);
            setOrdenTrabajo(orden);
            setActivo(activo);
            loadActividadesData(checkList);
            loadDescripcionData(orden);
            //find tecnico
            const ft = await findTecnico(orden?.responsable);
            //find autorizador
            const fa = await findAutorizador(orden?.autorizador);
            //find proveedor
            const fp = await findProveedor(orden?.proveedor);

            setObservaciones({
                ...observaciones,
                realizadoPor: ft,
                revisadoPor: fa,
                trabajoRealizado: fp,
                direccionCompania: fp?.address || '',
            });
            setLoading(false);
        };
    };

    //load descripcion data
    const loadDescripcionData = (orden) => {
        // console.log('loadDescripcionData')
        setObservaciones({
            ...observaciones,
            prioridad: orden?.prioridad,
            observaciones: orden?.resumenProblema,
            realizado: orden?.estado === 3 ? 'Si' : 'No',
            comentario: orden?.notaFinalizacion,
            tiempoReq: orden?.tiempoEstimado,
            noPersTrabajo: orden?.numeroPersonas,
        });
    };

    //get activo data
    const getActivo = async (activo_id, plan_id, orden_id, tipo_ot) => {
        const activo = await Api.get({ id: activo_id });
        if (activo) {
            getPlanMantenimiento(plan_id);
            setActivo(activo);
            const ultimaRevision = await OrdenTrabajo.lastOt({ id: activo_id });
            if (ultimaRevision) {
                if (ultimaRevision.length > 0 && ultimaRevision[0].fechaInicio !== null) {
                    let fecha = moment(ultimaRevision[0].fechaInicio).format('DD/MM/YYYY');
                    setUltimaRevision(tipo_ot === 1 ? fecha : '');
                }
            };
        } else {
            setActivo(activo);
            loadActividadesData([]);
        }
    };

    //get plan de mantenimiento data
    const getPlanMantenimiento = async (plan_id) => {
        const planMantenimiento = await ApiPlan.getPlanById({ id: plan_id });
        if (planMantenimiento) {
            setTitulo(`- ${planMantenimiento?.descripcion}`);
            setPlanMantenimiento(planMantenimiento);
            let actividades = !planMantenimiento?.partes ? [] : planMantenimiento?.partes[0]?.actividades;
            loadActividadesData(actividades);
        } else {
            loadActividadesData([]);
        }
    };

    //load actividades data
    const loadActividadesData = (actividades) => {

        const actividadesData = actividades.map((actividad, index) => {
            return {
                id: index + 1,
                ...actividad,
                descripcion: actividad?.descripcion,
                realizado: actividad.checked,
                comentario: actividad?.comentario || actividad?.procedimientos,
            };
        });

        setActividadesCache(actividadesData);

        let actividadesDataLength = actividadesData.length;
        let actividadesLength = 8;
        let newActividades = [];
        if (actividadesDataLength > 0) {
            for (let i = 0; i < actividadesDataLength; i++) {
                if (actividadesDataLength !== 0) {
                    newActividades.push({
                        id: i + 1,
                        ...actividadesData[i],
                        descripcion: actividadesData[i].descripcion,
                        realizado: actividadesData[i].checked,
                        comentario: actividadesData[i].comentario,
                        nueva: false
                    });
                    if (i === actividadesDataLength - 1) {
                        for (let j = 0; j < actividadesLength - actividadesDataLength; j++) {
                            newActividades.push({
                                id: i + j + 2,
                                descripcion: '',
                                realizado: false,
                                comentario: '',
                                nueva: true
                            });
                        }
                    }
                } else {
                    for (let i = 0; i < actividadesLength; i++) {
                        newActividades.push({
                            id: i + 1,
                            descripcion: '',
                            realizado: false,
                            comentario: '',
                            nueva: true
                        });
                    }
                }
            }
        } else {
            //add empty actividades
            for (let i = 0; i < actividadesLength; i++) {
                newActividades.push({
                    id: i + 1,
                    descripcion: '',
                    realizado: false,
                    comentario: '',
                    nueva: true
                });
            }
        }
        setActividades(newActividades);
        setLoading(false);
        setLoadingTitle(false);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const newActividades = [...actividades];
        newActividades[index] = { ...newActividades[index], [name]: value };
        setActividades(newActividades);
    };

    const handleChangeCheck = (e, index) => {
        const { name, checked } = e.target;
        const newActividades = [...actividades];
        newActividades[index] = { ...newActividades[index], [name]: checked };
        setActividades(newActividades);
    };

    const handleActividades = async (ordenTrabajoId) => {
        let newActividades = [...actividades];
        const newActividadesFilter = newActividades.filter((item) => { return !item.ordenTrabajoCheckListId && item.descripcion !== '' && item.nueva });
        const updateActividadesFilter = newActividades.filter((item) => { return item.ordenTrabajoCheckListId && !item.nueva });
        if (newActividadesFilter.length > 0) {
            console.log('actividades nuevas')
            newActividadesFilter.forEach(async (item) => {
                const res = await agregarTarea(item, ordenTrabajoId);
                return res;
            });
        }
        if (updateActividadesFilter.length > 0) {
            updateActividadesFilter.forEach(async (item, index) => {
                if ((actividadesCache[index].descripcion !== item.descripcion) || (actividadesCache[index].realizado !== item.realizado) || (actividadesCache[index].comentario !== item.comentario)) {
                    const res = await actualizarTarea(item, ordenTrabajoId);
                    return res;
                };
            });
        };
    };

    const actualizarTarea = async (item, ordenTrabajoId) => {
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:315 ~ actualizarTarea ~ item", item)
        console.log("🚀 actualizarTarea")
        const data = {
            ordenTrabajoCheckListId: item.ordenTrabajoCheckListId,
            ordenTrabajo: {
                ordenTrabajoId: ordenTrabajoId
            },
            checkedByUser: null,
            descripcion: item.descripcion,
            comentario: item.comentario,
            checked: item.realizado,
        }
        console.log(data, 'actualizarTarea')
        const response = await OrdenTrabajo.updateTask(data);
        return response;
        // OrdenTrabajo.updateTask(data).then((res) => {
        //     console.log("🚀 ~ ", res);
        // });
    };

    const agregarTarea = async (item, ordenTrabajoId) => {
        console.log("🚀 agregarTarea")
        const data = {
            ordenTrabajo: {
                ordenTrabajoId: ordenTrabajoId
            },
            checkedByUser: null,
            descripcion: item.descripcion,
            comentario: item.comentario,
            checked: item.realizado,
        }
        console.log(data, 'agregarTarea')
        const response = await OrdenTrabajo.addTaskItem(data);
        return response;
        // OrdenTrabajo.addTaskItem(data).then((res) => {
        //     console.log("🚀 ~ ", res);
        // });
    };

    const handleChangeObservaciones = (e) => {
        const { name, value } = e.target;
        console.log("🚀 ~ ", { [name]: e.target.value })
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:358 ~ handleChangeObservaciones ~ e.target.value :", e.target.value)
        if (name === 'realizado') {
            setObservaciones({ ...observaciones, [name]: e.target.value });
            actividadesRealizadas(e.target.value);
            setEstadoOrdenSel(e.target.value === 'true' ? 3 : e.target.value === 'false' ? 4 : 1);
        } else {
            setObservaciones({ ...observaciones, [name]: value });
        };
    };

    const handleTecnicos = (e) => {
        setObservaciones({
            ...observaciones,
            realizadoPor: e.selected,
        });
        setTipo('Trabajo');
        setLoadingTitle(false);
    };

    const handleAutorizador = (e) => {
        setObservaciones({
            ...observaciones,
            revisadoPor: e.selected,
        });
    };

    const handleProveedor = (e) => {
        setObservaciones({
            ...observaciones,
            trabajoRealizado: e.selected
        });
        setTipo('Servicio');
        setLoadingTitle(false);
    };

    //activar en la linea 342 para que se actualicen las actividades como completadas o no
    //eslint-disable-next-line
    const actividadesRealizadas = (value) => {
        const newActividades = [...actividades];
        const newActividadesFilter = newActividades.filter((item) => { return item.descripcion.trim() !== '' });
        if (value === 'true') {
            newActividadesFilter.forEach((item) => {
                item.realizado = true;
            });
        } else if (value !== 'null') {
            newActividadesFilter.forEach((item) => {
                item.realizado = false;
            });
        };
    };

    const guardarOrden = async () => {
        let checkList = []
        for (const key in actividades) {
            if (actividades[key].descripcion !== '') {
                checkList.push({
                    descripcion: actividades[key].descripcion,
                    checked: actividades[key].realizado ? actividades[key].realizado : false,
                    comentario: actividades[key].comentario ? actividades[key].comentario : ''
                })
            }
        };
        let { realizadoPor, trabajoRealizado, comentario, revisadoPor, tiempoReq, noPersTrabajo } = observaciones;

        const proveedorId = trabajoRealizado?.value ? trabajoRealizado?.value : null;
        const notaFinalizacion = comentario !== undefined && comentario !== null && comentario !== '' ? comentario : "";
        const resumenProblema = observaciones?.observaciones !== undefined && observaciones?.observaciones !== null && observaciones?.observaciones !== '' ? observaciones.observaciones : '';
        const tipoOrden = tipo === 'Trabajo' ? 1 : 2;
        const prioridad = prioridadSel;
        const autorizador = revisadoPor ? revisadoPor.value : null;
        const tiempoEstimado = tiempoReq ? parseInt(tiempoReq) : 0;
        const numeroPersonas = noPersTrabajo ? parseInt(noPersTrabajo) : 0;
        const estado = parseInt(estadoOrdenSel);
        const fechaEmision = moment().format("YYYY-MM-DD");
        const fechaInicio = moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");
        const fechaFinalizacion = estado >= 3 ? moment().format("YYYY-MM-DD") : null;
        const tipoMantenimiento = tipoOrderCache ? tipoOrderCache : planMantenimiento.tipoMantenimiento;
        const responsable = realizadoPor?.value ? parseInt(realizadoPor?.value) : null;

        const datos = {
            activo: { activoId: id_activo, },
            planMantenimiento: tipoOrderCache === 1 ? { planMantenimientoId: id_plan } : null,
            fechaEmision,
            fechaInicio,
            fechaFinalizacion,
            tipoMantenimiento,
            checkList,
            estado,
            tipoOrden,
            notaFinalizacion,
            resumenProblema,
            responsable: responsable !== null ? { personaId: responsable } : null,
            proveedor: proveedorId !== null ? { proveedorId: proveedorId } : null,
            prioridad,
            tiempoEstimado,
            numeroPersonas,
            autorizador: autorizador !== null ? { personaId: autorizador } : null,
        };

        let dataUpdate = {
            ordenTrabajoId,
            checkList,
            estado,
            tipoOrden,
            notaFinalizacion,
            resumenProblema,
            responsable: responsable !== null ? { personaId: responsable } : null,
            proveedor: proveedorId !== null ? { proveedorId: proveedorId } : null,
            prioridad,
            tiempoEstimado,
            numeroPersonas,
            autorizador: autorizador !== null ? { personaId: autorizador } : null,
        };

        if (ordenTrabajoId === -1) {
            generarOrden(datos, 1);
        } else {
            actualizarOrdenTrabajo(dataUpdate, 1);
        };

    };

    const guardadoDefinitivo = () => {
        let checkList = []
        for (const key in actividades) {
            if (actividades[key].descripcion !== '') {
                checkList.push({
                    descripcion: actividades[key].descripcion,
                    checked: actividades[key].realizado ? actividades[key].realizado : false,
                    comentario: actividades[key].comentario
                })
            }
        };
        let { realizadoPor, trabajoRealizado, comentario, revisadoPor, tiempoReq, noPersTrabajo } = observaciones;
        const proveedorId = trabajoRealizado?.value ? trabajoRealizado?.value : null;
        const notaFinalizacion = comentario !== '' ? comentario : "";
        const resumenProblema = observaciones.observaciones !== '' ? observaciones.observaciones : '';
        const tipoOrden = tipo === 'Trabajo' ? 1 : 2;
        const prioridad = prioridadSel;
        const autorizador = revisadoPor ? revisadoPor.value : null;
        const tiempoEstimado = tiempoReq ? parseInt(tiempoReq) : 0;
        const numeroPersonas = noPersTrabajo ? parseInt(noPersTrabajo) : 0;
        const estado = parseInt(estadoOrdenSel);
        const fechaEmision = moment().format("YYYY-MM-DD");
        const fechaInicio = moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");
        const fechaFinalizacion = moment().format("YYYY-MM-DD");
        const tipoMantenimiento = tipoOrderCache ? tipoOrderCache : planMantenimiento.tipoMantenimiento;
        const responsable = realizadoPor?.value ? parseInt(realizadoPor?.value) : null;

        const datos = {
            activo: { activoId: id_activo, },
            planMantenimiento: tipoOrderCache === 1 ? { planMantenimientoId: id_plan } : null,
            fechaEmision,
            fechaInicio,
            fechaFinalizacion,
            tipoMantenimiento,
            checkList,
            estado,
            tipoOrden,
            notaFinalizacion,
            resumenProblema,
            responsable: responsable !== null ? { personaId: responsable } : null,
            proveedor: proveedorId !== null ? { proveedorId: proveedorId } : null,
            prioridad,
            tiempoEstimado,
            numeroPersonas,
            autorizador: autorizador !== null ? { personaId: autorizador } : null,
        };

        const dataUpdate = {
            ordenTrabajoId,
            planMantenimiento: tipoOrderCache === 1 ? { planMantenimientoId: id_plan } : null,
            fechaFinalizacion,
            tipoMantenimiento,
            checkList,
            estado,
            tipoOrden,
            notaFinalizacion,
            resumenProblema,
            responsable: responsable !== null ? { personaId: responsable } : null,
            proveedor: proveedorId !== null ? { proveedorId: proveedorId } : null,
            prioridad,
            tiempoEstimado,
            numeroPersonas,
            autorizador: autorizador !== null ? { personaId: autorizador } : null,
        };

        if (ordenTrabajoId === -1) {
            if (validarCampos(datos)) {
                generarOrden(datos, 0);
                console.log("generado")
            }
        } else {
            if (validarCampos(datos)) {
                actualizarOrdenTrabajo(dataUpdate, 0);
                console.log("actualizado")
            }
        };
    };

    const validarCampos = (datos) => {
        const actividadesFiltradas = actividades.filter((actividad) => actividad.descripcion.trim() !== '');
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:541 ~ validarCampos ~ actividadesFiltradas", actividadesFiltradas)
        if (actividadesFiltradas.length === 0) {
            alert("Debe crear al menos una actividad")
            return false;
        }
        if (datos?.responsable === null && datos?.proveedor === null) {
            alert("Debes seleccionar un responsable");
            return false;
        }
        if (estadoOrdenSel < 3) {
            alert("Debes seleccionar SI o NO para finalizar la orden");
            return false;
        }
        if (datos?.notaFinalizacion === null) {
            alert("Debes ingresar un comentario");
            return false;
        };
        return true;
    };

    const generarOrden = async (datos, editable) => {
        const actividadesFiltradas = actividades.filter((actividad) => actividad.descripcion !== '');

        if (actividadesFiltradas.length > 0) {
            console.log('generar orden')
            const res = await OrdenTrabajo.save(datos, editable);
            if (res) {
                alert(res.message)
                //close window
                window.close();
            } else {
                alert("Error al crear orden")
            };
        } else {
            alert("Debe crear primero una actividad, para poder guardar la orden")
        }
    };

    const actualizarOrdenTrabajo = async (data, editable) => {
        // console.log("🚀 ~ file: ReporteOrdenTrabajo.js:609 ~ actualizarOrdenTrabajo ~ data:", data)
        if (ordenTrabajoId !== -1) {
            const actividades = await handleActividades(ordenTrabajoId);
            if (actividades || actividades === undefined) {
                const res = await OrdenTrabajo.update(data, editable);
                if (res) {
                    alert(res.message)
                    window.close();

                }
            }
        } else {
            alert("debe guardar la orden primero")
        };
    };

    const guardarUsuario = async () => {
        console.log('guardar usuario: ', newUser);
        const data = {
            nombre: newUser.nombre,
            apellido: '',
        };
        if (data.nombre !== '' && data.nombre !== null) {
            const res = await addNewUser(data);
            if (res) {
                console.log(res);
                setModal(false);
                consulta();
                setNewUser({});
                // tecnicos.push({
                //     value: res.usuarioId,
                //     label: res.nombre,
                // });
                setObservaciones({
                    ...observaciones,
                    [newUser.type]: { label: res.nombre, value: res.usuarioId },
                });
            } else {
                console.log(res.message);
            }
        }
    };

    const guardarProveedor = async () => {
        console.log('guardar proveedor: ', newProveedor);
        const data = {
            tipoProveedor: "Proveedor",
            persona: {
                nombre: newProveedor.trabajoRealizado,
                apellido: '',
                direccion: newProveedor.direccion,
            }
        };
        if (data.persona.nombre !== '' && data.persona.direccion !== '') {
            const res = await addNewProveedor(data);
            if (res) {
                console.log(res);
                setModalProveedor(false);
                consulta();
                setNewProveedor({});
                // proveedores.push({
                //     value: res.proveedorId,
                //     label: res.nombre,
                // });
                // setProveedores(proveedores);
                setObservaciones({
                    ...observaciones,
                    trabajoRealizado: { label: res.persona.nombre, value: res.proveedorId },
                    direccionCompania: res.persona.direccion,
                });
            } else {
                console.log(res.message);
            }
        }
    };

    const [title, setTitle] = useState('');
    const [loadingPdf, setLoadingPdf] = useState(false);

    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [text, setText] = React.useState("old boring text");
    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
        setShowButton(false);
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        setLoadingPdf(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoadingPdf(false);
                setText("New, Updated Text!");
                resolve();
            }, 2000);
        });
    }, [setLoadingPdf, setText]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `${DATOS_BASICOS.NOMBRE_EMPRESA} - ${fecha} - ${activo?.nombre} - ${ordenTrabajo.ordenTrabajoId !== undefined ? `revisión #${ordenTrabajo.ordenTrabajoId}` : `nueva orden`}`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    });

    React.useEffect(() => {
        if (
            text === "New, Updated Text!" &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onBeforeGetContentResolve.current, text]);

    return (
        <div className="d-flex justify-content-center align-items-center" ref={componentRef} text={text} >
            <section className="content ml-0 mr-0" style={{ minWidth: 900, maxWidth: 900 }}>
                {loading ?
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner />
                    </div> :
                    <div ref={ref} className='p-2 pt-0'>
                        <Row className='justify-content-between align-items-center'>
                            {loadingTitle ? <Spinner size='sm' className='ml-3' /> : <Col md={10} >
                                {/* <p>Orden de Trabajo</p> */}
                                {title}
                            </Col>
                            }
                            <Col className='d-flex justify-content-end'>
                                <p>{fecha}</p>
                            </Col>
                        </Row>
                        {/* seccion1 */}
                        <InformacionBasica activo={activo} orden={ordenTrabajo} ultimaRevision={ultimaRevision} />
                        {/* warning */}
                        <Warning />
                        {/* actividades */}
                        <Actividades actividades={actividades} handleChange={handleChange} handleChangeCheck={handleChangeCheck} editable={editable} />
                        {/* Observaciones */}
                        <InformacionComplementaria
                            nuevadata={observaciones}
                            handleChange={handleChangeObservaciones}
                            handleTecnicos={handleTecnicos}
                            handleProveedor={handleProveedor}
                            handleAutorizador={handleAutorizador}
                            editable={editable}
                            tecnicos={tecnicos}
                            terminado={terminado}
                            proveedores={proveedores}
                            handleUsuario={(e) => { setModalData(e); e.value !== '' && setNewUser({ nombre: e.value, type: e.type }) }}
                            handleNuevoProveedor={(e) => { setModalData(e); e.value !== '' && setNewProveedor({ [e.type]: e.value, type: e.type }); }}
                            modalNuevoUsuario={() => setModal(true)}
                            modalNuevoProveedor={() => setModalProveedor(true)}
                        />
                    </div>
                }
                <Copyright fecha={moment().format('DD/MM/YYYY')} />
                <Row >
                    {!editable && !showButton &&
                        <Col className='d-flex justify-content-end'>
                            <Button disabled={editable} style={{}} className='mt-2' color='secundary' onClick={() => guardarOrden()}>Guardar Temporal</Button>
                        </Col>
                    }
                    {!showButton &&
                        <Col className='d-flex justify-content-center align-items-center'>
                            <Col className='d-flex justify-content-start align-item-center'>
                                {!editable && <Button disabled={editable} style={{}} className='mt-2' color='success' onClick={() => guardadoDefinitivo()}>Guardar Defenitivo</Button>}
                            </Col>
                            <Col className='d-flex justify-content-end align-item-center'>
                                <ButtonAnt

                                    icon={<PrinterOutlined style={{ display: 'inline-flex' }} />}
                                    style={{ backgroundColor: '#0c7ce6', color: '#fff', alignItems: 'center' }}
                                    disabled={loadingPdf ? true : false} onClick={() => { handlePrint(); setShowButton(true) }}>
                                    {loadingPdf ? <Spinner /> : 'Imprimir'}
                                </ButtonAnt>
                            </Col>
                        </Col>

                    }
                </Row>
            </section>
            <ModaladdUser show={modal} onHide={() => { setModal(false) }} data={modalData} handleChange={(e) => setNewUser(e)} save={guardarUsuario} />
            <ModaladdUserProveedor show={modalProveedor} onHide={() => { setModalProveedor(false) }} data={modalData} handleChange={(e) => setNewProveedor({ ...newProveedor, ...e })} save={guardarProveedor} />
        </div>
    );
}

export default ReporteOrdenTrabajo;