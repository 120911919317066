import React from 'react';
import '../App.css';


export default class TableUserlist extends React.Component {

    render() {
        const { data, className } = this.props
        // console.log(data)
        return (
            <td
                // className="text-center"
                className={className}
            >
                <ul className="list-unstyled team-info margin-0">
                    {
                        data.map((item, index) => {
                            return <li key={index} >
                                <div className='row align-items-center'>
                                    <img src={item.src === null ? "" : item.src} alt={item.src === null ? "" : item?.name} />
                                    {/* <span>{item?.name}</span> */}
                                </div>
                            </li>
                        })
                    }
                </ul>
            </td>
        )
    }
}