import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import Select from 'react-select';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import { Table, Button, Alert, Input, Row, Col, Breadcrumb, BreadcrumbItem, Card, Label } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import Api from "../modules/ordenTrabajo"
import { server } from "../services/config"
import moment from "moment"
import _ from "lodash"
export default class AssignedTasks extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tareas: [],
            nota: "",
            confirm: false,
            usuarioId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "La orden se ha completado con exito",
            loading: true,
            ordenes: [],
            ordensel: {
                value: -1,
                label: "TODAS"
            },
            checked: false,
            myKey: 0
        }
    }

    componentDidMount() {
        if (localStorage.getItem('user_rol') === "5" || localStorage.getItem('user_rol') === "6") {
            history.replace('/404');
        }
        this.listTareas();
    }

    listTareas() {
        //this.setState({loading: true})
        let tareas = []
        Api.listTask({ displayAll: this.state.checked ? 1 : 0 }).then((res) => {
            if (res) {
                for (const key in res.data) {
                    const task = res.data[key];
                    tareas.push({
                        id: task.id,
                        nombre: task.nombre,
                        imagen: task.fotoUri,
                        tarea: task.descripcion,
                        ordenId: task.ordenTrabajoId,
                        numero: ('00000000' + task.orden_trabajo_numero).slice(-8),
                        activoId: task.activoId,
                        checked: task.checked
                    })
                }
            }
        }).finally(() => {
            const unique = _.uniqBy(tareas, (item) => item.numero)
            let ordenes = [];
            ordenes.push({
                value: -1,
                label: "TODAS"
            })
            for (const key in unique) {
                ordenes.push({
                    value: unique[key].ordenId,
                    label: unique[key].numero
                })
            }
            this.setState({
                tareas,
                tareasFiltro: tareas,
                ordenes,
                ordensel: {
                    value: -1,
                    label: "TODAS"
                },
                loading: false,
            })
        })
    }



    showActivo = (id) => {
        history.push('/createassest/equipment', { id });
    }

    finishTask = (value) => {
        let { taskSel, tareas, checked } = this.state;
        const index = tareas.findIndex(element => element.id === taskSel);
        const userId = localStorage.getItem('id_token');
        if (index >= 0) {
            Api.updateTask({
                ordenTrabajoCheckListId: tareas[index].id,
                checked: value,
                fechaFinalizacion: value ? moment().format("YYYY-MM-DD") : null,
                checkedByUser: {
                    usuarioId: userId
                },
                ordenTrabajo: {
                    ordenTrabajoId: tareas[index].ordenId
                }
            }).then((res) => {
                if (res) {
                    tareas[index].checked = value
                    if (value && !checked) {
                        tareas.splice(index, 1);
                    }
                    this.setState({
                        tareas,
                        confirm: false
                    })
                }
            })
        }

    }

    filter = () => {
        const { ordensel, tareasFiltro } = this.state;
        if (ordensel.value === -1) {
            this.setState({
                tareas: tareasFiltro
            })
        } else {
            this.setState(
                { tareas: _.filter(tareasFiltro, (o) => { return o.ordenId === ordensel.value; }) }
            )
        }

    }

    updateList = () => {
        this.listTareas();
    }

    printOrder = (orderId) => {
        window.open(`${server}/report/orden/${orderId}`, "_blank")
    }

    render() {
        if (this.state.loading) return <Loading />;
        // console.log(this.state.tareas)
        return (
            <Container menuId="tareas" submenu="asignadas" taskToggled>
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={8} md={6} sm={12} >
                                <h2>Tareas Asignadas</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Tareas Asignadas</BreadcrumbItem>
                                </Breadcrumb>
                                <Button className="btn btn-primary btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                            <Col lg={4} md={6} sm={12} >
                                <div className="checkbox" >
                                    <Input id="procheck1" type="checkbox" checked={this.state.checked} onChange={() => this.setState({ checked: !this.state.checked }, () => this.updateList())} />
                                    <Label htmlFor="procheck1">Mostrar Tareas Finalizadas</Label>
                                </div>
                                <Select noOptionsMessage={() => 'Sin Resultados'}
                                    value={this.state.ordensel}
                                    onChange={(selectedOption) => {
                                        this.setState({ ordensel: selectedOption }, () => this.filter());
                                    }}
                                    options={this.state.ordenes}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Card>
                        <Table hover responsive className={"c_table theme-color mb-0 c_list"}>
                            <thead>
                                <tr>
                                    <TableHeaderlist title="#" />
                                    <TableHeaderlist title="Activo" />
                                    <TableHeaderlist title="#Orden" />
                                    <TableHeaderlist title="Tarea" />
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    this.state.tareas.map((e, i) => {
                                        return (
                                            <tr key={e.id}>
                                                <td>
                                                    <div className="checkbox" onClick={() => this.setState({ taskSel: e.id, confirm: true })}>
                                                        <Input id={"delete_" + e.id} type="checkbox" checked={e.checked} onChange={() => this.setState({ taskSel: e.id, confirm: true })} />
                                                        <Label for={"delete_" + e.id}></Label>
                                                    </div>
                                                </td>
                                                <td className='row align-items-center'>
                                                    <div>
                                                        <img src={e.imagen} className="avatar w40" alt="" />
                                                    </div>
                                                    <div className="c_name">
                                                        <Button color="link" onClick={() => this.showActivo(e.activoId)} className="c_name">{e.nombre}</Button>
                                                    </div>
                                                </td>
                                                {/* <TableItemlist item={e.numero}/> */}
                                                <td>
                                                    <Button color="link" onClick={() => this.printOrder(e.ordenId)} className="c_name">{e.numero}</Button>
                                                </td>
                                                <TableItemlist item={e.tarea} />

                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Card>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.setState({ visible: false })}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.setState({ warning: false })}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.setState({ error: false })}>
                        Error Inesperado
                    </Alert>
                </section>
                <SweetAlert info showCancel
                    confirmBtnText="Completada"
                    cancelBtnText="No Completada"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    focusCancelBtn

                    title={"¿Completar tarea?"}
                    onConfirm={() => this.finishTask(true)}
                    onCancel={() => this.finishTask(false)}
                    show={this.state.confirm}
                >
                    Indique el estado de la tarea, una vez completada se dara como finalizada.
                </SweetAlert>
            </Container>
        )
    }
}