import React from 'react';
import { Button } from 'reactstrap';
import moment from "moment"
import history from './history';
export default function NotFound(){
    return(
        <div className="theme-green">
            <div className="authentication">
            <div className="container">
                <div className="row">
                <div className="col-lg-4 col-sm-12">
                    <form className="card auth_form">
                    <div className="header">
                        <img className="logo" src="assets/images/logo5.png" alt="" />
                        <h5>Error 404</h5>
                        <span>Página No Encontrada</span>
                    </div>
                    <div className="body">
                        <Button onClick={()=>  history.replace('/home')} className="btn btn-success btn-block waves-effect waves-light">IR AL HOME</Button>                                               
                        <div className="signin_with mt-3">
                        <a href="mailto:jeanma.jcgb15@gmail.com?subject=Error 404&body= " className="link App-link">¿Necesitas ayuda?</a>
                        </div>
                    </div>
                    </form>
                        <div className="copyright text-center">
                            <Button color="link" className="App-link"><span>© {moment().format("YYYY")} Desarrollado por</span> Code Computer</Button>
                        </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="card">
                    <img src="assets/images/404.svg" alt={404} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
}