import React from 'react';
import Authentication from "../modules/authentication"
import { Input, Button, Col, Row, Card,InputGroup, InputGroupAddon, InputGroupText,Spinner } from 'reactstrap';
import moment from "moment"

export default class ForgotPassword extends React.Component {
    state = {
        email : "",
        isLoading: false
    }

    send = () => {
        const correo = this.state.email.toLowerCase();
        this.setState({isLoading : true})
       Authentication.forgotPassword({correo}).then((response)=>{
           if(response)
           {
                alert(response.message)
           }else{
               alert("No se pudo enviar")
           }
       }).finally(()=>{
        this.setState({isLoading :false})
       })
       
    }

    render(){
        return(
            <div className="authentication">
                <div className="container">
                    <Row >
                        <Col lg={4} sm={12} >
                            <form className="card auth_form">
                                <div className="header">
                                    <img className="logo" src="assets/images/logo5.png" alt="" />
                                    <h5>¿Olvidó Contraseña?</h5>
                                    <span>Ingrese su correo electronico para resetear contraseña.</span>
                                </div>
                                <div className="body">
                                    <InputGroup className="mb-3">
                                        <Input type="text" className="form-control" placeholder="Correo Electronico" value={this.state.email} onChange={(event)=>this.setState({email : event.target.value})}/>
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText><i className="zmdi zmdi-email" /></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    <Button color="success" onClick={this.send} className="btn-block waves-effect waves-light" >{this.state.isLoading? <Spinner color="light" size="sm"/> :"ENVIAR"}</Button>                        
                                    <div className="signin_with mt-3">
                                        <a href="login" className="link App-link">¿Iniciar Sesión?</a>
                                    </div>
                                </div>
                            </form>
                            <div className="copyright text-center">
                                <Button color="link" className="App-link"><span>© {moment().format("YYYY")} Desarrollado por</span> SIACOM</Button>
                            </div>
                        </Col>
                        <Col lg={8} sm={12}>
                            <Card >
                                <img src="assets/images/signin.svg" alt="Forgot Password" />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}