import React, { useEffect, useState } from 'react';
import '../../App.css';
import Api from "../../modules/activos";
import { Card, CardGroup, Col, Row, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import TableHeaderlist from '../../components/TableHeaderList';
import TableItemlist from '../../components/TableItemList';

export default function ModalDetail(props) {
    const { show, onHide, data } = props;
    //eslint-disable-next-line
    const [categories, setCategories] = useState([]);
    const [activosFiles, setActivosFiles] = useState([]);

    useEffect(() => {
        if (show) {
            console.log("===================> Modal Detail <===============================");
            setActivosFiles(data?.activosFiles)
            getCategoriasLocalizacion();
        }
        //eslint-disable-next-line
    }, [show]);

    const getCategoriasLocalizacion = async () => { //Obtiene las categorias de la localizacion
        let categoriasLocalizacion = []
        Api.listCategoriasLocalizacion().then((response) => {
            if (response) {
                for (let key in response) {
                    categoriasLocalizacion.push({
                        value: response[key].categoriaLocalizacionId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            setCategories(categoriasLocalizacion)
            // console.log(categoriasLocalizacion)
        })
    }

    return (
        <Modal isOpen={show} size="xl" fullscreen="lg" backdrop className="p-0">
            <ModalHeader toggle={() => onHide()}>
                {data.tipoModal === 'detail' ? data.nombre : `Documentos de ${data.nombre}`}
            </ModalHeader>
            <section className="content" style={{ width: '100%', margin: 0 }}>
                <ModalBody>
                    {data.tipoModal === 'detail' ?
                        <CardGroup className="d-flex align-items-top">
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Card className="mb-3">
                                    <div className="header">
                                        <h6><strong>DATOS </strong> BÁSICOS</h6>
                                    </div>
                                    <div className="body">
                                        <small>Nombre: </small>
                                        <p>{data?.nombre || "--"}</p>
                                        <hr />
                                        <small>Codigo: </small>
                                        <p>{data?.codigo || "--"}</p>
                                        <hr />
                                        <small>Categoria: </small>
                                        <p>{data?.tiposActivo?.descripcion || "--"}</p>
                                        <hr />
                                        <small>Descripción: </small>
                                        <p>{data?.descripcion || "--"}</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>

                                <Card className=" mb-3">
                                    <div className="header">
                                        <h6><strong>DATOS </strong> DEL ACTIVO</h6>
                                    </div>
                                    <Row className="body">
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <div >
                                                <small>Modelo</small>
                                                <p>{data?.modelo || "--"}</p>
                                                <hr />
                                                <small>Marca</small>
                                                <p>{data?.marca || "--"}</p>
                                                <hr />
                                                <small>Valor de Medición</small>
                                                <p>{data?.valorLectura || "--"}</p>
                                                <hr />
                                                <small>Unidad de Medición</small>
                                                <p>{data?.unidadMedicion?.descripcion || "--"}</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <div >
                                                <small>Éste activo está localizado en:</small>
                                                <p>{data?.localizacion?.localizacionDescripcion || "--"}</p>
                                                <hr />
                                                <small>Éste activo forma parte de:</small>
                                                <p>--</p>
                                                <hr />
                                                <small>Es un equipo</small>
                                                <p>{data.esEquipo ? 'Si' : 'No'}</p>
                                                <hr />
                                                <small>Es una herramienta</small>
                                                <p>{data.esHerramienta ? 'Si' : 'No'}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </CardGroup> :
                        <CardGroup className="d-flex align-items-top">
                            {activosFiles.length === 0 ? <p>No hay archivos para mostrar</p> :
                                <Table hover responsive className="mb-0 c_table">
                                    <thead>
                                        <tr>
                                            <TableHeaderlist title="Nombre del Archivo" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            activosFiles.map((item, index) => {
                                                let classes = "zmdi zmdi-file-text w25 text-amber"
                                                if (item.tipoArchivo.includes("image")) {
                                                    classes = "zmdi zmdi-collection-folder-image"
                                                }
                                                if (item.tipoArchivo.includes("pdf")) {
                                                    classes = "zmdi zmdi-collection-pdf w25 text-blush"
                                                }
                                                return (<tr key={index}>
                                                    <TableItemlist item={<a href={item.fileUri} style={{ color: "#000" }} target="_blank" rel="noopener noreferrer">
                                                        <span>
                                                            <i className={classes}></i>{
                                                                item.descripcion}</span>
                                                    </a>} />
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </Table>
                            }
                        </CardGroup>
                    }
                </ModalBody>
            </section>
        </Modal>
    )
}