import React, { useState, useEffect } from "react";
import { Col, Row, Input, Button } from "reactstrap";
import { Checkbox } from 'antd';
import Select from 'react-select';

const InformacionBasica = ({ activo, orden, ultimaRevision }) => {
    return (
        <Col>
            <Row className='p-3' style={{ borderTop: '1px solid #000' }} >
                <Col sm={6} md={6} className='p-0'>
                    <ActivoItem item={{ label: 'Ubicación: ', value: activo?.localizacion?.nombre }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Nombre: ', value: activo?.nombre }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Marca: ', value: activo?.marca }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Tipo: ', value: activo?.tiposActivo?.descripcion }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Modelo: ', value: activo?.modelo }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Número de serie: ', value: activo?.numeroSerie }} className='justify-content-start' />
                </Col>
                <Col sm={6} md={6} style={{ paddingTop: 22 }}>
                    <ActivoItem item={{ label: 'RH de producto: ', value: `${activo?.valorLectura} ${activo?.unidad}` }} className='justify-content-end' />
                    <ActivoItem item={{ label: 'Ultima revisión: ', value: ultimaRevision }} className='justify-content-end' />
                    <ActivoItem item={{ label: 'ID de la revisión: ', value: orden?.ordenTrabajoId }} className='justify-content-end' />
                    <ActivoItem item={{ label: 'ID del trabajo: ', value: orden?.ordenTrabajoNumero }} className='justify-content-end' />
                </Col>
            </Row>
            <Row className='p-3' style={{ borderTop: '1px solid #000', borderBottom: '1px solid #000', marginBottom: 5 }} >
                <Col sm={6} md={6} className='p-0'>
                    <ActivoItem item={{ label: '# de tag: ', value: activo?.codigo }} className='justify-content-start' />
                    <ActivoItem item={{ label: 'Sección: ', value: activo?.seccion }} className='justify-content-start' />
                </Col>
                <Col sm={6} md={6} >
                    <ActivoItem item={{ label: 'Lugar: ', value: activo?.lugar }} className='justify-content-end' />
                    <ActivoItem item={{ label: 'Posición: ', value: activo?.posicion }} className='justify-content-end' />
                </Col>
            </Row>
        </Col>
    )
}

const Warning = () => {
    const warnings = [
        {
            id: 1,
            label: ' La ejecución correcta de los trabajos de mantenimiento es condición imprescindible para un servicio seguro y exento de fallos.',
            color: 'yellow'
        },
        {
            id: 2,
            label: ` Los trabajos de mantenimiento de la maquina solo deberán ser realizados por personal técnico autorizado y debidamente instruido.
            Retirar siempre las acumulaciones de polvo, suciedad o producto. Si la maquina se mantiene limpia y se respeten los intervalos de mantenimiento, se incrementa la fiabilidad y limpieza de la máquina.`,
            color: 'orange'
        },
        {
            id: 3,
            label: `Todos los trabajos de mantenimiento, limpieza y revisión se realizarán solo con la maquina completamente parada (todos los movimientos de la maquina
                detenidos). El interruptor de seguridad, que puede cerrarse con llave y que interrumpe el suministro de tensión en todos los polos, debe estar en posición "0" y cerrado.`,
            color: 'red'
        },
    ];

    return (
        <table className="table table-striped table-bordered">
            <tbody>
                {warnings.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ verticalAlign: 'middle' }}>
                                <i className="zmdi zmdi-alert-triangle" style={{ color: item.color, fontSize: 40 }}></i>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                                <p style={{ fontSize: 13 }} className="mb-0">{item.label}</p>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const Actividades = ({ actividades, editable, handleChange, handleChangeCheck }) => {
    return (
        <Col lg={12} md={12} sm={12} className="pl-0 pr-0">
            <div className="table-responsive">
                <table id="mainTable" className="table table-striped table-bordered"
                    style={{ borderColor: '#000' }}
                >
                    <thead>
                        <tr >
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 12, fontWeight: '700', width: 20, borderColor: '#00000066' }} className="p-0">#</th>
                            <th style={{ verticalAlign: 'middle', fontSize: 12, fontWeight: '700', borderColor: '#00000066' }} className="pt-0 pb-0">Descripcion</th>
                            <th style={{ verticalAlign: 'middle', textAlign: 'center', width: 50, fontSize: 12, fontWeight: '700', borderColor: '#00000066' }} className="p-1">Realizado Si/No</th>
                            <th style={{ verticalAlign: 'middle', fontSize: 12, fontWeight: '700', borderColor: '#00000066' }} className="pt-0 pb-0">Comentario</th>
                            {/* <th style={{  }} className="pt-0 pb-0">Accion</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            actividades.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center', borderColor: '#00000066' }} className="p-0 pl-1 pr-1">{index + 1}</td>
                                        <td style={{
                                            verticalAlign: 'middle',
                                            backgroundColor: item.descripcion !== '' ? '#ffffff' : '#dfe5ff',
                                            borderColor: '#00000066'
                                        }} className="p-0">
                                            <Input
                                                disabled={editable}
                                                style={{ border: 0, borderRadius: 0, fontSize: 12 }}
                                                className="p-0 pl-1 pr-1 input_custom_disabled"
                                                rows={3}
                                                type="textarea"
                                                name="descripcion"
                                                id="descripcion"
                                                value={item.descripcion}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td style={{
                                            verticalAlign: 'middle',
                                            backgroundColor: '#b2b6ff',
                                            borderColor: '#00000066'
                                        }} className="d-flex justify-content-center">
                                            <Checkbox
                                                disabled={editable}
                                                name="realizado"
                                                id="realizado"
                                                checked={item.realizado}
                                                onChange={(e) => handleChangeCheck(e, index)}
                                                className='p-2'
                                            />
                                        </td>
                                        <td style={{
                                            verticalAlign: 'middle',
                                            backgroundColor: item.comentario !== '' ? '#fffecd' : '#dfe5cd',
                                            borderColor: '#00000066'
                                        }} className="p-0">
                                            <Input
                                                disabled={editable}
                                                style={{ border: 0, borderRadius: 0, fontSize: 12 }}
                                                className="p-0 pl-1 pr-1 input_custom_disabled"
                                                rows={3}
                                                type="textarea"
                                                name="comentario"
                                                id="comentario"
                                                value={item.comentario}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Col>
    )
}

const FinalizarOrden = ({ editable, handleChange, data, terminado }) => {

    const [comentario, setComentario] = useState(data.comentario);
    const handleChangeComentario = (e) => {
        setComentario(e.target.value);
        handleChange(e);
    }
    return (
        <div className="" >
            <table id="mainTable" className="table table-bordered" style={{ zIndex: 10000 }}>
                <thead>
                    <tr >
                        <th style={{ verticalAlign: 'middle', fontSize: 12, fontWeight: '700', width: 404, borderColor: '#00000066' }} className="pt-0 pb-0">Descripcion</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: 12, fontWeight: '700', width: 68, borderColor: '#00000066' }} className="p-0">Si/No</th>
                        <th style={{ verticalAlign: 'middle', fontSize: 12, fontWeight: '700', borderColor: '#00000066' }} className="pt-0 pb-0">Comentario</th>
                        {/* <th style={{  }} className="pt-0 pb-0">Accion</th> */}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ verticalAlign: 'middle', fontSize: 12, borderColor: '#00000066' }} className="p-1">
                            {/* <Input disabled={editable} style={{ border: 0 }} type="textarea" name="descripcion" id="descripcion" value={descripcion.descripcion} onChange={(e) => handleChangeObservaciones(e)} /> */}
                            Trabajo realizado según el manual de mantenimiento. La aplicacion esta en buenas condiciones y nuevamente puede ser utilizada para operacion.
                        </td>
                        <td style={{ verticalAlign: 'middle', backgroundColor: '#b2b6ff', borderColor: '#00000066', paddingBottom: 17 }} className="d-flex justify-content-center pl-0 pr-0">
                            {/* <Checkbox disabled={editable} name="realizado" id="realizado" checked={descripcion.realizado} onChange={(e) => handleChangeObservaciones(e)} className='p-2' /> */}
                            {editable ?
                                <Input
                                    type="text"
                                    name="realizado"
                                    id="realizado"
                                    value={data.realizado}
                                    className='custom_input input_custom_disabled border-0'
                                    disabled
                                    style={{ textAlign: 'center' }}
                                /> :
                                <Input
                                    type="select"
                                    name="realizado"
                                    id="realizado"
                                    value={data.realizado}
                                    className='custom_input input_custom_disabled'
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={editable}
                                >
                                    {
                                        terminado.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>{item.label}</option>
                                            )
                                        })
                                    }
                                </Input>
                            }
                        </td>
                        <td style={{ verticalAlign: 'middle', backgroundColor: '#b2b6ff', borderColor: '#00000066' }} className="p-0">
                            <Input
                                disabled={editable}
                                className="p-0 pl-1 pr-1 input_custom_disabled"
                                rows={3}
                                style={{ border: 0, borderRadius: 0, fontSize: 12 }}
                                type="textarea"
                                name="comentario"
                                id="comentario"
                                value={comentario}
                                onChange={(e) => handleChangeComentario(e)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const InformacionComplementaria = ({ nuevadata, editable, handleChange, handleTecnicos, handleProveedor, handleAutorizador, tecnicos, terminado, proveedores, handleNuevoProveedor, handleUsuario, modalNuevoUsuario, modalNuevoProveedor }) => {
    const [data, setData] = useState({});
    const [proveedorDisabled, setProveedorDisabled] = useState(false);
    const [tecnicoDisabled, setTecnicoDisabled] = useState(false);

    const [observaciones, setObservaciones] = useState(data.observaciones);
    const handleChangeObservaciones = (e) => {
        setObservaciones(e.target.value);
        handleChange(e);
    }
    useEffect(() => {
        setData(nuevadata);
    }, [nuevadata])

    return (
        <Col lg={12} md={12} sm={12} className="pl-0 pr-0" style={{ zIndex: 1 }}>
            {/* Observaciones */}
            <Input
                disabled={editable}
                type='textarea'
                className="mb-2 input_custom_disabled"
                id='observaciones'
                name='observaciones'
                placeholder='Comentarios'
                value={observaciones}
                onChange={(e) => handleChangeObservaciones(e)}
                style={{
                    fontSize: 13,
                    backgroundColor: data.observaciones !== '' ? 'none' : '#dfe5cd',
                }}
            />
            <FinalizarOrden editable={editable} handleChange={handleChange} data={data} terminado={terminado} />
            <Row className=''>
                <Col lg={6} md={6} sm={6} >
                    <Row className='align-items-center pb-1'>
                        <Col lg={4} md={4} className=''>
                            <label className='mb-0' style={{ fontSize: 14 }}>Realizado por: </label>
                        </Col>
                        <Col lg={8} md={8} className='pl-0'>
                            <Select noOptionsMessage={() => addUsuario(modalNuevoUsuario)}
                                className='custom_select input_custom_disabled'
                                style={{ backgroundColor: '#b2b6ff' }}
                                placeholder={"Seleccione..."}
                                value={data.realizadoPor}
                                isClearable
                                onChange={(value) => {
                                    handleTecnicos({
                                        selected: value
                                    })
                                    setProveedorDisabled(true)
                                    if (value === null) {
                                        setProveedorDisabled(false)
                                    }
                                }}
                                options={tecnicos}
                                isDisabled={tecnicoDisabled || editable}
                                onInputChange={(value) => handleUsuario({ type: 'realizadoPor', value })}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center justifu-content-center pb-1'>
                        <Col lg={4} md={4}>
                            <label style={{}} className='mb-0'>Aprobado por: </label>
                        </Col>
                        <Col lg={8} md={8} className='pl-0'>
                            <Select noOptionsMessage={() => addUsuario(modalNuevoUsuario)}
                                className='custom_select input_custom_disabled'
                                style={{ backgroundColor: '#b2b6ff' }}
                                placeholder={"Seleccione..."}
                                value={data.revisadoPor}
                                isClearable
                                onChange={(value) => {
                                    handleAutorizador({ selected: value });
                                }}
                                options={tecnicos}
                                isDisabled={editable}
                                onInputChange={(value) => handleUsuario({ type: 'revisadoPor', value })}
                            />
                            {/* <Input disabled={editable} style={{ fontSize: 14 }} className='custom_input_bg' type='text' name='revisadoPor' id='revisadoPor' placeholder='' value={data.revisadoPor} onChange={(e) => handleChange(e)} /> */}
                        </Col>
                    </Row>
                    <Row className='align-items-center pb-1'>
                        <Col lg={7} md={7} className=''>
                            <label style={{}} className='mb-0'>Tiempo req. para trabajo (H): </label>
                        </Col>
                        <Col lg={2} md={3} className='pl-0'>
                            <Input
                                disabled={editable}
                                style={{ fontSize: 14 }}
                                className='custom_input_bg'
                                type='number'
                                name='tiempoReq'
                                id='tiempoReq'
                                placeholder=''
                                value={data.tiempoReq}
                                onChange={(e) => handleChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center pb-1'>
                        <Col md={7} className=''>
                            <label style={{}} className='mb-0'>No. de Pers. para trabajo: </label>
                        </Col>
                        <Col md={3} className='pl-0'>
                            <Input disabled={editable} style={{ fontSize: 14 }} className='custom_input_bg' type='number' name='noPersTrabajo' id='noPersTrabajo' placeholder='' value={data.noPersTrabajo} onChange={(e) => handleChange(e)} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={6} sm={6} >
                    <Row className='align-items-center pb-1'>
                        <Col lg={3} md={3} className=''>
                            <label className='mb-0'>Proveedor: </label>
                        </Col>
                        <Col lg={9} md={9} className='pl-0'>
                            <Select
                                noOptionsMessage={() => addProveedor(modalNuevoProveedor)}
                                className='custom_input_bg'
                                style={{ backgroundColor: '#b2b6ff' }}
                                placeholder={"Seleccione..."}
                                isSearchable={true}
                                value={data.trabajoRealizado}
                                isClearable
                                onChange={(value) => {
                                    handleProveedor({ selected: value });
                                    setData({ direccionCompania: value ? value.address : '' })
                                    setTecnicoDisabled(true)
                                    if (value === null) {
                                        setTecnicoDisabled(false)
                                    }
                                }}
                                options={proveedores}
                                isDisabled={proveedorDisabled || editable}
                                onInputChange={(value) => handleNuevoProveedor({ type: 'trabajoRealizado', value })}

                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center pb-1'>
                        <Col lg={3} md={3} className=''>
                            <label style={{}} className='mb-0'>Dirección: </label>
                        </Col>
                        <Col lg={9} md={9} className='pl-0'>
                            <Input disabled={true} style={{}} className='custom_input_bg' rows={3} type='textarea' name='direccionCompania' id='direccionCompania' placeholder='' value={data.direccionCompania} onChange={(e) => handleChange(e)} />
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Col>
    )
}

const addUsuario = (modalNuevoUsuario) => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <p className='mb-0'>No se encontraron resultados</p>
            <Button onClick={() => modalNuevoUsuario()} style={{ marginLeft: 10 }} color='primary' size='sm'>Agregar</Button>
        </div>
    )
};

const addProveedor = (modalNuevoProveedor) => {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <p className='mb-0'>No se encontraron resultados</p>
            <Button onClick={() => modalNuevoProveedor()} style={{ marginLeft: 10 }} color='primary' size='sm'>Agregar</Button>
        </div>
    )
};


const Copyright = ({ fecha }) => {
    return (
        <Row className='justify-content-between align-items-center'>
            <Col className='d-flex justify-content-start'>
                <strong> Siacom </strong>
            </Col>
            <Col className='d-flex justify-content-center'>
                {/* <strong>Forma 1.1</strong> */}
            </Col>

            <Col className='d-flex justify-content-end'>
                <strong>Fecha de la forma: {fecha}</strong>
            </Col>
        </Row>
    )
}

const ActivoItem = ({ item, index, className }) => {
    return (
        <Row key={index} className={`align-items-center ${className}`} >
            <Col sm={5} md={4} className='pl-0 pr-0 d-flex justify-content-end'>
                <p className="mb-0 pr-1" style={{ verticalAlign: 'middle', fontSize: 14 }}>{item.label}</p>
            </Col>
            <Col sm={7} md={7} className='pl-0 pr-0'>
                <strong style={{ verticalAlign: 'middle', fontSize: 13 }} className=''>{item.value}</strong>
            </Col>

        </Row>
    )
}

export {
    InformacionBasica,
    Warning,
    Actividades,
    InformacionComplementaria,
    Copyright
};


// const Item = ({ item, index, editable, handleChangeSection, className }) => {
//     return (
//         <Row key={index} className={`align-items-center ${className}`} >
//             <Col sm={5} md={4} className='pl-0 pr-0 d-flex justify-content-end'>
//                 <p className="mb-0 pr-1" style={{ verticalAlign: 'middle', fontSize: 14 }}>{item.label}</p>
//             </Col>
//             {item.show &&
//                 <Col sm={7} md={8} className='pl-0 pr-0 d-flex align-items-center' >
//                     <Checkbox disabled={editable} checked={item.checked} name='Nivel de riesgo' onChange={e => handleChangeSection(e, index)} className='pr-1' />
//                     <strong style={{ verticalAlign: 'middle', fontSize: 14 }} className='pt-1'>{item.value}</strong>
//                 </Col>
//             }
//             {!item.options && !item.show &&
//                 <Col sm={7} md={7} className='pl-0 pr-0'>
//                     <strong style={{ verticalAlign: 'middle', fontSize: 13 }} className=''>{item.value}</strong>
//                 </Col>
//             }
//             {
//                 item.options &&
//                 <Col sm={4} md={4} className='pl-0 pr-0 pb-1'>
//                     <Select noOptionsMessage={() => 'Sin Resultados'}
//                         placeholder={"Seleccione..."}
//                         value={item.value}
//                         onChange={(value) => handleChangeSection({ selected: value, target: 'select', name: item.label }, index)}
//                         options={item.options}
//                         isDisabled={editable}
//                     />
//                 </Col>
//             }
//         </Row>
//     )
// }