import React from 'react';
import '../App.css';
import { Badge } from 'reactstrap';
import moment from "moment";

export default class CalendarEvent extends React.Component {
    render() {
        const Tag = 'h' + this.props.headinglevel;
        const { fecha, color, descripcion, nombre } = this.props
        return (
            <div className="e_list" style={this.props.style}>
                <Tag className="e_name">{moment(fecha).format("DD MMMM")} <Badge color={color} className="float-right"> {nombre} </Badge></Tag>
                <p className="e_details">{descripcion}</p>
            </div>
        )
    }
}

CalendarEvent.defaultProps = {
    headinglevel: "5",
    color: "primary",
    fecha: moment(),
    nombre: "",
    descripcion: ""
}