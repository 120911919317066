import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Card, Alert, CardGroup, Col, Row, Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import Select from 'react-select';


export default function ModalAssestType(props) {
    // eslint-disable-next-line
    const { show, onHide, data, options, descripcion, setDescripcion, tipoActivoParent, setTipoActivoParent, crear, update, actualizar, selected } = props;
    const [tipo, setTipo] = useState({});
    const [alert, setAlert] = useState(false);
    const [description, setDescription] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [newOptions, setNewOptions] = useState([]);
    useEffect(() => {
        if (show) {
            console.log("===================> Modal crear tipo de activo <===================");
            if (options.length > 0) {
                setNewOptions(options.filter((option) => option?.value !== selected?.tipoActivoId));
            }

            if (selected !== null && selected?.tipoActivoParentId) {
                const selectedOption = options.find((option) => option.value === selected?.tipoActivoParentId?.tipoActivoId);
                setTipo({ value: selectedOption.value, label: selectedOption.label });
                setTipoActivoParent({ value: selectedOption.value, label: selectedOption.label });
            } else {
                setTipo(tipoActivoParent);
            }
            if (selected !== null) {
                setDescription(selected?.descripcion);
            } else {
                setDescription(descripcion);

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const handlerChange = (e) => {
        setDescripcion(e.target.value);
        setDescription(e.target.value);
        setDisabled(false);
    }

    return (
        <Modal isOpen={show} size="md" fullscreen="lg" backdrop className="p-0">
            <ModalHeader toggle={onHide}>{update ? 'Actualizar tipo de activo' : 'Crear tipo de activo'}</ModalHeader>
            <section className="content" style={{ width: '100%', margin: 0 }}>
                <ModalBody>
                    <CardGroup className="d-flex align-items-top">
                        <Card className="">
                            <Col className="body">
                                <Row className='align-items-center pb-1'>
                                    <Col md={3}>
                                        <label>Descripción: </label>
                                    </Col>
                                    <Col md={9}>
                                        <Input
                                            // type="text"
                                            name="descripcion"
                                            // id="descripcion"
                                            placeholder="Descripción"
                                            value={description}
                                            onChange={(e) => handlerChange(e)}
                                        />
                                    </Col>
                                </Row>
                                <Row className='align-items-center'>
                                    <Col md={3}>
                                        <label>Tipo: </label>
                                    </Col>
                                    <Col>
                                        <Select noOptionsMessage={() => 'Sin resultados'}
                                            placeholder={"Seleccione un tipo de activo"}
                                            value={tipo}
                                            isClearable
                                            onChange={(value) => {
                                                console.log("🚀 ~ file: ModalAssestType.js:111 ~ ModalAssestType ~ value:", value)
                                                setTipoActivoParent(value);
                                                setTipo(value);
                                                setDisabled(false);
                                            }}
                                            options={newOptions}
                                        />
                                    </Col>
                                </Row>
                                <Row className='align-items-center'>
                                    <Col>
                                        {update ?
                                            <Button color="primary" disabled={disabled} className="btn-block" onClick={actualizar}>Actualizar</Button> :
                                            <Button color="primary" className="btn-block" onClick={() => descripcion !== '' ? crear() : setAlert(true)}>CREAR</Button>
                                        }
                                    </Col>
                                    <Col>
                                        <Button color="secondary" className="btn-block" onClick={onHide}>Cancelar</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </CardGroup>
                    <Alert
                        color="danger"
                        className='mt-1'
                        isOpen={alert}
                        toggle={() => setAlert(false)}
                    >
                        <strong>¡Error! </strong> Debe ingresar una descripción.
                    </Alert>

                </ModalBody>

            </section>

        </Modal>
    )
}
