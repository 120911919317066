import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import { Table, Button, Pagination, PaginationItem, PaginationLink, Row, Col, Breadcrumb, BreadcrumbItem, Card } from 'reactstrap';
import history from '../history';
import Api from "../modules/ordenTrabajo"
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from "moment"
import ModalRealizarOT from '../components/Modal/ModalRealizarOT';
import Orden from "../modules/ordenTrabajo";

export default class SolicitudesTrabajos extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            solicitudes: [],
            totalPages: 0,
            currentPage: 0,
            pages: [],
            filter: "pendientes",
            filterStatus: 0,
            isOpen: false,
            showModalOT: false,
            modalData: null,
            fechaOT: null,
            tipoOT: 1,
            activo_id: null,
            selectedCheckbox: [],
        }
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        this.setState({ userRol: parseInt(userRol, 10) })
        if (localStorage.getItem('user_rol') !== "1" && localStorage.getItem('user_rol') !== "2" && localStorage.getItem('user_rol') !== "3") {
            history.replace('/404');
        }
        this.getSolicitudes(1, 0, "pendientes");
    }

    getSolicitudes(page, filtro, name) {
        this.setState({ filtro: name, filterStatus: filtro })
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.listOrdersRequests({ page, filtro }).then((res) => {
            if (res) {
                //console.log("🚀 ~ file: SolicitudesTrabajos.js:49 ~ SolicitudesTrabajos ~ Api.listOrdersRequests ~ res:", res)
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: ('00000000' + response[key].solicitudTrabajoId).slice(-8),
                        description: response[key].descripcion,
                        status: response[key].estado,
                        date: moment(response[key].fechaSolicitud).format("DD/MM/YYYY"),
                        asset: response[key].activo.nombre,
                        assetId: response[key].activo.activoId,
                        assetCode: response[key].activo.codigo,
                        assetType: response[key].activo.tiposActivo.tipoActivoId,
                        createdBy: response[key].solicitante ? response[key].solicitante.persona.nombre + " " + response[key].solicitante.persona.apellido : "",
                        created: response[key].solicitante,
                        assignTo: response[key].asignado,
                        data: response[key],
                    })
                }
            }
        }).catch((err) => {
            alert("Error insperado.")
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                solicitudes: data,
                pages,
                currentPage,
                totalPages,
                loading: false,
                filter: name,
            })
        })
    }

    getStatusBadge(status) {
        switch (status) {
            case 0:
                return "badge-info";
            case 1:
                return "badge-warning";
            case 2:
                return "badge-success";
            case 3:
                return "badge-danger"
            default: return "";
        }
    }

    getStatusDescription(status) {
        switch (status) {
            case 0:
                return "Abierto";
            case 1:
                return "En Progreso";
            case 2:
                return "Completado";
            case 3:
                return "No Completada"
            default: return "";
        }
    }

    showActivo = (id) => {
        history.push('/createassest/equipment', { id });
    }

    createOrder(value) {
        //console.log("🚀 ~ file: SolicitudesTrabajos.js:117 ~ SolicitudesTrabajos ~ createOrder ~ value:", value.data)
        this.setState({
            // modalData: value,
            // activo_id: value.assetId,
            // showModalOT: true
        })
        const data = value?.data;
        let selectedCheckbox = [{
            activo_id: data?.activo?.assetId || data?.activo?.activoId,
            codigo: data?.activo?.codigo,
            descripcion: data?.activo?.descripcion,
            estadoMantenimiento: data?.estado,
            fecha_mantenimiento: moment(data?.fechaSolicitud).format("DD/MM/YYYY"),
            nombre: data?.activo?.nombre,
            tipo_activo: data?.activo?.tiposActivo?.tipoActivoId,
            tipo_mantenimiento: 1,
            solicitudTrabajoId: data?.solicitudTrabajoId,
        }];
        history.push('/createorder', { selectedCheckbox });
    }

    // ModalOT = () => {
    //     // if (this.selectedCheckbox?.length > 1) {
    //     //     let selected = [];
    //     //     let selected_id = [];
    //     //     this.selectedCheckbox.forEach((value, i) => {
    //     //         selected.push(value);
    //     //         selected_id.push(value.activo_id);
    //     //     })
    //     //     this.setState({
    //     //         modalData: selected,
    //     //         activo_id: selected_id,
    //     //         showModalOT: true
    //     //     })
    //     // } else {
    //     this.setState({
    //         modalData: this.selectedCheckbox[0],
    //         activo_id: this.selectedCheckbox[0].activo_id,
    //         showModalOT: true
    //     })
    //     // }
    // }

    revisarOT = async () => {
        if (this.state.fechaOT !== null) {
            const data = {
                fecha: moment(this.state.fechaOT, "DD/MM/YYYY").format("YYYY-MM-DD"),
                tipo: this.state.tipoOT,
                activo_id: this.state.activo_id
            }

            if (this.modalData.length > 1) {
                this.modalData.forEach(async (value, i) => {
                    const revision = await Orden.revisionOT({ ...data, activo_id: value.activo_id });
                    if (revision) {
                        this.realizarOT(revision.ordenTrabajoId, this.tipoOT, value)
                    } else {
                        alert("No se pudo generar las ordenes de trabajo")
                    }
                })

            } else {
                const revision = await Orden.revisionOT(data);
                if (revision) {
                    this.realizarOT(revision.ordenTrabajoId, this.tipoOT)
                } else {
                    alert("No se pudo generar la orden de trabajo")
                }
            }
        } else {
            alert("Debe seleccionar una fecha")
        }
    }

    realizarOT = (ordenTrabajoId, tipoOT, item) => { // realizar orden de trabajo
        let width = 950;
        let height = (window.screen.height / 1.2);
        let left = (window.screen.width / 2) - (width / 2) + 50;
        let top = (window.screen.height / 2) - (height / 2) - 25;
        let specs = `width=900,height=${height.toString()},left=${left.toString()},top=${top.toString()}scrollbars=yes,status=yes`;
        if (this.selectedCheckbox?.length > 0) {
            if (this.selectedCheckbox?.length > 1) {

                window.open(`/reporteot?activo_id=${item.activo_id}&ordenTrabajoId=${ordenTrabajoId}&plan_id=${item.plan_id}&tipo_ot=${tipoOT}&fecha=${this.fechaOT}`, '_blank', specs)
                this.setState({
                    showModalOT: false,
                    tipoOT: 1
                })
            } else {
                let activo_id = this.selectedCheckbox[0].activo_id
                let plan_id = this.selectedCheckbox[0].plan_id
                window.open(`/reporteot?activo_id=${activo_id}&ordenTrabajoId=${ordenTrabajoId}&plan_id=${plan_id}&tipo_ot=${tipoOT}&fecha=${this.fechaOT}`, '_blank', specs)
                this.setState({
                    showModalOT: false,
                    tipoOT: 1
                })

            }
        } else {
            alert("Debe seleccionar almenos un plan de mantenimiento")
        }
    };

    handleModalOT = (e) => {
        if (typeof e === "boolean" && e === true) {
            this.setState({ tipoOT: 2 })
            if (typeof e === "boolean" && e === false) {
                this.setState({ tipoOT: 1 })
            }
            if (typeof e === "string") {
                this.setState({ fechaOT: e })
            }
        };
    }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <Container menuId="tareas" submenu="solicitud_trabajo" taskToggled>
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Solicitudes de Trabajos</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Solicitudes de Trabajos</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12}>
                                {<Button color={"success"} className="btn-icon float-right" type="button" onClick={() =>
                                    history.push('/requestorder', { data: null })
                                }>
                                    <i className="zmdi zmdi-plus" />
                                </Button>}
                                <ButtonDropdown direction="left" isOpen={this.state.isOpen} toggle={() => { this.setState({ isOpen: !this.state.isOpen }); }} className="float-right">
                                    <DropdownToggle className="button-boder "  >
                                        <i className="zmdi zmdi-filter-list"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Filtrar Por</DropdownItem>
                                        <DropdownItem
                                            disabled={this.state.filter === "pendientes"}
                                            onClick={() => this.getSolicitudes(1, 0, "pendientes")}>Pendientes</DropdownItem>
                                        <DropdownItem
                                            disabled={this.state.filter === "completadas"}
                                            onClick={() => this.getSolicitudes(1, 2, "completadas")}>Completadas</DropdownItem>
                                        <DropdownItem
                                            disabled={this.state.filter === "en progreso"}
                                            onClick={() => this.getSolicitudes(1, 1, "en progreso")}>En Progreso</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </div>
                    <Card>
                        <Table hover responsive className={"c_table theme-color mb-0 c_list"}>
                            <thead>
                                <tr>
                                    <TableHeaderlist title="No. Solicitud" />
                                    <TableHeaderlist title="Activo" />
                                    <TableHeaderlist title="Descripción" />
                                    <TableHeaderlist title="Creado Por" />
                                    <TableHeaderlist title="Fecha" />
                                    <TableHeaderlist title="Estado" />
                                    {(this.state.userRol < 3 || this.state.userRol === 6) && <TableHeaderlist title="Acción" />}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.solicitudes.map((value, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><strong>{'A' + value.id}</strong></td>
                                                <td><Button color="link" className="c_name" onClick={() => this.showActivo(value.assetId)}>{value.asset}</Button></td>
                                                <td>{value.description}</td>
                                                <td>{value.createdBy}</td>
                                                <td>{value.date}</td>
                                                <td><span className={"badge " + this.getStatusBadge(value.status)}>{this.getStatusDescription(value.status)}</span></td>
                                                {(this.state.userRol < 3 || this.state.userRol === 6) && <td><Button title="Crear Orden de Trabajo" color="default" disabled={value.status > 0} onClick={() => this.createOrder(value)} size="sm"><i className="zmdi zmdi-assignment-o" /></Button></td>}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                        <div className="justify-content-center">
                            <Pagination className="pagination pagination-success mt-4">
                                {
                                    this.state.pages.map((value, index) => {
                                        return (
                                            <PaginationItem key={index} active={value === this.state.currentPage}>
                                                <PaginationLink href="#" onClick={() => this.getSolicitudes(value, this.state.filterStatus, this.state.filter)}>{value}</PaginationLink>
                                            </PaginationItem>
                                        );
                                    })
                                }
                            </Pagination>
                        </div>
                    </Card>
                </section>
                <ModalRealizarOT
                    show={this.state.showModalOT}
                    onHide={() => this.setState({ showModalOT: false })}
                    data={this.state.modalData}
                    RealizarOT={this.revisarOT}
                    handleModal={this.handleModal}
                />
            </Container>
        );
    }
}