import { server } from './config'
import client from './client'

export default {
    dashboard (data, config = {}) {
		return client.get(`${server}/user/dashboard/${data.id}`)
    },
    solicitudes (data, config = {}) {
      return client.get(`${server}/solicitud?p=${data.page}&id=${data.id}`)
      },
}