import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import EquipmentandPropertiesComponent from "../components/EquipmentandPropertiesComponent"
import Api from "../modules/inventario"
import { Alert } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class EquipmentandProperties extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            confirm: false,
            productoId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: ""
        }
        this.deleteProducto = this.deleteProducto.bind(this)
    }

    componentDidMount() {
        this.listProductos(1);
    }

    onSearch = (search, page) => {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            Api.listProductoBySearch({ search, page }).then(res => {
                if (res) {
                    const response = res.data;
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].productoId,
                            nombre: response[key].nombre,
                            codigo: response[key].codigo,
                            existencia: response[key].existenciaActual,
                            ubicacion: response[key].ubicacionDetalle,
                            unidad: response[key].unidad
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listProductos(page);
        }
    }

    async listProductos(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.list({ page }).then(res => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].productoId,
                        nombre: response[key].nombre,
                        codigo: response[key].codigo,
                        existencia: response[key].existenciaActual,
                        ubicacion: response[key].ubicacionDetalle,
                        unidad: response[key].unidad
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    updateProducto(id) {
        history.push('/createequipment', { id });
    }

    deleteProducto = () => {
        const { productoId } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(productoId)
        Api.delete({ id: productoId }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.listProductos(1))
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }


    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    showDialog = (value) => {
        this.setState({ confirm: true, productoId: value })
    }

    render() {
        const { data, loading } = this.state;
        return (
            <Container menuId="reservas" submenu="repuestos_materiales" inventoryToggled>
                {loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <EquipmentandPropertiesComponent data={data}
                        updateItem={(id) => this.updateProducto(id)}
                        deleteItem={(id) => this.showDialog(id)}
                        onSearch={(search, page) => this.onSearch(search, page)}
                        currentPage={this.state.currentPage}
                        totalPages={this.state.totalPages}
                        pages={this.state.pages}
                    />
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, eliminar"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deleteProducto}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    Ésta acción es irreversible
                </SweetAlert>
            </Container>
        )
    }
}