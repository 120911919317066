let terminado = [
    { value: 'null', label: '...' },
    { value: 'true', label: 'Si' },
    { value: 'false', label: 'No' },
]
//eslint-disable-next-line
const tipoMantenimiento = [
    { label: 'Preventivo', value: 1 },
    { label: 'Correctivo', value: 2 },
    { label: 'Predictivo', value: 3 },
    { label: 'Calibración', value: 4 },
]

//eslint-disable-next-line
const estado = [
    { value: "1", label: "Abierta" },
    { value: "2", label: "En Progreso" },
    { value: "3", label: "Completada" },
    { value: "4", label: "No Completada" },
    { value: "5", label: "Vencido" },
]

//eslint-disable-next-line
const prioridad = [
    { value: "1", label: "Alta" },
    { value: "2", label: "Media" },
    { value: "3", label: "Baja" },
]

//eslint-disable-next-line
const option2 = [
    { label: 'Bueno', value: 'Bueno' },
    { label: 'Regular', value: 'Regular' },
    { label: 'Malo', value: 'Malo' },
]

//eslint-disable-next-line
const tipoOrden = [
    { value: 1, label: "Orden de Trabajo" },
    { value: 2, label: "Orden de Servicio" },
]



export {
    terminado,
    tipoMantenimiento,
    estado,
    prioridad,
    option2,
    tipoOrden,
}