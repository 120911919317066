import React from 'react';
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import { Button, InputGroup, InputGroupAddon, InputGroupText, Input, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
export default class EquipmentandPropertiesComponent extends React.Component {

    state = {
        search: ""
    }

    updateItem = (value) => {
        const { updateItem } = this.props;
        updateItem(value)
    }

    deleteItem = (value) => {
        const { deleteItem } = this.props;
        deleteItem(value)
    }

    render() {
        return (
            <div className="theme-green">
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <h2>Repuestos y Materiales</h2>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/home" className="App-link"><i className="zmdi zmdi-home" /> Home</a></li>
                                <li className="breadcrumb-item">Inventario</li>
                                <li className="breadcrumb-item active">Repuestos y Materiales</li>
                            </ul>
                            <button className="btn btn-primary btn-icon btn-icon-mini mobile_menu" type="button"></button>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <a href="/createequipment" className="App-link">
                                <button className="btn btn-success btn-icon float-right" type="button">
                                    <i className="zmdi zmdi-plus" />
                                </button>
                            </a>
                            <InputGroup className="float-right">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                </InputGroupAddon>
                                <Input type="text"
                                    onKeyUp={(event) => { if (event.keyCode === 13) this.props.onSearch(this.state.search, 1) }}
                                    className="form-control"
                                    placeholder="Busqueda..."
                                    value={this.state.search}
                                    onChange={(event) => this.setState({ search: event.target.value })} />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="card">
                                <Table responsive hover className="mb-0 c_list c_table theme-color">
                                    <thead>
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Nombre" />
                                            <TableHeaderlist title="Código" />
                                            <TableHeaderlist title="Existencia" />
                                            <TableHeaderlist title="Ubicación" />
                                            <TableHeaderlist title="Unidad" />
                                            <TableHeaderlist title="Accion" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.data.map((value, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <TableItemlist item={((this.props.currentPage - 1) * 5) + (i + 1)} />
                                                        <TableItemlist item={value.nombre} />
                                                        <TableItemlist item={value.codigo} />
                                                        <TableItemlist item={value.existencia} />
                                                        <TableItemlist item={value.ubicacion} />
                                                        <TableItemlist item={value.unidad} />
                                                        <td>
                                                            <Button onClick={() => this.updateItem(value.id)} className="btn btn-primary btn-sm"><i className="zmdi zmdi-edit" /></Button>
                                                            <Button onClick={() => this.deleteItem(value.id)} className="btn btn-danger btn-sm"><i className="zmdi zmdi-delete" /></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="justify-content-center">
                                    <Pagination className="pagination pagination-success mt-4">
                                        {
                                            this.props.pages.map((value, index) => {
                                                return (
                                                    <PaginationItem active={value === this.props.currentPage} key={index}>
                                                        <PaginationLink href="#" onClick={() => this.props.onSearch(this.state.search, value)}>{value}</PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })
                                        }
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}                