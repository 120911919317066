import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import {
    Input, Button, Alert, Col, Row, Card,
    FormGroup, Breadcrumb,
    BreadcrumbItem, Spinner
} from 'reactstrap';
import Api from "../modules/inventario"
import Proveedor from "../modules/proveedor"
import Select from 'react-select';
import history from '../history';
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai';
import NumberFormat from 'react-currency-format';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';

export default class CreateEquipmentandProperties extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            nombre: "",
            modelo: "",
            marca: "",
            codigo: "",
            existencia: 0,
            stockMinimo: 0,
            costoActual: 0,
            descripcion: "",
            especificaciones: "",
            photoUrl: "",
            categorias: [],
            ubicaciones: [],
            proveedores: [],
            unidades: [{
                value: "pza",
                label: "pza"
            },
            {
                value: "lts",
                label: "lts"
            },
            {
                value: "kg",
                label: "kg"
            }],
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            editing: false,
            usuarioId: undefined,
            loading: true,
            preview: "",
            costo: "",
            existenciaMinima: "",
            existenciaActual: "",
            pasillo: "",
            estante: "",
            contenedor: "",
            categoriaError: "none",
            ubicacionError: "none",
            unidadError: "none",
            categoria: null,
            ubicacion: null,
            unidad: null
        }
    }

    async componentDidMount() {
        const state = this.props.location.state
        await this.getCategorias();
        await this.getUbicaciones();
        await this.getProveedores();
        if (state && state.id) {
            await this.getProductoData(state.id)
        }
    }

    async getProductoData(id) {
        this.setState({ loading: true })
        Api.get({ id }).then((response) => {
            if (response) {
                this.setState({
                    productoId: response.productoId,
                    nombre: response.nombre,
                    modelo: response.modelo,
                    marca: response.marca,
                    codigo: response.codigo,
                    existencia: response.existenciaActual,
                    stockMinimo: response.stockMinimo,
                    costoActual: response.costo,
                    descripcion: response.descripcion,
                    especificaciones: response.especificaciones,
                    photoUrl: response.fotoUri,
                    unidad: {
                        value: response.unidad,
                        label: response.unidad
                    },
                    categoria: {
                        value: response.categoria.categorialId,
                        label: response.categoria.descripcion
                    },
                    ubicacion: {
                        value: response.ubicacion.ubicacionlId,
                        label: response.ubicacion.descripcion
                    },
                    proveedor: response.proveedor ? {
                        value: response.proveedor.proveedorId,
                        label: response.proveedor.persona.nombre
                    } : null,
                    preview: response.fotoUri,
                    costo: response.costo >= 0 ? response.costo + "" : "",
                    existenciaMinima: response.stockMinimo >= 0 ? response.stockMinimo + "" : "",
                    existenciaActual: response.existenciaActual,
                    pasillo: response.pasillo,
                    estante: response.estante,
                    contenedor: response.contenedor,
                    editing: true,
                })
                if (response.fotoUri !== "" && this.dropzone) {
                    this.dropzone.className = "file-wrapper has-preview"
                }
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    async getCategorias() {
        let categorias = []
        Api.listCategorias().then((response) => {
            if (response) {
                for (let key in response) {
                    categorias.push({
                        value: response[key].categoriaId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ categorias })
        })
    }

    async getUbicaciones() {
        let ubicaciones = []
        Api.listUbicaciones().then((response) => {
            if (response) {
                for (let key in response) {
                    ubicaciones.push({
                        value: response[key].ubicacionId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ ubicaciones, loading: false })
        })
    }

    async getProveedores() {
        let proveedores = []
        Proveedor.listBy().then((response) => {
            if (response) {
                for (let key in response) {
                    proveedores.push({
                        value: response[key].proveedorId,
                        label: response[key].persona.nombre
                    })
                }
            }
        }).finally(() => {
            this.setState({ proveedores, loading: false })
        })
    }

    selectCategoria = selectedOption => {
        this.setState(
            { categoria: selectedOption }
        );
    };

    selectUbicacion = selectedOption => {
        this.setState(
            { ubicacion: selectedOption }
        );
    };

    selectUnidad = selectedOption => {
        this.setState(
            { unidad: selectedOption }
        );
    };

    selectProveedor = selectedOption => {
        this.setState(
            { proveedor: selectedOption }
        );
    };

    save() {
        this.setState({ disabled: true, isLoading: true })
        const { costoActual, nombre, file, modelo, marca, codigo, existencia, descripcion, especificaciones, categoria, ubicacion, unidad, proveedor, stockMinimo, contenedor, pasillo, estante } = this.state;
        if (file) {
            //upload picture test
            const data = new FormData();
            data.append('picture', file);
            Api.upload(data).then((response) => {
                if (response && response.success) {
                    const data1 = {
                        nombre,
                        descripcion,
                        modelo,
                        marca,
                        codigo,
                        fotoUri: response.donwloadUrl,
                        stockMinimo,
                        existenciaActual: existencia,
                        costo: costoActual,
                        codigoBarra: "",
                        unidad: unidad.value,
                        proveedor: !proveedor ? null : {
                            proveedorId: proveedor.value,
                            descripcion: proveedor.label
                        },
                        especificaciones,
                        categoria: {
                            categoriaId: categoria.value,
                            descripcion: categoria.label
                        },
                        ubicacion: {
                            ubicacionId: ubicacion.value,
                            descripcion: ubicacion.label
                        },
                        ubicacionDetalle: ubicacion.label + (pasillo !== "" ? "/" + pasillo : "") + (estante !== "" ? "/" + estante : "") + (contenedor !== "" ? "/" + contenedor : ""),
                        contenedor,
                        pasillo,
                        estante
                    }
                    Api.save(data1).then((response1) => {
                        if (response1 && response1.productoId > 0) {
                            this.setState({
                                visible: true,
                                message: "Producto Creado.",
                            })
                        } else {
                            if (response1.warning) {
                                this.setState({
                                    warning: true,
                                    message: response1.message,
                                    disabled: false
                                })
                            } else {
                                this.setState({ error: true, disabled: false })
                            }

                        }
                    }).finally(() => {
                        this.setState({ isLoading: false })
                    })
                }
            })
        } else {
            const data = {
                nombre,
                descripcion,
                modelo,
                marca,
                codigo,
                fotoUri: "",
                stockMinimo,
                existenciaActual: existencia,
                costo: costoActual,
                codigoBarra: "",
                unidad: unidad.value,
                proveedor: !proveedor ? null : {
                    proveedorId: proveedor.value,
                    descripcion: proveedor.label
                },
                especificaciones,
                categoria: {
                    categoriaId: categoria.value,
                    descripcion: categoria.label
                },
                ubicacion: {
                    ubicacionId: ubicacion.value,
                    descripcion: ubicacion.label
                },
                ubicacionDetalle: ubicacion.label + (pasillo !== "" ? "/" + pasillo : "") + (estante !== "" ? "/" + estante : "") + (contenedor !== "" ? "/" + contenedor : ""),
                contenedor,
                pasillo,
                estante
            }
            Api.save(data).then((response) => {
                if (response && response.productoId > 0) {
                    this.setState({
                        visible: true,
                        message: "Producto Creado.",
                    })
                } else {
                    if (response.warning) {
                        this.setState({
                            warning: true,
                            message: response.message,
                            disabled: false
                        })
                    } else {
                        this.setState({ error: true, disabled: false })
                    }

                }
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }

    }

    update() {
        this.setState({ disabled: true, isLoading: true })
        const { productoId, costoActual, nombre, file, modelo, marca, codigo, existencia, descripcion, especificaciones, categoria, ubicacion, unidad, proveedor, stockMinimo, pasillo, estante, contenedor } = this.state;
        if (file) {
            //upload picture test
            const data = new FormData();
            data.append('picture', file);
            Api.upload(data).then((response) => {
                if (response && response.success) {
                    const data1 = {
                        productoId,
                        nombre,
                        descripcion,
                        modelo,
                        marca,
                        codigo,
                        fotoUri: response.donwloadUrl,
                        stockMinimo,
                        existenciaActual: existencia,
                        costo: costoActual,
                        codigoBarra: "",
                        unidad: unidad.value,
                        proveedor: !proveedor ? null : {
                            proveedorId: proveedor.value,
                            descripcion: proveedor.label
                        },
                        especificaciones,
                        categoria: {
                            categoriaId: categoria.value,
                            descripcion: categoria.label
                        },
                        ubicacion: {
                            ubicacionId: ubicacion.value,
                            descripcion: ubicacion.label
                        },
                        ubicacionDetalle: ubicacion.label + (pasillo !== "" ? "/" + pasillo : "") + (estante !== "" ? "/" + estante : "") + (contenedor !== "" ? "/" + contenedor : ""),
                        contenedor,
                        pasillo,
                        estante
                    }
                    Api.update(data1).then((response1) => {
                        if (response1) {
                            this.setState({
                                visible: response.success,
                                warning: response.warning,
                                message: response.message,
                                disabled: false
                            })
                        } else {
                            this.setState({ error: true, disabled: false })
                        }
                    }).finally(() => {
                        this.setState({ isLoading: false })
                    })
                }
            })
        } else {
            const data = {
                productoId,
                nombre,
                descripcion,
                modelo,
                marca,
                codigo,
                fotoUri: "",
                stockMinimo,
                existenciaActual: existencia,
                costo: costoActual,
                codigoBarra: "",
                unidad: unidad.value,
                proveedor: !proveedor ? null : {
                    proveedorId: proveedor.value,
                    descripcion: proveedor.label
                },
                especificaciones,
                categoria: {
                    categoriaId: categoria.value,
                    descripcion: categoria.label
                },
                ubicacion: {
                    ubicacionId: ubicacion.value,
                    descripcion: ubicacion.label
                },
                ubicacionDetalle: ubicacion.label + (pasillo !== "" ? "/" + pasillo : "") + (estante !== "" ? "/" + estante : "") + (contenedor !== "" ? "/" + contenedor : ""),
                contenedor,
                pasillo,
                estante
            }
            Api.update(data).then((response) => {
                if (response) {
                    this.setState({
                        visible: response.success,
                        warning: response.warning,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    onDrop = (acceptedFiles) => {
        //console.log(acceptedFiles,"acceptedFiles")
        this.setState({
            preview: URL.createObjectURL(acceptedFiles[0]),
            file: acceptedFiles[0]
        })
        //console.log(URL.createObjectURL(acceptedFiles[0]),"preview")
        this.dropzone.className = "file-wrapper has-preview"
    }

    removeImage = () => {
        this.setState({
            preview: ""
        })
        this.dropzone.className = "file-wrapper"
    }

    validate() {
        //validate selects
        const { categoria, ubicacion, unidad } = this.state;
        this.setState({
            categoriaError: categoria === null ? "block" : "none",
            ubicacionError: ubicacion === null ? "block" : "none",
            unidadError: unidad === null ? "block" : "none",
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        //validate select
        const { categoriaError, ubicacionError, unidadError } = this.state;
        if (categoriaError === "none" && ubicacionError === "none" && unidadError === "none") {
            this.state.editing ? this.update() : this.save()
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    render() {
        const { categorias, ubicaciones, loading, unidades, proveedores } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="reservas">
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={12} md={12} sm={12}>
                                <h2>Crear Repuestos y Materiales</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Inventario</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link active" href="/manageequipment" tag="a">Repuestos y Materiales</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Crear Repuestos y Materiales</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                        </Row>
                    </div>
                    <AvForm onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}
                        onInvalidSubmit={() => this.handleInvalidSubmit()}>
                        <Card >
                            <div className="body">
                                <Row className="clearfix">
                                    <div className="body ">
                                        <Dropzone onDrop={this.onDrop} multiple={false} accept="image/*">
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="file-wrapper" ref={e => this.dropzone = e}>
                                                    <div {...getRootProps({ className: 'file-message' })}>
                                                        <AiOutlineCloudUpload size={50} color={"#CCC"} />
                                                        <p>Arrastra la imagen o hacer click aqui</p>
                                                    </div>
                                                    <input {...getInputProps()} className={"form-control-file"} />
                                                    <Button className="file-clear" onClick={() => this.removeImage()}>Quitar</Button>
                                                    <div className="file-preview" style={{ display: this.state.preview && this.state.preview !== "" ? "block" : "none" }}>
                                                        <img src={this.state.preview} alt="" />
                                                        <div className="file-infos">
                                                            <div className="file-infos-inner">
                                                                <p className="file-filename"><span className="file-icon"></span> <span className="file-filename-inner">1caf54794d2ce9c62f00ee45f4f9d963.jpg</span></p>
                                                                <p className="file-infos-message">Arrastra la imagen o hacer click para reemplazar</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <Col sm={8} style={{ paddingTop: 20 }}>
                                        <Row className="clearfix">
                                            <Col sm={4} >
                                                <FormGroup>
                                                    <AvField name="codigo"
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.nombreRef.focus() }}
                                                        type="text"
                                                        value={this.state.codigo}
                                                        onChange={(event) => this.setState({ codigo: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Codigo"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese código' },
                                                            pattern: { value: '^[A-z0-9 -]+$', errorMessage: 'El código debe contener solo letras y numeros' },
                                                            maxLength: { value: 15, errorMessage: 'El código debe tener menos de 15 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={8} >
                                                <FormGroup>
                                                    <AvField name="nombre"
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.modeloRef.focus() }}
                                                        type="text"
                                                        innerRef={e => this.nombreRef = e}
                                                        value={this.state.nombre}
                                                        onChange={(event) => this.setState({ nombre: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Nombre del Repuesto o Material"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese el nombre del repuesto o material' },
                                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                            maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <AvField name="modelo"
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.marcaRef.focus() }}
                                                        innerRef={e => this.modeloRef = e}
                                                        type="text"
                                                        value={this.state.modelo}
                                                        onChange={(event) => this.setState({ modelo: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Modelo"
                                                        validate={{
                                                            required: { value: false, errorMessage: '' },
                                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El modelo debe contener solo letras y numeros' },
                                                            maxLength: { value: 50, errorMessage: 'El modelo debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <AvField name="marca"
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.unidadRef.focus() }}
                                                        innerRef={e => this.marcaRef = e}
                                                        type="text"
                                                        value={this.state.marca}
                                                        onChange={(event) => this.setState({ marca: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Marca"
                                                        validate={{
                                                            required: { value: false, errorMessage: '' },
                                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'La marca debe contener solo letras y numeros' },
                                                            maxLength: { value: 50, errorMessage: 'La marca debe tener menos de 50 caracteres' }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.unidadError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                        ref={ref => { this.unidadRef = ref; }}
                                                        placeholder={"-- Unidad --"}
                                                        value={this.state.unidad}
                                                        onChange={this.selectUnidad}
                                                        options={unidades}
                                                        onBlur={() => { this.state.unidad === null ? this.setState({ unidadError: "block" }) : this.setState({ unidadError: "none" }) }}
                                                    />
                                                    <div style={{ display: this.state.unidadError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione una unidad</div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={4}>
                                                <Select noOptionsMessage={() => 'Sin Resultados'}
                                                    styles={{
                                                        container: base => ({
                                                            ...base,
                                                            border: this.state.categoriaError === "block" ? "1px solid #ced4da" : "none",
                                                            borderRadius: ".25rem",
                                                            borderColor: "#dc3545"
                                                        }),
                                                    }}
                                                    ref={ref => { this.categoriaRef = ref; }}
                                                    placeholder={"-- Categoria --"}
                                                    value={this.state.categoria}
                                                    onChange={this.selectCategoria}
                                                    options={categorias}
                                                    onBlur={() => { this.state.categoria === null ? this.setState({ categoriaError: "block" }) : this.setState({ categoriaError: "none" }) }}
                                                />
                                                <div style={{ display: this.state.categoriaError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione una categoría</div>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <AvGroup>
                                                        <AvInput tag={NumberFormat}
                                                            name="existencia"
                                                            onKeyUp={(event) => { if (event.keyCode === 13) this.minimaRef.focus() }}
                                                            innerRef={e => this.existenciaRef = e}
                                                            value={this.state.existenciaActual}
                                                            customInput={Input}
                                                            thousandSeparator={true}
                                                            prefix={''}
                                                            allowNegative={false}
                                                            placeholder="Existencia Actual"
                                                            // hintText="Existencia Actual"
                                                            disabled={this.state.editing}
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                this.setState({ existenciaActual: formattedValue, existencia: value })
                                                            }}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Ingrese existencia actual' },
                                                            }}
                                                        />
                                                        <AvFeedback>Ingrese existencia actual</AvFeedback>
                                                    </AvGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <AvGroup>
                                                        <AvInput tag={NumberFormat}
                                                            name="minima"
                                                            onKeyUp={(event) => { if (event.keyCode === 13) this.costoRef.focus() }}
                                                            innerRef={e => this.minimaRef = e}
                                                            value={this.state.existenciaMinima}
                                                            customInput={Input}
                                                            thousandSeparator={true}
                                                            prefix={''}
                                                            allowNegative={false}
                                                            placeholder="Existencia Minima"
                                                            // hintText="Existencia Minima"
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                this.setState({ existenciaMinima: formattedValue, stockMinimo: value })
                                                            }}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Ingrese existencia Minima' },
                                                            }}
                                                        />
                                                        <AvFeedback>Ingrese existencia minima</AvFeedback>
                                                    </AvGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="clearfix">
                                            <Col sm={4}>
                                                <FormGroup>
                                                    <AvGroup>
                                                        <AvInput tag={NumberFormat}
                                                            name="costo"
                                                            onKeyUp={(event) => { if (event.keyCode === 13) this.descripcionRef.focus() }}
                                                            innerRef={e => this.costoRef = e}
                                                            value={this.state.costo}
                                                            customInput={Input}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            placeholder="Costo Actual"
                                                            // hintText="Costo Actual"
                                                            allowNegative={false}
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                this.setState({ costo: formattedValue, costoActual: value })
                                                            }}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Ingrese existencia Minima' },
                                                            }}
                                                        />
                                                        <AvFeedback>Ingrese costo actual</AvFeedback>
                                                    </AvGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={8}>
                                                <AvField name="descripcion"
                                                    onKeyUp={(event) => { if (event.keyCode === 13) this.unidadRef.focus() }}
                                                    innerRef={e => this.descripcionRef = e}
                                                    type="text"
                                                    value={this.state.descripcion}
                                                    onChange={(event) => this.setState({ descripcion: event.target.value })}
                                                    rows="4"
                                                    className="form-control no-resize"
                                                    placeholder="Descripción"
                                                    validate={{
                                                        required: { value: false, errorMessage: '' },
                                                        pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'La descripción debe contener solo letras y numeros' },
                                                        maxLength: { value: 150, errorMessage: 'La descripción debe tener menos de 150 caracteres' }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Card >
                            <div className="body">
                                <Row className="clearfix">
                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.ubicacionError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={ref => { this.ubicacionRef = ref; }}
                                                placeholder={"-- Seleccione ubicacion --"}
                                                value={this.state.ubicacion}
                                                onChange={this.selectUbicacion}
                                                options={ubicaciones}
                                                onBlur={() => { this.state.ubicacion === null ? this.setState({ ubicacionError: "block" }) : this.setState({ ubicacionError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.ubicacionError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione una ubicación</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col lg={4} md={12}>
                                        <FormGroup>
                                            <AvField name="pasillo"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.estanteRef.focus() }}
                                                innerRef={e => this.pasilloRef = e}
                                                placeholder="Pasillo"
                                                value={this.state.pasillo}
                                                onChange={(event) => this.setState({ pasillo: event.target.value })}
                                                className="form-control"
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre del pasillo debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre del pasillo debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={12}>
                                        <FormGroup>
                                            <AvField name="estante"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.contenedorRef.focus() }}
                                                innerRef={e => this.estanteRef = e}
                                                placeholder="Estante"
                                                value={this.state.estante}
                                                onChange={(event) => this.setState({ estante: event.target.value })}
                                                className="form-control"
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre del estante debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre del estante debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4} md={12}>
                                        <FormGroup>
                                            <AvField name="contenedor"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.contenedorRef.focus() }}
                                                innerRef={e => this.contenedorRef = e}
                                                placeholder="Contenedor"
                                                value={this.state.contenedor}
                                                onChange={(event) => this.setState({ contenedor: event.target.value })}
                                                className="form-control"
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El nombre del contenedor debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre del contenedor debe tener menos de 50 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col lg={12} md={12}>
                                        <FormGroup>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                ref={ref => { this.proveedorRef = ref; }}
                                                placeholder={"-- Seleccione Proveedor --"}
                                                value={this.state.proveedor}
                                                onChange={this.selectProveedor}
                                                options={proveedores}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <AvField name="especificaciones"
                                                type="textarea"
                                                value={this.state.especificaciones}
                                                onChange={(event) => this.setState({ especificaciones: event.target.value })}
                                                rows={4}
                                                className="form-control no-resize"
                                                placeholder="Especificaciones"
                                                innerRef={e => this.especificacionesRef = e}
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    // eslint-disable-next-line
                                                    pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'Las especificaciones deben contener solo letras y numeros' },
                                                    maxLength: { value: 150, errorMessage: 'Las especificaciones deben tener menos de 150 caracteres' }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Button color="success" size="lg" disabled={this.state.disabled} onClick={() => this.validate()} >{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>
                        <Button color="default" size="lg" onClick={() => history.goBack()} >Cancelar</Button>
                    </AvForm>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {/* {this.state.message}<a
                            // href="/#"
                             href="javascript:location.reload()"
                            className="alert-link"> ¿Crear Otro Producto?</a> */}
                        <Button
                            onClick={() => window.location.reload()}
                            // href="javascript:location.reload()"
                            color="link"
                        >{this.state.message} ¿Crear Otro Producto?
                        </Button> :
                        <p>
                            {this.state.message}
                        </p>
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear el nuevo repuesto o material
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
            </Container>
        )
    }
}