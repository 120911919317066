import React from 'react';
import NotificationList from "./NotificationList"
import history from '../history';
import Notificaciones from "../modules/notificaciones"
import moment from "moment"
import SweetAlert from 'react-bootstrap-sweetalert';
//eslint-disable-next-line
import Countdown from 'react-countdown';

export default class RightPanel extends React.Component {
    logout = () => {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_photo');
        localStorage.removeItem('user_rol');
        // navigate to the home route
        history.replace('/login');
    }

    componentDidMount() {
        this.getNotifications();
        const expiresAt = localStorage.getItem('expires_at');
        this.setState({ expiresAt: expiresAt })

    }

    state = {
        data: [],
        showOtherAlert: false,
        descripcion: "",
        title: "",
        icon: "",
        idsel: -1,
        expiresAt: null
    }

    getNotifications() {
        const notificaciones = []
        Notificaciones.list().then((res) => {
            if (res) {
                const data = res.data;
                for (const key in data) {
                    notificaciones.push({
                        id: data[key].notificacionId,
                        description: data[key].descripcion,
                        originalTitle: data[key].titulo,
                        title: data[key].titulo,
                        time: moment().diff(moment(data[key].fecha), 'days') >= 7 ? moment(data[key].fecha).calendar() : moment(data[key].fecha).fromNow(),
                        qty: 1,
                        icon: data[key].icono
                    })
                }
                // console.log(notificaciones)
            }
        }).finally(() => {
            let result = [];
            notificaciones.reduce((res, value) => {
                if (!res[value.originalTitle]) {
                    res[value.originalTitle] = { id: value.id, originalTitle: value.originalTitle, qty: 0, time: value.time, description: "", icon: value.icon };
                    result.push(res[value.originalTitle])
                }
                res[value.originalTitle].qty += value.qty;
                if (value.originalTitle === "Te han asignado 1 herramienta") {
                    res[value.originalTitle].title = "Te han Asignado " + res[value.originalTitle].qty + " Herramienta"
                } else {
                    res[value.originalTitle].title = res[value.originalTitle].qty + " " + value.title;
                }
                res[value.originalTitle].description += "- " + value.description + "\n";
                return res;
            }, {});
            // console.log(result)
            this.setState({ data: result })
        })
    }

    onPress = (item) => {
        this.setState({
            showOtherAlert: true,
            descripcion: item.description,
            title: item.originalTitle,
        }, () => this.setLeido(item.id))
    }

    async setLeido(id) {
        //marca notificaciones vista como leidas
        Notificaciones.update({ notificacionId: id, leido: true });
    }

    render() {
        return (
            <ul className="navbar-nav">
                <li className="dropdown">
                    <a href="/#" className="dropdown-toggle" title="Notificaciones" data-toggle="dropdown" role="button"><i className="zmdi zmdi-notifications" />
                        {this.state.data.length > 0 && <div className="notify"><span className="heartbit" /><span className="point" /></div>}
                    </a>
                    <ul className="dropdown-menu slideUp2">
                        <li className="header">Notificaciones</li>
                        <li className="body">
                            <ul className="menu list-unstyled">
                                {
                                    this.state.data.map((value, index) => {
                                        return <NotificationList key={index} title={value.title} time={value.time} icon={value.icon} onPress={() => this.onPress(value)} />
                                    })
                                }
                            </ul>
                        </li>
                        <li className="footer"> <a href="notification">Mostrar Todas las Notificaciones</a> </li>
                        {/* <li className="footer"> <a href="notification">Tiempo restante para expirar la sesión: <Countdown date={this.state.expiresAt} /></a> </li> */}
                    </ul>
                </li>
                <li>
                    <a href="/#" role="button" onClick={this.logout} title="Salir" color="link" ><i className="zmdi zmdi-power" /></a>
                </li>
                {/* <li>
                    <Button color="link" className="mega-menu" title="Salir"><i className="zmdi zmdi-power" /></Button>
                </li> */}
                <SweetAlert
                    custom
                    confirmBtnText="Aceptar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    customIcon={"assets/images/notificaciones.png"}
                    title={this.state.title}
                    onConfirm={() => this.setState({ showOtherAlert: false })}
                    onCancel={() => this.setState({ showOtherAlert: false })}
                    show={this.state.showOtherAlert}>
                    <div className="display-linebreak">
                        {this.state.descripcion}
                    </div>
                </SweetAlert>
            </ul >
        )
    }
}