import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import TableItemPriority from "../components/TableItemPriority"
import { Label, Button, Alert, InputGroup, InputGroupAddon, InputGroupText, Input, Row, Col, Breadcrumb, BreadcrumbItem, Card, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Spinner } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import OrdenTrabajo from "../modules/ordenTrabajo"
import TableUserList from '../components/TableUserList';
import AsyncSelect from 'react-select/async';
import Inventario from "../modules/inventario"
import NumberFormat from 'react-currency-format';
import Select from 'react-select';
import Activos from "../modules/activos"
import moment from "moment"
import "moment/locale/es";
import _ from "lodash";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import OrderStatus from './OrderStatus';
import NotificationAlert from 'react-notification-alert';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-notification-alert/dist/animate.css";
import ReporteOrdenTrabajo from '../Order/reporte/ReporteOrdenTrabajo';
import history from "../history";
import { Modal as NewModal } from 'antd'
import { server } from "../services/config"
import Api from "../modules/user"


const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}

export default class Order extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            nota: "",
            confirm: false,
            usuarioId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "La orden se ha completado con exito",
            loading: true,
            modal: false,
            numOrder: "",
            productosList: [],
            actividadesList: [],
            modalValor: false,
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: "",
            tareasPopup: false,
            descripcionTarea: "",
            filter: "pendientes",
            fechaFinalizacion: moment(),
            calendarOpen: false,
            modalVisible: true,
            open: false,
            showButton: false,
            componentRef: null,
            onBeforeGetContentResolve: null,
            text: "",
            arryData: [],
            orderSelect: null,
            alerttActividad: false,
            actividadSelect: null,
            indexActividad: null,
            checkBoxLoading: false,
            productoSel: null,
            cantidadtext: "",
            showCompletado: false,
            comentario: "",

        }
    }

    async componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        this.setState({ userRol: parseInt(userRol, 10) })
        this.onSearch(1);
        this.getUnidadesMedicion();
    }

    async listOrdenes(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        OrdenTrabajo.list({ filtro: 1, page }).then(res => {
            if (res) {
                const response = res.data;
                // //console.log(res, "response")
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    let equipo = response[key].usuarioAsignado ? [{
                        src: response[key].usuarioAsignado.fotoUri,
                        name: response[key].usuarioAsignado.persona.nombre
                    }] : []
                    for (let k in response[key].checkList) {
                        const member = response[key].checkList[k];
                        if (member.checkedByUser) {
                            equipo.push({
                                src: member.checkedByUser.fotoUri,
                                name: member.checkedByUser.persona.nombre
                            })
                        }
                    }
                    data.push({
                        id: response[key].ordenTrabajoId,
                        estado: response[key].estado,
                        numero: response[key]?.ordenTrabajoNumero,
                        prioridadValor: response[key].prioridad,
                        prioridad: response[key].prioridad === 1 ? "Alta" : response[key].prioridad === 2 ? "Media" : "Baja",
                        imagenActivo: response[key].activo.fotoUri,
                        codigoActivo: response[key].activo.codigo,
                        nombreActivo: response[key].activo.nombre,
                        activoId: response[key].activo.activoId,
                        equipo,
                        tareas: response[key].checkList,
                        //progreso : this.getProgress(response[key].checkList),
                        productosList: _.filter(response[key].productos, (o) => { return o.activo; }),
                        frecuenciaValueLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : 0,
                        unidadActividadLectura: response[key].activo.unidadMedicion ? {
                            value: response[key].activo.unidadMedicion.unidadMedicionId,
                            label: `${response[key].activo.unidadMedicion.descripcion} (${response[key].activo.unidadMedicion.simbolo})`,
                            simbolo: response[key].activo.unidadMedicion.simbolo
                        } : null,
                        frecuenciaLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : "",
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            //console.log("🚀 ~ file: Order.js:154 ~ Order ~ awaitOrdenTrabajo.list ~ err:", err)
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false,

            })
            console.log("🚀 ~ file: Order.js:162 ~ Order ~ OrdenTrabajo.list ~ data:", data)

        })
    }

    getProgress(tareas) {
        const total = tareas.length;
        let completadas = _.filter(tareas, (o) => { return o.checked; }).length;
        return parseInt((completadas * 100) / total)
    }

    async getUnidadesMedicion() {
        let unidadesMedicion = [];
        Activos.listUnidadesMedicion().then((response) => {
            if (response) {
                for (let key in response) {
                    unidadesMedicion.push({
                        value: response[key].unidadMedicionId,
                        label: `${response[key].descripcion} (${response[key].simbolo})`,
                        simbolo: response[key].simbolo
                    })
                }
            }
        }).finally(() => {
            this.setState({ unidadesMedicion, loading: false })
        })
    }

    finishOrder = async (status, orderSelect) => {

        const { orderid, nota, userRol, fechaFinalizacion } = this.state;
        const tipoMantenimiento = orderSelect?.tipoMantenimiento;
        const checkList = orderSelect?.checkList;
        const tipoOrden = orderSelect?.tipoOrden;
        const resumenProblema = orderSelect?.resumenProblema;
        const responsable = orderSelect?.responsable?.personaId || null;
        const proveedorId = orderSelect?.proveedor?.proveedorId || null;
        const prioridad = orderSelect?.prioridad;
        const tiempoEstimado = orderSelect?.tiempoEstimado;
        const numeroPersonas = orderSelect?.numeroPersonas;
        const autorizador = orderSelect?.autorizador?.personaId || null;
        const id = localStorage.getItem('id_token')
        console.log("🚀 ~ file: Order.js:212 ~ Order ~ finishOrder= ~ id:", id)
        const user = await Api.get({ id: parseInt(id) })
        console.log("🚀 ~ file: Order.js:215 ~ Order ~ finishOrder= ~ user:", user)
        const data = {
            ordenTrabajoId: orderid,
            // planMantenimiento: { planMantenimientoId: orderSelect?.planMantenimiento?.planMantenimientoId },
            fechaFinalizacion: userRol !== "3" && userRol !== "6" ? fechaFinalizacion.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            // tipoMantenimiento,
            // checkList,
            estado: status,
            tipoOrden,
            notaFinalizacion: nota,
            // resumenProblema,
            // responsable: responsable !== null ? { personaId: responsable } : null,
            // proveedor: proveedorId !== null ? { proveedorId: proveedorId } : null,
            // prioridad,
            tiempoEstimado,
            numeroPersonas,
            autorizador: user?.persona?.personaId ? { personaId: user.persona.personaId } : null,
        }
        // //console.log("🚀 ~ file: Order.js:202 ~ Order ~ data:", data)
        OrdenTrabajo.update(data, 0).then((res) => {
            if (res) {
                // //console.log("🚀 ~ file: Order.js:204 ~ Order ~ OrdenTrabajo.update ~ res:", res)
                this.setState({
                    showCompletado: true,
                    confirm: false,
                    visible: res.success,
                    message: res.message,
                    fechaFinalizacion: moment(),
                    nota: "",
                    orderid: -1,
                }, () => this.onSearch(1))
            }
        })
    }

    printOrder = (item) => {

        let ordenTrabajoId = item.ordenTrabajoId;
        // let activo_id = item?.activo?.activoId;
        // let plan_id = item?.planMantenimiento?.planMantenimientoId;
        // let fecha = moment(item.fechaEmision).format("DD/MM/YYYY");
        // let tipoOT = item?.tipoMantenimiento;

        window.open(`${server}/report/orden/${ordenTrabajoId}`, "_blank")
        // let arryData = [{
        //     ordenTrabajoId: ordenTrabajoId,
        //     tipoOT: tipoOT,
        //     activo_id: activo_id,
        //     plan_id: tipoOT === 1 ? plan_id : -1,
        //     fechaOT: fecha,
        // }];
        // this.setState({
        //     arryData: arryData,
        //     open: true,
        //     showButton: true,
        // })
    }

    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState({ productoSel: selectedOption });
        }
    };

    loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        let productos = []
        Inventario.listProductoBySearch({ search: inputValue }).then((res) => {
            if (res) {
                const response = res.data;
                for (let key in response) {
                    productos.push({
                        label: `${response[key].nombre} (${response[key].codigo})`,
                        value: response[key].productoId
                    })
                }
            }

        }).finally(() => {
            return callback(productos);
        })
    };

    agregarProducto = () => {
        const { productoSel, cantidad, orderId, productosList } = this.state;

        OrdenTrabajo.addProductItem({
            ordenTrabajo: {
                ordenTrabajoId: orderId
            },
            producto: {
                productoId: productoSel.value,
                nombre: productoSel.label
            },
            cantidad
        }).then((res) => {
            if (res) {
                productosList.push({
                    ordenTrabajoProductoId: res.ordenTrabajoProductoId,
                    producto: {
                        nombre: productoSel.label,
                    },
                    cantidad
                })
                this.setState(
                    {
                        productosList,
                        cantidad: 0,
                        cantidadtext: "",
                        productoSel: null
                    }
                )
            }
        })
    }

    removeProduct = (id, index) => {
        const { productosList } = this.state;
        OrdenTrabajo.removeProductItem({ id }).then((res) => {
            if (res) {
                productosList.splice(index, 1);
                this.setState(
                    {
                        productosList,
                    }
                )
            }
        })
    }

    actualizarActivo = () => {
        const { frecuenciaLectura, unidadActividadLectura, frecuenciaValueLectura, activoId, data, index } = this.state;
        Activos.update({
            activoId,
            valorLectura: frecuenciaValueLectura,
            unidad: unidadActividadLectura.simbolo,
            unidadMedicion: {
                unidadMedicionId: unidadActividadLectura.value
            },

        }).then((res) => {
            if (res) {
                data[index].frecuenciaValueLectura = frecuenciaValueLectura;
                data[index].unidadActividadLectura = unidadActividadLectura;
                data[index].frecuenciaLectura = frecuenciaLectura;
                this.setState({ data })
            }
        })
            .finally(() => {
                this.setState({
                    modalValor: false
                })
            })
    }

    onSearch = (page) => {
        const { search, filter } = this.state;
        // if(filter !== "pendientes")
        // {
        //     this.filterBy(filter,page)
        // }else{
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            OrdenTrabajo.search({ search, page }).then((res) => {
                if (res.success) {
                    const response = res.data
                    //console.log(response)
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        let equipo = response[key].usuarioAsignado ? [{
                            src: response[key].usuarioAsignado.fotoUri,
                            name: response[key].usuarioAsignado.persona.nombre
                        }] : []
                        for (let k in response[key].checkList) {
                            const member = response[key].checkList[k];
                            if (member.checkedByUser) {
                                equipo.push({
                                    src: member.checkedByUser.fotoUri,
                                    name: member.checkedByUser.persona.nombre
                                })
                            }
                        }

                        data.push({
                            id: response[key].ordenTrabajoId,
                            estado: response[key].estado,
                            numero: ('00000000' + response[key].ordenTrabajoNumero).slice(-8),
                            prioridadValor: response[key].prioridad,
                            prioridad: response[key].prioridad === 1 ? "Alta" : response[key].prioridad === 2 ? "Media" : "Baja",
                            imagenActivo: response[key].activo.fotoUri,
                            codigoActivo: response[key].activo.codigo,
                            nombreActivo: response[key].activo.nombre,
                            activoId: response[key].activo.activoId,
                            equipo,
                            tareas: response[key].checkList,
                            //progreso : this.getProgress(response[key].checkList),
                            productosList: _.filter(response[key].productos, (o) => { return o.activo; }),
                            frecuenciaValueLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : 0,
                            unidadActividadLectura: response[key].activo.unidadMedicion ? {
                                value: response[key].activo.unidadMedicion.unidadMedicionId,
                                label: `${response[key].activo.unidadMedicion.descripcion} (${response[key].activo.unidadMedicion.simbolo})`,
                                simbolo: response[key].activo.unidadMedicion.simbolo
                            } : null,
                            frecuenciaLectura: response[key].activo.valorLectura ? response[key].activo.valorLectura : "",
                        })

                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                //console.log("🚀 ~ file: Order.js:418 ~ Order ~ OrdenTrabajo.search ~ err:", err)
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })

            })
        } else {
            this.filterBy(filter, page)
        }
        //}

    }

    agregarTarea = () => {
        const { orderid, actividadesList, descripcionTarea, comentario } = this.state;

        OrdenTrabajo.addTaskItem({
            ordenTrabajo: {
                ordenTrabajoId: orderid
            },
            descripcion: descripcionTarea,
            comentario: comentario,

        }).then((res) => {
            if (res) {
                actividadesList.push({
                    ordenTrabajoId: res.ordenTrabajoCheckListId,
                    descripcion: descripcionTarea,
                    comentario: comentario,
                })
                this.setState(
                    {
                        actividadesList,
                        descripcionTarea: "",
                    }
                )
            }
        })
    }

    filterBy(filter, page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        OrdenTrabajo.listByFilter({ filtro: filter, page }).then(res => {
            if (res) {
                const response = res.data;
                // console.log("🚀 ~ file: Order.js:402 ~ Order ~ OrdenTrabajo.listByFilter ~ response", response)
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    let tareas = response[key].checkList;
                    let equipo = response[key].usuarioAsignado ? [{
                        src: response[key].usuarioAsignado.fotoUri,
                        name: response[key].usuarioAsignado.persona.nombre
                    }] : []
                    for (let k in response[key].checkList) {
                        const member = response[key].checkList[k];
                        if (member.checkedByUser) {
                            equipo.push({
                                src: member.checkedByUser.fotoUri,
                                name: member.checkedByUser.persona.nombre
                            })
                        }
                    }

                    data.push({
                        id: response[key].ordenTrabajoId,
                        estado: response[key].estado,
                        numero: response[key].ordenTrabajoNumero !== null ? ('00000000' + response[key].ordenTrabajoNumero).slice(-8) : "",
                        prioridadValor: response[key].prioridad,
                        prioridad: response[key].prioridad === 1 ? "Alta" : response[key].prioridad === 2 ? "Media" : "Baja",
                        imagenActivo: response[key]?.activo?.fotoUri,
                        codigoActivo: response[key]?.activo?.codigo,
                        nombreActivo: response[key]?.activo?.nombre,
                        activoId: response[key]?.activo?.activoId,
                        equipo,
                        tareas,
                        //progreso : this.getProgress(response[key].checkList),
                        productosList: _.filter(response[key].productos, (o) => { return o.activo; }),
                        frecuenciaValueLectura: response[key]?.activo?.valorLectura ? response[key]?.activo?.valorLectura : 0,
                        unidadActividadLectura: response[key]?.activo?.unidadMedicion ? {
                            value: response[key]?.activo?.unidadMedicion?.unidadMedicionId,
                            label: `${response[key]?.activo?.unidadMedicion?.descripcion} (${response[key]?.activo?.unidadMedicion?.simbolo})`,
                            simbolo: response[key]?.activo?.unidadMedicion?.simbolo
                        } : null,
                        frecuenciaLectura: response[key]?.activo?.valorLectura ? response[key]?.activo?.valorLectura : "",
                        data: response[key]
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            ////console.log("🚀 ~ file: Order.js:513 ~ Order ~ OrdenTrabajo.listByFilter ~ err:", err)
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            // console.log("🚀 ~ file: Order.js:527 ~ Order ~ OrdenTrabajo.listByFilter ~ data:", data)
            this.setState({
                data: data,
                pages,
                currentPage,
                totalPages,
                loading: false,
                filter

            })
        })
    }

    updateStatus = (orderId, statusId) => {

        if (statusId > 2) {
            //confirmar completar
            this.setState({ orderid: orderId, confirm: true })
        } else {

            OrdenTrabajo.update({
                ordenTrabajoId: orderId,
                estado: statusId
            }, 1).then((res) => {
                if (res) {
                    const { data } = this.state;
                    const index = data.findIndex(element => element.id === orderId);
                    data[index].estado = statusId;
                    this.setState({
                        data,
                    });
                }
            })

        }
    }

    verOrder = (item) => {
        const newItem = item.data;
        history.push({
            pathname: '/createorder',
            state: {
                item: item,
                data: {
                    assetId: newItem.activo.activoId,
                    asset: newItem.activo.nombre,
                    assetCode: newItem.activo.codigo,
                    assetType: newItem.activo.tiposActivo.tipoActivoId,
                    description: newItem?.resumenProblema || "",
                    assignTo: newItem?.usuarioAsignado,
                    solicitudId: {
                        solicitudTrabajoId: newItem?.ordenTrabajoId,
                        solicitante: newItem?.responsable?.nombre,
                    },
                    checkList: newItem.checkList,
                    maintenanceType: newItem.tipoMantenimiento,
                    tipo: {
                        value: 1,
                        label: "Orden de Trabajo"
                    },
                    ordenTrabajoId: item.ordenTrabajoId,
                }
            }
        })
    }

    async completarActividad(item, index) {
        this.setState({ checkBoxLoading: true })
        const id = localStorage.getItem('id_token')
        console.log("🚀 ~ file: Order.js:597 ~ Order ~ completarActividad ~ id:", id)
        const user = await Api.get({ id: parseInt(id) })
        console.log("🚀 ~ file: Order.js:598 ~ Order ~ completarActividad ~ user:", user)
        let data = {
            ordenTrabajoCheckListId: item?.ordenTrabajoCheckListId,
            checked: !item.checked,
            ordenTrabajo: {
                ordenTrabajoId: this?.state?.orderSelect?.ordenTrabajoId
            },
            checkedByUser: {
                usuarioId: user?.usuarioId
            }
        }

        try {
            const response = await OrdenTrabajo.updateTask(data);
            if (response) {
                let { actividadesList } = this.state;
                actividadesList[index].checked = !item.checked;
                this.setState({ actividadesList, alerttActividad: false, checkBoxLoading: false })
            }
        } catch (err) {
            ////console.log(err)
        }

    }

    render() {
        const { data } = this.state;
        return (
            <Container menuId="tareas" submenu="trabajo" taskToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">

                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Ordenes de Trabajo</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Ordenes de Trabajo</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12}>
                                {(this.state.userRol < 3 || this.state.userRol === 6) &&
                                    <Button color={"success"} className="btn-icon float-right" type="button"
                                        onClick={() => history.push({
                                            pathname: '/createorder',
                                            state: 'trabajo'
                                        })}>
                                        <i className="zmdi zmdi-plus" />
                                    </Button>
                                }
                                <ButtonDropdown isOpen={this.state.isOpen} toggle={() => { this.setState({ isOpen: !this.state.isOpen }); }}>
                                    <DropdownToggle className="button-boder"  >
                                        <i className="zmdi zmdi-filter-list" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Filtrar Por</DropdownItem>
                                        <DropdownItem disabled={this.state.filter === "pendientes"} onClick={() => this.filterBy("pendientes", 1)}>Pendientes</DropdownItem>
                                        <DropdownItem disabled={this.state.filter === "completadas"} onClick={() => this.filterBy("completadas", 1)}>Completadas</DropdownItem>
                                        <DropdownItem disabled={this.state.filter === "no completadas"} onClick={() => this.filterBy("no completadas", 1)}>No Completadas</DropdownItem>
                                        <DropdownItem disabled={this.state.filter === "sin asignar"} onClick={() => this.filterBy("sin asignar", 1)}>Sin Asignar</DropdownItem>
                                        <DropdownItem disabled={this.state.filter === "en progreso"} onClick={() => this.filterBy("en progreso", 1)}>En Progreso</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                                <InputGroup className="float-right">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                        className="form-control"
                                        placeholder="Busqueda..."
                                        value={this.state.search}
                                        onChange={(event) => this.setState({ search: event.target.value })} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <Card>
                        <Table hover className="c_table theme-color">
                            <thead>
                                <tr>
                                    <TableHeaderlist title="" style={{ width: 80 }} className="hidden-md-down" />
                                    <TableHeaderlist title="Activo" />
                                    <TableHeaderlist title="#Orden" style={{ width: 90 }} />
                                    <TableHeaderlist title="Usuarios" style={{ width: 100 }} className="hidden-md-down" />
                                    <TableHeaderlist title="Estado" style={{ width: 150 }} className="hidden-md-down" />
                                    <TableHeaderlist title="Prioridad" style={{ width: 85 }} className="hidden-md-down" />
                                    <TableHeaderlist title="Acción" style={{ width: 250 }} />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((value, i) => {
                                        return (
                                            <tr key={value.id}>
                                                <td className="hidden-md-down">
                                                    <img className="rounded avatar" src={value.imagenActivo === "" ?
                                                        "assets/images/xs/avatar1.jpg" : value.imagenActivo} alt=""
                                                        style={{ width: 50, height: 50, objectFit: "cover" }}
                                                    />
                                                </td>
                                                <td>
                                                    {value.nombreActivo}<br />
                                                    <small>{value.codigoActivo}</small>
                                                </td>
                                                <TableItemlist item={value.numero} />
                                                <TableUserList data={value.equipo.length > 0 ? value.equipo : [{ src: null, name: "" }]} className="hidden-md-down" />
                                                <td className="hidden-md-down">
                                                    <OrderStatus currentStatus={value.estado} onChange={(statusId) => { this.updateStatus(value.id, statusId); this.setState({ orderSelect: value?.data, orderid: value.id }) }} />
                                                </td>
                                                <TableItemPriority item={value.prioridad} priority={value.prioridadValor} className="hidden-md-down" />
                                                <td>
                                                    {this.state.userRol < 3 && value.estado < 3 && <Button title="Completar Orden" color="default" onClick={() => { this.setState({ orderSelect: value?.data, orderid: value.id, confirm: true }) }} size="sm"><i className="zmdi zmdi-check" /></Button>}
                                                    {this.state.userRol < 3 && value.estado < 3 && <Button title="Ver Actividades" color="default" onClick={() => {
                                                        // //console.log("🚀 ~ file: Order.js:251 ~ Order ~ return ~ value", value)
                                                        this.setState({ orderSelect: value?.data, orderid: value.id, tareasPopup: true, numOrder: value.numero, actividadesList: value.tareas })
                                                    }
                                                    } size="sm"><i className="zmdi zmdi-format-list-bulleted" /></Button>}
                                                    <Button title="Agregar Repuestos" color="default" onClick={() => {
                                                        // //console.log("🚀 ~ file: Order.js:251 ~ Order ~ return ~ value", value)
                                                        // //console.log(this.state.cantidadtext === "" && this.state.productoSel === null)
                                                        // //console.log("🚀 ~ file: Order.js:670 ~ Order ~ data.map ~  this.state.productoSel === null:", this.state.productoSel === null)
                                                        // //console.log(`🚀 ~ file: Order.js:670 ~ Order ~ data.map ~ this.state.cantidadtext === "":`, this.state.cantidadtext === "")
                                                        this.setState({ modal: true, numOrder: value.numero, orderId: value.id, productosList: value.productosList })
                                                    }}
                                                        size="sm"><i className="zmdi zmdi-collection-plus" /></Button>
                                                    <Button title={"Imprimir Orden"} color="default" onClick={() => this.printOrder(value?.data)} size="sm"><i className="zmdi zmdi-print" /></Button>
                                                    <Button title="Actualizar Valores de Medición" color="default" onClick={() => this.setState({ modalValor: true, activoId: value.activoId, activoSel: value.nombreActivo, unidadActividadLectura: value.unidadActividadLectura, frecuenciaValueLectura: value.frecuenciaValueLectura, frecuenciaLectura: value.frecuenciaLectura, index: i })} size="sm"><i className="zmdi zmdi-filter-frames" /></Button>
                                                    {/* <Button title="Ver Orden" color="default" onClick={() => this.verOrder(value)} size="sm"><i className="zmdi zmdi-eye" /></Button> */}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <div className="justify-content-center">
                            <Pagination className="pagination pagination-success mt-4">
                                {
                                    this.state.pages.map((value, index) => {
                                        return (
                                            <PaginationItem key={index} active={value === this.state.currentPage}>
                                                <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                            </PaginationItem>
                                        );
                                    })
                                }
                            </Pagination>
                        </div>

                    </Card>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.setState({ visible: false })}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.setState({ warning: false })}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.setState({ error: false })}>
                        Error Inesperado
                    </Alert>
                </section>
                <SweetAlert info showCancel
                    confirmBtnText="Completada"
                    cancelBtnText="No Completada"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    onConfirm={() => this.finishOrder(3, this.state.orderSelect)}
                    title={"¿Vas a completar la orden?"}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                    allowEscape={true}
                    showCloseButton={true}
                    dependencies={[this.state.fechaFinalizacion]}
                    customButtons={
                        <React.Fragment>
                            <Button style={{ marginRight: 5, marginLeft: 5 }} color="default" className="btn-round" onClick={() => this.finishOrder(4, this.state.orderSelect)}>No Completada</Button>
                            <Button color="success" className="btn-round" onClick={() => this.finishOrder(3, this.state.orderSelect)}>Completada</Button>
                        </React.Fragment>
                    }
                >
                    {this.state.userRol !== "3" && this.state.userRol !== "6" && <Row>
                        <div style={{ display: "none", zIndex: 6000 }}>
                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"} >
                                <DatePicker
                                    disableFuture
                                    value={this.state.fechaFinalizacion}
                                    open={this.state.calendarOpen}
                                    onOpen={() => this.setState({ calendarOpen: true })}
                                    onClose={() => this.setState({ calendarOpen: false })}
                                    onChange={(date) => this.setState({ fechaFinalizacion: date })}
                                    label="Fecha de Finalización"
                                    showTodayButton
                                    cancelLabel={"Cancelar"}
                                    okLabel={"Aceptar"}
                                    todayLabel={"HOY"}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <Col md={12} lg={12}>
                            <FormGroup className="form-group mb-3">
                                <label>Seleccione fecha de Finalización:</label>
                                <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="zmdi zmdi-calendar" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        value={this.state.fechaFinalizacion !== "" ? moment(this.state.fechaFinalizacion).format("DD/MM/YYYY") : ""}
                                        className="form-control datetimepicker" placeholder="Fecha de Finalización"
                                        onClick={() => this.setState({ calendarOpen: true })}
                                        onChange={() => { }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col md={12} lg={12}>
                            <FormGroup className="form-group">
                                <Input type="textarea"
                                    value={this.state.nota}
                                    onChange={(event) => this.setState({ nota: event.target.value })}
                                    className="form-control"
                                    placeholder="Nota"
                                    rows={3}
                                    required
                                // maxlength={500}
                                />
                            </FormGroup>
                        </Col>
                        Indique el estado de la orden, una vez completada se dara como finalizada.
                    </Row>
                </SweetAlert>
                <Modal
                    isOpen={this.state.tareasPopup}
                    toggle={() => this.setState({ tareasPopup: false })}
                    size="xl"
                >
                    <ModalHeader toggle={() => this.setState({ tareasPopup: false, orderSelect: null })}>Actividades en la orden #{this.state.numOrder}</ModalHeader>
                    <ModalBody>
                        <Row className="clearfix">
                            <InputGroup className="mb-4 p-3">
                                <Input type="text" style={{ flexWrap: 'wrap' }} value={this.state.descripcionTarea} onChange={(event) => this.setState({ descripcionTarea: event.target.value })} className="form-control" placeholder="Escriba tarea..." />
                                <Input type="text" style={{ flexWrap: 'wrap' }} value={this.state.comentario} onChange={(event) => this.setState({ comentario: event.target.value })} className="form-control" placeholder="Intrucciones" />

                                <InputGroupAddon addonType="append">
                                    <Button
                                        onClick={() => this.agregarTarea()}
                                        className="btn btn-outline-secondary"
                                        type="submit"
                                        id="button-addon2"
                                        disabled={this.state.descripcionTarea === ""}
                                    >
                                        Agregar
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Row>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Table responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Descripción" />
                                            <TableHeaderlist title="Instrucciones" />
                                            <TableHeaderlist title="Completada" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.actividadesList.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <TableItemlist item={(index + 1)} />
                                                        <TableItemlist item={item.descripcion} />
                                                        <TableItemlist item={item.comentario} />
                                                        {item.checked && <TableItemlist item={item.checked && <i className="zmdi zmdi-check" />} />}
                                                        {!item.checked && <td>
                                                            {this.state.checkBoxLoading && this.state.indexActividad === index ? <Spinner color="primary" /> :
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item.checked}
                                                                    // defaultChecked={item.checked}
                                                                    onChange={() => {
                                                                        // this.completarActividad(item, index)
                                                                        this.setState({ alerttActividad: true, actividadSelect: item, indexActividad: index })
                                                                    }}
                                                                    // onClick={() => this.setState({ alerttActividad: true, actividadSelect: item, indexActividad: index })}
                                                                    disabled={item.checked}

                                                                />
                                                            }
                                                        </td>}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })} className={""}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Seleccione los repuestos/materiales utilizados en la orden #{this.state.numOrder}</ModalHeader>
                    <ModalBody>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <FormGroup>
                                    <AsyncSelect noOptionsMessage={() => 'Sin Resultados'}
                                        placeholder={"Escriba para buscar"}
                                        cacheOptions
                                        value={this.state.productoSel}
                                        onChange={(e) => { this.onSearchChange(e); }}
                                        loadOptions={this.loadOptions}
                                        defaultOptions={false}
                                    // onInputChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <FormGroup>
                                    <NumberFormat value={this.state.cantidadtext} customInput={Input} thousandSeparator={true} placeholder="Cantidad Utilizada"
                                        onValueChange={(values) => {
                                            const { formattedValue, value } = values;
                                            //console.log("🚀 ~ file: Order.js:867 ~ Order ~ render ~ values:", values)
                                            this.setState({ cantidadtext: formattedValue, cantidad: value })
                                        }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Table responsive>
                                    <thead className="thead-light" >
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Descripción" />
                                            <TableHeaderlist title="Cantidad" />
                                            <TableHeaderlist title="" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.productosList.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <TableItemlist item={(index + 1)} />
                                                        <TableItemlist item={item.producto.nombre} />
                                                        <TableItemlist item={item.cantidad} />
                                                        <TableItemlist item={<Button onClick={() => this.removeProduct(item.ordenTrabajoProductoId, index)} color="link" size="sm" style={{ padding: "3px 10px", width: "30px" }} className="waves-effect waves-float btn-sm waves-red"><i className="zmdi zmdi-delete" /></Button>} />
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            className="waves-effect btn-round"
                            onClick={() => this.agregarProducto()}
                            disabled={this.state.cantidadtext === "" || this.state.productoSel === null || this.state.cantidad <= 0}
                        >
                            Agregar y Guardar</Button>{' '}
                        <Button color="danger" className="waves-effect" onClick={() => this.setState({
                            modal: false,
                            cantidadtext: "",
                            cantidad: 0,
                            productoSel: null
                        })}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalValor} toggle={() => this.setState({ modalValor: false })} className={""}>
                    <ModalHeader toggle={() => this.setState({ modalValor: false })}>Actualice valores de medición del activo</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>{this.state.activoSel}</Label>
                            <NumberFormat value={this.state.frecuenciaLectura} customInput={Input} thousandSeparator={true} prefix={''} placeholder="Nuevo Valor de Lectura"
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ frecuenciaLectura: formattedValue, frecuenciaValueLectura: value })
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                placeholder={"Unidad"}
                                value={this.state.unidadActividadLectura}
                                onChange={(unidadActividadLectura) => this.setState({ unidadActividadLectura })}
                                options={this.state.unidadesMedicion}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            className="waves-effect btn-round"
                            onClick={() => this.actualizarActivo()}
                            disabled={this.state.frecuenciaLectura === "" || this.state.unidadActividadLectura === null || this.state.frecuenciaValueLectura <= 0}
                        >
                            Guardar</Button>{' '}
                        <Button color="danger" className="waves-effect" onClick={() => this.setState({ modalValor: false })}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
                <NewModal
                    title=''
                    centered
                    open={this.state.open}
                    onCancel={() => this.setState({ open: false })}
                    width={1000}
                    okText={this.state.loading ? "Cargando..." : "Imprimir todo"}
                    cancelText="Cerrar"
                    closable={true}
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}
                    footer={[

                    ]}
                >
                    <div ref={this.componentRef} text={this.state.text} >
                        {this.state.arryData.map((value, i) => {
                            return <ReporteOrdenTrabajo key={i} data={value} show={false} />
                        })}
                    </div>
                </NewModal>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Sí, estoy seguro"
                    cancelBtnText="No"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={() => this.completarActividad(this.state.actividadSelect, this.state.indexActividad)}
                    onCancel={() => this.setState({ alerttActividad: false })}
                    show={this.state.alerttActividad}
                >
                    {`¿Esta seguro que desea completar esta actividad?`}
                </SweetAlert>
                <SweetAlert
                    //guardado con exito
                    success
                    show={this.state.showCompletado}
                    title={"Orden Completada"}
                    onConfirm={() => this.setState({ showCompletado: false })}
                    onCancel={() => this.setState({ showCompletado: false })}
                >
                </SweetAlert>
            </Container>
        )
    }
}