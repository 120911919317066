import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import {
    Input,
    Button,
    Alert,
    Col,
    FormGroup,
    Row,
    Card,
    InputGroup,
    InputGroupAddon,
    ListGroup,
    ListGroupItem,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Spinner
} from 'reactstrap';
import { AsyncPaginate } from "react-select-async-paginate";
import Plan from "../modules/plan"
import Select from 'react-select';
import history from '../history';
import moment from "moment";
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Api from "../modules/ordenTrabajo"
import User from "../modules/user"
import Proveedor from "../modules/proveedor"
import SweetAlert from 'react-bootstrap-sweetalert';
import { AvForm, AvField } from 'availity-reactstrap-validation';
const defaultAdditional = {
    page: 1
};

export default class CreateOrder extends React.Component {
    constructor(props) {

        super(props)
        moment.locale("es");
        this.state = {
            isLoading: false,
            showOtherAlert: false,
            selectid: "0",
            tiposMantenimientos: [
                { value: 1, label: "Preventivo" },
                { value: 2, label: "Correctivo" },
                { value: 3, label: "Predictivo" },
                { value: 4, label: "Emergencia" },
            ],
            estado: [
                { value: "1", label: "Abierta" },
                { value: "2", label: "En Progreso" },
                { value: "3", label: "Completada" },
                { value: "4", label: "No Completada" }
            ],
            tipoActivo: [],
            prioridad: [
                { value: "1", label: "Alta" },
                { value: "2", label: "Media" },
                { value: "3", label: "Baja" },
            ],
            fechaInicio: moment(),
            isOpen: false,
            resumen: "",
            intrucciones: "",
            asignadoa: [],
            horasEstimadas: "",
            horasTrabajadas: "",
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: false,
            editing: false,
            usuarioId: undefined,
            checkList: [],
            tareaError: "none",
            tipoOrdenError: "none",
            estadoError: "none",
            activoError: "none",
            prioridadError: "none",
            tipoMantenimientoError: "none",
            tipo: null,
            estadoSel: null,
            activo: null,
            prioridadSel: null,
            asignadoaSel: null,
            solicitudId: null,
            tipoMantenimiento: null,
            userRol: "",
            tareaEmpty: "",
            tarea: "",
            ordenTrabajoId: null,
            itemSelect: null,
            comentario: "",
            planificada: false,
        }
        this.firstSelectRef = React.createRef();
        this.secondSelectRef = React.createRef();
        this.threeSelectRef = React.createRef();
        this.fourSelectRef = React.createRef();
        this.fiveSelectRef = React.createRef();
        this.sixSelectRef = React.createRef();
        this.sevenSelectRef = React.createRef();
        this.eightSelectRef = React.createRef();
        this.nineSelectRef = React.createRef();

    }

    async componentDidMount() {
        this.firstSelectRef.current.focus();

        let userRol = (localStorage.getItem('user_rol'));
        if (userRol === "4" || userRol === "5") {
            history.replace('/404');
        }
        this.setState({ userRol: parseInt(userRol, 10) })
        const state = this.props.location.state
        //console.log("🚀 ~ file: CreateOrder.js:109 ~ CreateOrder÷ß ~ componentDidMount ~ state:", state)
        if (state && state === "servicio") {
            this.setState({
                tipo: {
                    value: 2,
                    label: "Orden de Servicio",
                },
            })
            this.getTecnicos({ asignadoaSel: null })
        } else {
            this.setState({
                tipo: {
                    value: 1,
                    label: "Orden de Trabajo",
                },
            })
            this.getTecnicos({ asignadoaSel: null })
        }

        if (state && state.data) {
            this.fillOrder(state.data)
            this.setState({ itemSelect: state?.item?.data?.planMantenimiento })
        }
        if (state?.selectedCheckbox) {
            const { selectedCheckbox } = state;
            console.log("🚀 ~ file: CreateOrder.js:118 ~ CreateOrder ~ componentDidMount ~ selectedCheckbox:", selectedCheckbox)

            this.setState({
                itemSelect: {
                    planMantenimientoId: selectedCheckbox[0]?.plan_id,
                },
                activo: {
                    value: selectedCheckbox[0]?.activo_id,
                    label: `${selectedCheckbox[0]?.nombre} (${selectedCheckbox[0]?.codigo})`,
                    tipo: selectedCheckbox[0]?.tipo_activo_id
                },
                tipo: {
                    value: 1,
                    label: "Orden de Trabajo"
                },
                planificada: selectedCheckbox[0]?.solicitudTrabajoId ? false : true,
                tipoMantenimiento: {
                    value: selectedCheckbox[0]?.solicitudTrabajoId ? 2 : 1,
                    label: selectedCheckbox[0]?.solicitudTrabajoId ? "Correctivo" : "Preventivo"
                },
                estadoSel: { value: "1", label: "Abierta" },
                prioridadSel: { value: "1", label: "Alta" },
                solicitudId: {
                    solicitudTrabajoId: selectedCheckbox[0]?.solicitudTrabajoId,
                    // solicitante: selectedCheckbox[0]?.created
                },
            })
            this.getTecnicos({ asignadoaSel: null })
            if (selectedCheckbox[0]?.plan_id) {
                this.getPlanMantenimiento(selectedCheckbox[0]?.plan_id)
            }
        }
    }

    async getPlanMantenimiento(plan_id) {
        const planMantenimiento = await Plan.getPlanById({ id: plan_id });
        if (planMantenimiento) {
            let actividades = !planMantenimiento?.partes ? [] : planMantenimiento?.partes[0]?.actividades;
            console.log("🚀 ~ file: CreateOrder.js:173 ~ CreateOrder ~ getPlanMantenimiento ~ actividades:", actividades)
            let newActividades = [];
            for (const key in actividades) {
                newActividades.push({
                    descripcion: actividades[key].descripcion,
                    checked: false,
                    comentario: actividades[key].procedimientos
                })
            }

            this.setState({
                checkList: newActividades
            })
        }
    };

    fillOrder(data, item) {
        this.setState({
            ordenTrabajoId: data.ordenTrabajoId,
            editing: true,
            activo: {
                value: data.assetId,
                label: `${data.asset} (${data.assetCode})`,
                tipo: data.assetType
            },
            tipo: data.tipo,
            tipoMantenimiento: {
                value: data.maintenanceType,
                label: data.maintenanceType === 1 ? "Preventivo" : data.maintenanceType === 2 ? "Correctivo" : data.maintenanceType === 3 ? "Predictivo" : "Emergencia"
            },
            estadoSel: { value: "1", label: "Abierta" },
            prioridadSel: { value: "1", label: "Alta" },
            resumen: data.description,
            asignadoaSel: data.assignTo ? {
                value: data.assignTo.usuarioId,
                label: data.assignTo.persona.nombre + " " + data.assignTo.persona.apellido
            } : null,
            solicitudId: {
                solicitudTrabajoId: data.id,
                solicitante: data.created
            },
            checkList: data.checkList,
        }, () => {
            this.getTecnicos({
                asignadoaSel: data.assignTo ? {
                    value: data.assignTo.usuarioId,
                    label: data.assignTo.persona.nombre + " " + data.assignTo.persona.apellido
                } : null
            });
        })
    }

    getTecnicos = ({ asignadoaSel }) => {
        let asignadoa = []
        User.list({ filtro: 1 }).then((response) => {
            if (response) {
                const res = response.data
                for (const key in res) {
                    asignadoa.push({
                        value: res[key].usuarioId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                asignadoa,
                asignadoaSel: asignadoaSel
            })
        })
    }

    getProveedores = () => {
        let asignadoa = []
        const { activo } = this.state;
        Proveedor.listBy({ id: activo ? activo.tipo : 0 }).then((res) => {
            if (res) {
                for (const key in res) {
                    asignadoa.push({
                        value: res[key].proveedorId,
                        label: res[key].persona.nombre + " " + res[key].persona.apellido
                    })
                }
            }
        }).finally(() => {
            this.setState({
                asignadoa,
                asignadoaSel: null
            })
        })
    }

    async getRoles(keepLoading) {

    }

    selectEstado = selectedOption => {
        this.setState(
            { estadoSel: selectedOption },
        );
        this.fiveSelectRef.current.focus();
    };

    selectTipo = selectedOption => {
        this.setState(
            { tipo: selectedOption },
            () => {
                if (selectedOption.value === 1) this.getTecnicos({ asignadoaSel: null });
                else this.getProveedores()

            }
        );
        this.secondSelectRef.current.focus();
    };

    selectPrioridad = selectedOption => {
        this.setState(
            { prioridadSel: selectedOption }
        );
        this.sixSelectRef.current.focus();
    };

    selectTipoMantenimiento = selectedOption => {
        this.setState(
            { tipoMantenimiento: selectedOption }
        );
        this.fourSelectRef.current.focus();
    };

    selectAsignadoa = selectedOption => {
        this.setState(
            {
                asignadoaSel: selectedOption,
                isOpen: true
            }
        );
        this.eightSelectRef.current.focus();

    };

    selectCompletadopor = selectedOption => {
        this.setState(
            { completadoporSel: selectedOption }
        );
    };

    save() {
        this.setState({ disabled: true, isLoading: true })
        const { tipoMantenimiento, estadoSel, activo, fechaInicio, resumen, prioridadSel, checkList, tipo, asignadoaSel, solicitudId, itemSelect } = this.state;
        const fechaEmision = moment().format("YYYY-MM-DD");
        const estado = estadoSel.value;
        const fechaFinalizacion = estado >= 3 ? moment().format("YYYY-MM-DD") : null;


        const data = {
            activo: { activoId: activo.value },
            planMantenimiento: { planMantenimientoId: itemSelect?.planMantenimientoId || null },
            tipoMantenimiento: tipoMantenimiento.value,
            fechaEmision,
            fechaInicio,
            fechaFinalizacion,
            resumenProblema: resumen,
            prioridad: prioridadSel.value,
            checkList,
            estado,
            notaFinalizacion: "",
            responsable: null,
            tiempoEstimado: 0,
            numeroPersonas: 0,
            autorizador: null,
            usuarioAsignado: tipo.value === 1 && asignadoaSel ? {
                usuarioId: asignadoaSel.value
            } : null,
            proveedor: tipo.value === 2 && asignadoaSel ? {
                proveedorId: asignadoaSel.value
            } : null,
            tipoOrden: tipo.value,
            solicitudTrabajo: solicitudId
        }
        //console.log("🚀 ~ file: CreateOrder.js:312 ~ CreateOrder ~ save ~ data:", data)
        Api.save(data, 1).then((response) => {
            if (response && response.ordenTrabajoId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                    solicitudTrabajo: null
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    update() {
        this.setState({ disabled: true, isLoading: true })
        const { tipoMantenimiento, estadoSel, ordenTrabajoId, activo, fechaInicio, resumen, prioridadSel, checkList, tipo, asignadoaSel, solicitudId, itemSelect } = this.state;

        // const { usuarioId, nombre, apellido, rol, telefono, correo, cargo, cedula, direccion } = this.state;
        const data = {
            ordenTrabajoId,
            activo: { activoId: activo.value },
            tipoMantenimiento: tipoMantenimiento.value,
            resumenProblema: resumen,
            prioridad: prioridadSel.value,
            checkList,
            notaFinalizacion: "",
            usuarioAsignado: tipo.value === 1 && asignadoaSel ? {
                usuarioId: asignadoaSel.value
            } : null,
            proveedor: tipo.value === 2 && asignadoaSel ? {
                proveedorId: asignadoaSel.value
            } : null,
            tipoOrden: tipo.value,
            solicitudTrabajo: solicitudId,
        }
        //console.log("🚀 ~ file: CreateOrder.js:336 ~ CreateOrder ~ update ~ data:", data)
        // const data = {
        //     usuarioId,
        //     correo,
        //     nombreUsuario: correo,
        //     persona: {
        //         nombre,
        //         apellido,
        //         identificacion: cedula,
        //         fechaNacimiento: "",
        //         sexo: "",
        //         telefono,
        //         direccion,
        //         pais: "Venezuela",
        //         estado: "Zulia",
        //         ciudad: "Maracaibo",
        //         cargo: cargo
        //     },
        // rol: {
        //     rolId: rol.value,
        //     descripcion: rol.label
        // }
        // }
        Api.update(data, 1).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    disabled: false
                })
            } else {
                this.setState({ error: true, disabled: false })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    loadOptions = async (search, prevOptions, { page }) => {
        if (search === "") search = "a";
        let activos = []
        const res = await Plan.listActivoBySearch({ search, page, filtro: 1 });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                activos.push({
                    label: `${response[key].nombre} (${response[key].codigo})`,
                    value: response[key].activoId,
                    tipo: response[key].tiposActivo.tipoActivoId
                })
            }
        }

        const results = {
            options: activos,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    onSearchChange = (selectedOption) => {
        console.log("🚀 ~ file: CreateOrder.js:469 ~ CreateOrder ~ selectedOption:", selectedOption)
        this.threeSelectRef.current.focus();
        if (selectedOption) {

            this.setState({
                activo: selectedOption
            }, () => {
                if (this.state.tipo.value === 2) this.getProveedores()
            });
        }
    };

    agregarTarea = () => {
        let { tarea, checkList, comentario } = this.state;
        if (tarea === "") {
            this.setState({
                tareaEmpty: 'block'
            })
        } else {
            checkList.push({
                descripcion: tarea,
                comentario: comentario,
                checked: false
            })
            this.setState({
                tarea: "",
                comentario: "",
                checkList
            })
        }
    }

    quitarTarea = () => {
        let { checkList, indexSel } = this.state;
        if (indexSel >= 0) {
            checkList.splice(indexSel, 1);
            this.setState({
                checkList,
                showOtherAlert: false
            })
        }

    };

    quitartodastareas = () => {
        this.setState({
            checkList: [],
            showOtherAlert: false
        })
    };

    validate() {
        //validate selects
        const { activo, tipo, estadoSel, prioridadSel, checkList, tipoMantenimiento } = this.state;
        this.setState({
            activoError: activo === null ? "block" : "none",
            tipoOrdenError: tipo === null ? "block" : "none",
            estadoError: estadoSel === null ? "block" : "none",
            prioridadError: prioridadSel === null ? "block" : "none",
            tipoMantenimientoError: tipoMantenimiento === null ? "block" : "none",
            tareaError: checkList.length === 0 ? "block" : "none"
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        //validate select
        const { activoError, tipoOrdenError, estadoError, prioridadError, tareaError, tipoMantenimientoError } = this.state;
        if (tipoMantenimientoError === "none" && activoError === "none" && tipoOrdenError === "none" && estadoError === "none" && prioridadError === "none" && tareaError === "none") {
            this.state.editing ? this.update() : this.save()
        }
    };

    resetState() {
        this.setState({
            estadoSel: null,
            activo: null,
            prioridadSel: null,
            asignadoaSel: null,
            solicitudId: null,
            tipoMantenimiento: null,
            tarea: "",
            checkList: [],
            tareaEmpty: "",
            tareaError: "none",
            tipoOrdenError: "none",
            estadoError: "none",
            activoError: "none",
            prioridadError: "none",
            tipoMantenimientoError: "none",
            editing: false,
            disabled: false,
            isLoading: false,
            visible: false,
            error: false,
            warning: false,
            message: "",
            solicitudTrabajo: null
        })
    };



    render() {
        const { tiposMantenimientos, estado, prioridad, asignadoa, loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="createorder">
                <section className="content">
                    <div className="block-header">
                        <Row>
                            <Col lg={7} md={9} sm={12} >
                                <h2>Crear Orden {this.state.tipo ? this.state.tipo.value === 1 ? `de Trabajo` : `de Servicio` : ""} - {this.state.planificada ? "Planificada" : "No Planificada"}</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Tareas</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link active" href="/order" tag="a">Ordenes de Trabajo</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Crear Orden</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                        </Row>
                    </div>
                    <AvForm onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}>
                        <Card >
                            <div className="body">
                                <div className="header">
                                    <h2><strong>Datos</strong> de la Orden {this.state.tipo ? this.state.tipo.value === 1 ? `de Trabajo` : `de Servicio` : ""}</h2>
                                </div>
                                <Row className="clearfix">
                                    <Col md={12} lg={6}>
                                        <div className="form-group">
                                            <label>Seleccione tipo de orden:</label>
                                            <Select
                                                noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.tipoOrdenError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={this.firstSelectRef}
                                                placeholder={"Tipo de Orden"}
                                                value={this.state.tipo}
                                                onChange={this.selectTipo}
                                                options={[
                                                    { value: 1, label: "Orden de Trabajo" },
                                                    { value: 2, label: "Orden de Servicio" },
                                                ]}
                                                onBlur={() => { this.state.tipoOrdenError === null ? this.setState({ tipoOrdenError: "block" }) : this.setState({ tipoOrdenError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.tipoOrdenError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el tipo de orden</div>
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <FormGroup>
                                            <label>Seleccione activo:</label>
                                            <AsyncPaginate
                                                noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.activoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                selectRef={this.secondSelectRef}
                                                additional={defaultAdditional}
                                                loadingMessage={() => "Buscando activo.."}
                                                placeholder={"Escriba para buscar activo"}
                                                cacheOptions
                                                type="submit"
                                                isDisabled={this.state.tipo === null}
                                                value={this.state.activo}
                                                onChange={(e) => { this.onSearchChange(e); }}
                                                loadOptions={this.loadOptions}
                                                defaultOptions
                                                onInputChange={this.handleInputChange}
                                                onBlur={() => { this.state.activoError === null ? this.setState({ activoError: "block" }) : this.setState({ activoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.activoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione un activo</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col md={12} lg={6}>
                                        <FormGroup >
                                            <label>Seleccione tipo de mantenimiento:</label>
                                            <Select
                                                noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.tipoMantenimientoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={this.threeSelectRef}
                                                placeholder={"Tipo de Mantenimiento"}
                                                value={this.state.tipoMantenimiento}
                                                onChange={this.selectTipoMantenimiento}
                                                options={tiposMantenimientos}
                                                onBlur={() => { this.state.tipoMantenimientoError === null ? this.setState({ tipoMantenimientoError: "block" }) : this.setState({ tipoMantenimientoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.tipoMantenimientoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el tipo de mantenimiento de la orden</div>
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} lg={6}>
                                        <FormGroup >
                                            <label>Seleccione estado de orden de trabajo:</label>
                                            <Select
                                                noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.estadoError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={this.fourSelectRef}
                                                placeholder={"Estado de orden de trabajo"}
                                                value={this.state.estadoSel}
                                                onChange={this.selectEstado}
                                                options={estado}
                                                onBlur={() => { this.state.estadoError === null ? this.setState({ estadoError: "block" }) : this.setState({ estadoError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.estadoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el estado actual de la orden</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col md={12} lg={4}>
                                        <FormGroup className="form-group">
                                            <label>Seleccione prioridad:</label>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                styles={{
                                                    container: base => ({
                                                        ...base,
                                                        border: this.state.prioridadError === "block" ? "1px solid #ced4da" : "none",
                                                        borderRadius: ".25rem",
                                                        borderColor: "#dc3545"
                                                    }),
                                                }}
                                                ref={this.fiveSelectRef}
                                                placeholder={"Prioridad"}
                                                value={this.state.prioridadSel}
                                                onChange={this.selectPrioridad}
                                                options={prioridad}
                                                onBlur={() => { this.state.prioridadError === null ? this.setState({ prioridadError: "block" }) : this.setState({ prioridadError: "none" }) }}
                                            />
                                            <div style={{ display: this.state.prioridadError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la prioridad de la orden</div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} lg={4}>
                                        <div className="form-group">
                                            <label>Asignar a:</label>
                                            <Select
                                                ref={this.sixSelectRef}
                                                noOptionsMessage={() => 'Sin Resultados'}
                                                placeholder={"Asignar a"}
                                                value={this.state.asignadoaSel}
                                                onChange={this.selectAsignadoa}
                                                options={asignadoa}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        console.log("🚀 ~ file: CreateOrder.js:756 ~ CreateOrder ~ render ~ e.key:", e.key)
                                                        this.setState({ isOpen: true })
                                                        this.eightSelectRef.current.focus();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} lg={4}>
                                        <div className="input-group masked-input mb-3">
                                            <label>Seleccione fecha:</label>
                                            <div
                                                className="input-group"
                                                onClick={() => this.setState({ isOpen: true })}
                                            >
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="zmdi zmdi-calendar" /></span>
                                                </div>
                                                <Input
                                                    type="text"
                                                    value={this.state.fechaInicio !== "" ? moment(this.state.fechaInicio).format("DD/MM/YYYY HH:mm") : ""}
                                                    className="form-control datetimepicker"
                                                    placeholder="Fecha de Inicio"
                                                    readOnly
                                                    onChange={() => { this.eightSelectRef.current.focus() }}
                                                    editable="false"
                                                    ref={this.sevenSelectRef}
                                                />
                                            </div>
                                            <div style={{ display: "none" }}>
                                                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                    <DateTimePicker
                                                        value={this.state.fechaInicio}
                                                        disablePast={this.state.userRol === "3" || this.state.userRol === "6"}
                                                        open={this.state.isOpen}
                                                        onOpen={() => this.setState({ isOpen: true })}
                                                        onClose={() => { this.setState({ isOpen: false }); }}
                                                        onChange={(date) => {
                                                            this.setState({ fechaInicio: date });
                                                        }}

                                                        label="Fecha de Inicio"
                                                        showTodayButton
                                                        cancelLabel={"Cancelar"}
                                                        okLabel={"Aceptar"}
                                                        todayLabel={"HOY"}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="clearfix">
                                    <Col md={12} lg={12}>
                                        <div className="form-group">
                                            <label>Resumen</label>
                                            <AvField
                                                innerRef={this.eightSelectRef}
                                                name="resumen"
                                                type="textarea"
                                                value={this.state.resumen}
                                                onChange={(event) => {
                                                    this.setState({ resumen: event.target.value })
                                                }}
                                                className="form-control"
                                                placeholder="Resumen del problema"
                                                rows={3}
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El resumen debe contener solo letras y numeros' },
                                                    maxLength: { value: 150, errorMessage: 'El resumen debe tener menos de 150 caracteres' }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <div style={{ display: this.state.tareaError, marginBottom: "1rem" }} className="invalid-feedback">Ingrese por lo menos una tarea</div>
                        {
                            (<Card>
                                <div className="header">
                                    <h2><strong>Lista</strong> de tareas</h2>
                                </div>
                                <div className="body todo_list">
                                    <label>Nombre de la tarea</label>
                                    <InputGroup className="mb-4">
                                        <Input
                                            type="text"
                                            style={{ flexWrap: 'wrap' }}
                                            value={this.state.tarea}
                                            onChange={(event) => this.setState({ tarea: event.target.value })}
                                            className="form-control"
                                            placeholder="Escriba tarea..."
                                        />
                                        <Input
                                            innerRef={this.nineSelectRef}
                                            type="text"
                                            style={{ flexWrap: 'wrap' }}
                                            value={this.state.comentario}
                                            onChange={(event) => this.setState({ comentario: event.target.value })}
                                            className="form-control"
                                            placeholder="Intrucciones"
                                        />
                                        <InputGroupAddon addonType="append">
                                            <Button onClick={() => this.agregarTarea()} className="btn btn-outline-secondary" type="submit" id="button-addon2" disabled={this.state.tarea === ""}>Agregar</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {this.state.checkList.length > 0 &&
                                        <ListGroupItem tag="li" className="list-group-item d-flex  align-items-center" style={{ backgroundColor: '#04be5b' }}>
                                            <Badge color="">#</Badge>
                                            <div className='justify-content-between d-flex w-100'>
                                                <div>Descripcion </div>
                                                <div>Instrucciones</div>
                                                <Badge
                                                    color="primary"
                                                    pill
                                                    onClick={() => this.setState({ showOtherAlert: true, delete: true })}
                                                >
                                                    x
                                                </Badge>
                                            </div>
                                        </ListGroupItem>
                                    }
                                    <ListGroup tag="ul" className="list-group">
                                        {
                                            this.state.checkList.map((item, index) => {
                                                return (
                                                    <ListGroupItem tag="li" key={index} className="list-group-item d-flex justify-content-between align-items-center" style={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff" }}  >
                                                        <Badge color=""  >{index + 1}</Badge>
                                                        <div className='d-flex align-items-center justify-content-between w-100'>
                                                            <div style={{ flexWrap: 'wrap', width: '48%', padding: 5 }}>{item.descripcion}</div>
                                                            <div style={{ flexWrap: 'wrap', width: '48%', padding: 5 }}>{item.comentario}</div>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <Badge color="primary" pill tag='span' onClick={() => this.setState({ indexSel: index, showOtherAlert: true })}>x</Badge>
                                                            </div>
                                                        </div>
                                                    </ListGroupItem>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </div>
                            </Card>)
                        }
                        <div style={{ display: this.state.tareaEmpty, marginBottom: "1rem" }} className="invalid-feedback">la tarea no puede estar vacia</div>
                    </AvForm>
                    <Button disabled={this.state.disabled} onClick={() => this.validate()} className="btn  badge-success btn-lg">{this.state.isLoading ? <Spinner color="light" size="sm" /> : !this.state.editing ? "Guardar" : "Actualizar"}</Button>
                    <Button onClick={() => history.goBack()} className="btn badge-default btn-lg">Cancelar</Button>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {/* {this.state.message}<a 
                        // href='/#'
                        href="javascript:location.reload()"
                         className="alert-link"> ¿Crear Otro?</a> */}
                        <Button
                            onClick={() => {
                                window.location.reload()
                                this.resetState();
                            }}
                            // href="javascript:location.reload()"
                            color="link"
                        >
                            {this.state.message}{` ¿Crear otra Orden de ${this?.state?.tipo?.value === 1 ? 'Trabajo' : 'Servicio'}?`}
                        </Button>
                        {/* <p>
                            {this.state.message}
                        </p> */}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear la nueva orden
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Si, quitar"
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="danger"
                    title="¿Estás seguro?"
                    onConfirm={
                        !this.state.delete ?
                            this.quitarTarea :
                            this.quitartodastareas}
                    onCancel={() => this.setState({ showOtherAlert: false })}
                    focusCancelBtn
                    show={this.state.showOtherAlert}
                    cancelBtnBsStyle='default'
                >
                    {this.state.delete ? "Todas las tareas serán eliminadas" : "La tarea será eliminada"}

                </SweetAlert>
            </Container >
        )
    }
}