import React from 'react';
import Loading from "./Loading"
import '../App.css';
import DashboardCard from '../components/DashboardCard';
import ReportCard from "../components/ReportCard";
import C3Chart from 'react-c3js';
import Container from "../Container";
import {
    Row,
    Col,
    Card,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap';
import Api from "../modules/reportes"
import history from "../history"
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import moment from "moment"
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
//eslint-disable-next-line
import SearchComponent from '../components/SearchComponent';

const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}

export default class Dashboard extends React.Component {

    state = {
        loading: true,
        openOT: 0,
        delayOT: 0,
        lowStock: 0,
        soliOT: 0,
        openSoliOT: 0,
        products: 0,
        closedOT_Month: 0,
        closedTasks_Month: 0,
        programmedMt_Month: 0,
        assignedOt_Month: 0,
        closedOT: [],
        closedTasks: [],
        programmedMt: [],
        assignedOt: [],
        solicitudes: [],
        data: {
            columns: []
        },
        axis: {
            x: {
                type: 'category',
                // name of each category
                categories: []
            },
        },
        legend: {
            show: true, //hide legend
        },
        padding: {
            bottom: 0,
            top: 0,
        },
        totalPages: 0,
        currentPage: 0,
        pages: [],
        search: {},

    }


    async componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        if (!userRol) {
            history.replace('/login');
        }
        this.setState({ userRol: parseInt(userRol, 10) })
        let closedOT_Month = 0, closedTasks_Month = 0, programmedMt_Month = 0, assignedOt_Month = 0;
        let closedOT = [], closedTasks = [], programmedMt = [], assignedOt = [];
        let weeks = []
        Api.dashboard().then((res) => {
            if (res) {
                for (const key in res.ordenesCerradas) {
                    const ot_closed = res.ordenesCerradas[key]
                    closedOT.push(ot_closed.value);
                    closedOT_Month = closedOT_Month + ot_closed.value;
                    weeks.push("Semana " + ot_closed.week)
                }
                for (const key in res.tareasFinalizadas) {
                    const task_finished = res.tareasFinalizadas[key]
                    closedTasks.push(task_finished.value);
                    closedTasks_Month = closedTasks_Month + task_finished.value;
                }
                for (const key in res.mantenimientosProgramados) {
                    const programmed_mt = res.mantenimientosProgramados[key]
                    programmedMt.push(programmed_mt.value);
                    programmedMt_Month = programmedMt_Month + programmed_mt.value;
                }
                for (const key in res.ordenesAsignadas) {
                    const ot_assigned = res.ordenesAsignadas[key]
                    assignedOt.push(ot_assigned.value);
                    assignedOt_Month = assignedOt_Month + ot_assigned.value;
                }
                this.setState({
                    openOT: res.ordenesAbiertas,
                    delayOT: res.ordenesAtrasadas,
                    lowStock: res.productosLow,
                    soliOT: res.ordenesSolicitadas,
                    openSoliOT: res.ordenesSolicitadasAbiertas,
                    products: res.productos,
                    closedOT,
                    closedOT_Month,
                    closedTasks,
                    closedTasks_Month,
                    programmedMt,
                    programmedMt_Month,
                    assignedOt,
                    assignedOt_Month,
                    data: {
                        columns: [
                            // each columns data
                            ['data1', ...closedOT],
                            // ['data2', ...closedTasks],
                            ['data3', ...programmedMt],
                        ],
                        type: 'area-spline', // default type of chart
                        groups: [
                            ['data1', 'data2', 'data3']
                        ],
                        colors: {
                            'data1': '#82c885',
                            // 'data2': '#2bcbba',
                            'data3': '#868e96',
                        },
                        names: {
                            // name of each serie
                            'data1': 'Ordenes',
                            // 'data2': 'Tareas',
                            'data3': 'Mantenimientos',
                        }
                    },
                    axis: {
                        x: {
                            type: 'category',
                            // name of each category
                            categories: weeks
                        },
                    },
                    loading: false
                })
            }
        }).catch((err) => {
            history.replace('/login');
        })
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.solicitudes({ page: 1 }).then((res) => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].solicitudTrabajoId,
                        numero: ('00000000' + response[key].solicitudTrabajoId).slice(-8),
                        descripcion: response[key].descripcion,
                        fecha: moment(response[key].fechaSolicitud).format("DD/MM/YYYY"),
                        estado: response[key].estado === 0 ? 'Pendiente' : (response[key].estado === 1 ? 'En Progreso' : 'Completada')

                    })
                }
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                solicitudes: data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })

    }

    initC3Chart() {

    }

    onSearch = (page) => {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.solicitudes({ page: page }).then((res) => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].solicitudTrabajoId,
                        numero: ('00000000' + response[key].solicitudTrabajoId).slice(-8),
                        descripcion: response[key].descripcion,
                        fecha: moment(response[key].fechaSolicitud).format("DD/MM/YYYY"),
                        estado: response[key].estado === 0 ? 'Pendiente' : (response[key].estado === 1 ? 'En Progreso' : 'Completada')

                    })
                }
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                solicitudes: data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    handleChangeSelect = (e) => {
        console.log(e);
    }

    handleChange = (e) => {
        console.log(e);
    }

    selectedItem = (e) => {
        console.log(e);
    }

    render() {
        return (
            <Container menuId="home">
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notifyError" />
                {/* Main Content */}
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12}>
                                <h2>Dashboard</h2>
                                <Breadcrumb className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Dashboard</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-fluid">
                        <Row className="clearfix">
                            {(this.state.userRol < 4 || this.state.userRol === 5) && <DashboardCard title="Solicitudes Abiertas" value={this.state.openSoliOT} iconName="documents" valuePorct={this.state.openSoliOT} value2={` de ${this.state.soliOT}`} valuePorctSt={`${((this.state.openSoliOT * 100) / this.state.soliOT)}%`} valueMax={this.state.soliOT} />}
                            {(this.state.userRol < 4 || this.state.userRol === 5) && <DashboardCard title="Ordenes Abiertas" value={this.state.openOT} iconName="documents" valuePorct={this.state.openOT} valuePorctSt={`${this.state.openOT}%`} valueMax={100} />}
                            {(this.state.userRol < 4 || this.state.userRol === 6) && <DashboardCard title="Ordenes En Progreso" value={this.state.delayOT} value2={` de ${this.state.openOT}`} iconName="documents" valuePorct={((this.state.delayOT * 100) / this.state.openOT)} valuePorctSt={`${((this.state.delayOT * 100) / this.state.openOT)}%`} valueMax={100} />}
                            {(this.state.userRol > 4 || this.state.userRol === 1) && <DashboardCard title="Productos en Low Stock" value={this.state.lowStock} value2={` de ${this.state.products}`} iconName="sales" valuePorct={this.state.lowStock} valuePorctSt={`${((this.state.lowStock * 100) / this.state.products)}%`} valueMax={this.state.products} />}

                        </Row>
                        {/* <SearchComponent /> */}
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                {(this.state.userRol < 4 || this.state.userRol === 6) &&
                                    <Row className="clearfix">
                                        <Col lg={12} >
                                            <Card >
                                                <div className="header">
                                                    <h2><strong><i className="zmdi zmdi-chart" /> Reporte</strong> de Estadisticas</h2>
                                                </div>
                                                {/* <div className="body mb-2"> */}
                                                <Row className="clearfix">
                                                    <ReportCard title="Ordenes Completadas" monthValue={this.state.closedOT_Month} weekValues={this.state.closedOT} barColor={"#82c885"} iconName="zmdi-assignment-check" />
                                                    {/* <ReportCard title="Tareas Finalizadas" monthValue={this.state.closedTasks_Month} weekValues={this.state.closedTasks} barColor={"#2bcbba"} iconName="zmdi-laptop" /> */}
                                                    <ReportCard title="Mantenimientos Programados" monthValue={this.state.programmedMt_Month} weekValues={this.state.programmedMt} barColor={"#868e96"} iconName="zmdi-calendar" />
                                                    {/* <ReportCard title="Ordenes Asignadas" monthValue={this.state.assignedOt_Month} weekValues={this.state.assignedOt} barColor={"#45aaf2"} iconName="zmdi-timer" /> */}
                                                </Row>
                                                {/* </div> */}
                                                <div className="body">
                                                    <C3Chart data={this.state.data} axis={this.state.axis} legend={{ show: true }}
                                                        padding={{
                                                            bottom: 0,
                                                            top: 0,
                                                        }} className="c3_chart d_sales" />
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                {
                                    (this.state.userRol === 4 || this.state.userRol === 5) && <Row className="clearfix">
                                        <Col lg={12}>
                                            <Card>
                                                <div className="header">
                                                    <h2><strong><i className="zmdi zmdi-file-text" /> Solicitudes </strong> de Orden de Trabajo</h2>
                                                </div>
                                                <div className="body mb-2">
                                                    <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
                                                        <thead>
                                                            <tr>
                                                                <TableHeaderlist title="#" />
                                                                <TableHeaderlist title="No. Solicitud" />
                                                                <TableHeaderlist title="Descripción" />
                                                                <TableHeaderlist title="Fecha" />
                                                                <TableHeaderlist title="Estado" />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.solicitudes.map((value, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} style={{ width: "60px" }} />
                                                                            <TableItemlist item={'A' + value.numero} />
                                                                            <TableItemlist item={value.descripcion} />
                                                                            <TableItemlist item={value.fecha} />
                                                                            <TableItemlist item={value.estado} />
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <div className="justify-content-center">
                                                        <Pagination className="pagination pagination-success mt-4">
                                                            {
                                                                this.state.pages.map((value, index) => {
                                                                    return (
                                                                        <PaginationItem active={value === this.state.currentPage}>
                                                                            <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                                                        </PaginationItem>
                                                                    );
                                                                })
                                                            }
                                                        </Pagination>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            {/* <Col lg={4} md={4} sm={12}>
                                <Card>
                                    <div className='header'>
                                        <h2><strong>tareas</strong> vencidas</h2>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className="table table-hover theme-color c_table">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='cursor-pointer' onClick={() => this.selectedItem()}>
                                                    <td className="text-muted">111222</td>
                                                    <td className="text-muted">12/12/12</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Card>
                            </Col> */}
                        </Row>
                    </div>
                </section>
            </Container>
        )
    }
}