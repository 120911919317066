import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Card, CardGroup, Col, Row, Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

export default function ModalRealizarOT(props) {
    const { show, onHide, data, handleModal, RealizarOT } = props;
    const [fecha, setFecha] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [revision_actual, setRevision_actual] = useState('');
    const [nombre_activo, setNombre_activo] = useState('');

    useEffect(() => {
        if (show) {
            console.log("===================> Modal Realizar OT <==========================");
            // console.log(data);
            if (Array.isArray(data)) {
                setFecha('');
                // concate names separados por coma
                let names = data.map((item) => item.nombre);
                console.log(names.join(', '), "namesss")
                setNombre_activo(names.join(',\n'));
            } else {
                if (data?.ultima_revision && data?.ultima_revision !== null) {
                    setFecha(moment(data?.ultima_revision).format('DD/MM/YYYY'));
                    setNombre_activo(data.nombre);
                }
            }
        } else {
            setFecha('');
            setRevision_actual('');
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);


    return (
        <Modal isOpen={show} size="md" fullscreen="lg" backdrop className="p-0">
            <ModalHeader toggle={() => onHide()}>{nombre_activo}</ModalHeader>
            <section className="content" style={{ width: '100%', margin: 0 }}>
                <ModalBody>
                    <CardGroup className="d-flex align-items-top">
                        <Card className="">
                            <Col className="body">
                                <label> Introdusca la fecha de revision</label>
                                <Row className='align-items-center'>
                                    <Col>
                                        <strong> {`Ultima revision: `}</strong>
                                    </Col>
                                    <Col md={7}>
                                        <p> {fecha}</p>
                                    </Col>
                                </Row>
                                <Row className='align-items-center'>
                                    <Col>
                                        <strong>Revision actual: </strong>
                                    </Col>
                                    <Col md={7}>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="zmdi zmdi-calendar" /></span>
                                            </div>
                                            <Input
                                                type="text"
                                                value={revision_actual}
                                                className="form-control datetimepicker"
                                                placeholder="Fecha de revision"
                                                onClick={() => setIsOpen(true)}
                                                readOnly
                                            />
                                        </div>
                                        <div style={{ display: "none" }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                <DatePicker
                                                    value={moment(revision_actual).format('DD/MM/YYYY')}
                                                    // disablePast
                                                    open={isOpen}
                                                    format="DD/MM/YYYY"
                                                    onOpen={() => setIsOpen(true)}
                                                    onClose={() => setIsOpen(false)}
                                                    onChange={(date) => {
                                                        handleModal(moment(date).format('DD/MM/YYYY'));
                                                        setRevision_actual(moment(date).format('DD/MM/YYYY'))
                                                    }}
                                                    label="Fecha de revision"
                                                    showTodayButton
                                                    cancelLabel={"Cancelar"}
                                                    okLabel={"Aceptar"}
                                                    todayLabel={"HOY"}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                        {/* <input type="date" className="form-control"
                                            onChange={(e) => { handleModal(e.target.value) }}
                                        /> */}
                                    </Col>
                                </Row>
                                <Row className='align-items-center mt-1'>
                                    <Col>
                                        <strong>Trabajo no planificado: </strong>
                                    </Col>
                                    <Col md={6} >
                                        <Input type="checkbox" className="checkbox_custom"
                                            onChange={(e) => { handleModal(e.target.checked) }}
                                        />
                                    </Col>
                                </Row>
                                <p className='text-muted'>No se requiere formulario</p>
                                <Row className='align-items-center'>
                                    <Col>
                                        <Button color="primary" className="btn-block" onClick={() => RealizarOT()}>Ok</Button>
                                    </Col>
                                    <Col>
                                        <Button color="secondary" className="btn-block" onClick={() => onHide()}>Cancelar</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Card>
                    </CardGroup>
                </ModalBody>
            </section>
        </Modal>
    )
}