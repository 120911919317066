import React from 'react';
import '../App.css';

export default class TableItemlist extends React.Component {
    render() {
        return (
            !this.props.widthout ?
                <td
                    className={this.props.className}
                    style={this.props.style ? this.props.style : {}}
                >
                    {this.props.item}
                </td> :
                <div className={this.props.className}
                    style={this.props.style ? this.props.style : {}}
                >
                    {this.props.item}
                </div>
        )
    }
}