import Proveedor from '../services/proveedor'

export default {
    async listBy () {
    let proveedores;
    try{
      proveedores = await Proveedor.listBy();
    } catch(error){
      //console.log(error)
      proveedores = false;
    }
    return proveedores ? proveedores.data : false;
  },
    async list (data) {
      let proveedores;
      if(!data) data = { page : 1}
      try{
        proveedores = await Proveedor.list(data);
      } catch(error){
        //console.log(error)
        proveedores = false;
      }
      return proveedores ? proveedores.data : false;
    },
    async search (data) {
      let proveedores;
      try{
        proveedores = await Proveedor.search(data);
      } catch(error){
        //console.log(error)
        proveedores = false;
      }
      return proveedores ? proveedores.data : false;
    },
    async listByTipo (data) {
      let proveedores;
      try{
        proveedores = await Proveedor.listByTipo(data);
      } catch(error){
        //console.log(error)
        proveedores = false;
      }
      return proveedores ? proveedores.data : false;
    },
    async save(data){
      let result;
      try {
        result = await Proveedor.save(data)
        //console.log(result,"result")
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listRol () {
      let proveedores;
      try{
        proveedores = await Proveedor.listRol();
      } catch(error){
        //console.log(error)
        proveedores = false;
      }
      return proveedores ? proveedores.data : false;
    },
    async get (data) {
      let proveedor;
      try{
        proveedor = await Proveedor.get(data.id);
      } catch(error){
        //console.log(error)
        proveedor = false;
      }
      return proveedor ? proveedor.data : false;
    },
        
    async update(data){
      let result;
      try {
        result = await Proveedor.update(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async delete (data) {
      let proveedor;
      try{
        proveedor = await Proveedor.delete(data.id);
      } catch(error){
        //console.log(error)
        proveedor = false;
      }
      return proveedor ? proveedor.data : false;
    },
}