import React, { Component } from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import Api from "../modules/activos"
import { Card, Col, Button, Breadcrumb, BreadcrumbItem, Alert, ButtonGroup, Row, Table, InputGroup, InputGroupAddon, InputGroupText, Input, Pagination, PaginationItem, PaginationLink, Badge, Modal } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import { TreeTable, TreeState } from 'cp-react-tree-table';
import { BsTable } from 'react-icons/bs';
import { GiCheckboxTree } from 'react-icons/gi';
import NotificationAlert from 'react-notification-alert';
import CreateAssest from './CreateAssest';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class Assest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataTable: [],
            data: [],
            confirm: false,
            activoId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            view: "table",
            treeValue: TreeState.create([]),
            totalPages: 0,
            currentPage: 0,
            pages: [],
            totalPages2: 0,
            currentPage2: 0,
            pages2: [],
            search: "",
            childrenExpanded: [],
            userRol: "",
            showAssest: false,
            id: "",
        }
        this.deleteActivo = this.deleteActivo.bind(this)
        this.deleteLocalizacion = this.deleteLocalizacion.bind(this)
    }

    componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        this.setState({ userRol: parseInt(userRol, 10) })
        this.onChangeView(this.state.view);

    }

    onChangeView = (view) => {
        this.setState({ view })
        if (view === "table") {
            this.listActivos(1);
        }
        if (view === "tree") {
            this.listActivosTree(1);
        }
    }

    async listActivosTree(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.listAll({ view: "tree", page }).then(res => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].categoria + "-" + response[key].id,
                        categoriaActivo: response[key].categoria,
                        nombre: response[key].nombre,
                        codigo: response[key].codigo,
                        localizacion: response[key].localizacionDescripcion,
                        tipoEquipo: response[key].tipoActivo,
                        esHijo: response[key].tieneHijos,
                        padreId: response[key].padreId ? response[key].categoriaPadre + "-" + response[key].padreId : "-1",
                        realId: response[key].id,
                        esPadre: response[key].esPadre,
                        fueraServicio: response[key].fueraServicio
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            const tree = this.getNestedChildren(data, "-1");
            this.setState({
                data,
                treeValue: TreeState.create(tree.map((node) => this.processNode(node))),
                pages2: pages,
                currentPage2: currentPage,
                totalPages2: totalPages,
                loading: false
            })
        })
    }

    async listActivos(page) {
        const data = [];
        let pages = [], totalPages = 0, currentPage = 0;
        Api.listAll({ view: "table", page }).then(res => {
            if (res) {
                const response = res.data;
                // console.log("🚀 ~ file: Assest.js:123 ~ Assest ~ Api.listAll ~ response:", response)
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].categoria + "-" + response[key].id,
                        categoriaActivo: response[key].categoria,
                        nombre: response[key].nombre,
                        codigo: response[key].codigo,
                        localizacion: response[key].localizacionDescripcion,
                        tipoEquipo: response[key].tipoActivo,
                        esHijo: response[key].tieneHijos,
                        padreId: response[key].padreId ? response[key].categoriaPadre + "-" + response[key].padreId : "-1",
                        realId: response[key].id,
                        esPadre: response[key].esPadre,
                        fueraServicio: response[key].fueraServicio
                    })
                }
                if (totalPages === 0) {
                    this.refs.notify.notificationAlert(options);
                }
                // for (let i = 0; i < totalPages; i++) {
                //     pages.push((i + 1));
                // }
                this.setState({
                    dataTable: data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            }
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                dataTable: data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    onSearch = (page, view) => {
        const { search } = this.state;
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "" && view === "table") {
            Api.searchAll({ page, search }).then(res => {
                if (res) {
                    const response = res.data;
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].categoria + "-" + response[key].id,
                            categoriaActivo: response[key].categoria,
                            nombre: response[key].nombre,
                            codigo: response[key].codigo,
                            localizacion: response[key].localizacionDescripcion,
                            tipoEquipo: response[key].tipoActivo,
                            esHijo: response[key].tieneHijos,
                            padreId: response[key].padreId ? response[key].categoriaPadre + "-" + response[key].padreId : "-1",
                            realId: response[key].id,
                            esPadre: response[key].esPadre,
                            fueraServicio: response[key].fueraServicio
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    view: "table",
                    dataTable: data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            view === "table" ? this.listActivos(page) : this.listActivosTree(page);
        }
    }

    getNestedChildren(arr, parent) {
        let out = []
        //console.log({parent})
        for (let i in arr) {
            if (arr[i].padreId === parent) {
                let children = this.getNestedChildren(arr, arr[i].id)

                if (children.length) {
                    arr[i].children = children
                }
                out.push(arr[i])
            }
        }
        return out
    }

    processNode = (rawNode) => {
        const processedNode = { data: {} };

        // Data
        const keys = Object.keys(rawNode).filter((key) => (key !== 'children') && (key !== 'height'));
        keys.forEach((key) => {
            processedNode.data[key] = rawNode[key];
        });

        // Height
        // if (rawNode.height != null) {
        processedNode.height = 40;
        //}

        // Children
        if (rawNode.children != null && rawNode.children.length > 0) {
            processedNode.children = [];
            for (let i = 0; i < rawNode.children.length; i++) {
                processedNode.children.push(this.processNode(rawNode.children[i]));
            }
        }

        return processedNode;
    }

    updateActivo(id, e) {
        const type = id.split("-");
        switch (type[0]) {
            case "1"://activo
                history.push('/createassestequipment', { id: parseInt(type[1], 10), e });
                break;
            case "2"://localizacion
                history.push('/createassestbuildings', { id: parseInt(type[1], 10), e });
                break;
            default:
        }
        //
    }

    deleteActivo = () => {
        console.log("delete")
        const { activoId } = this.state;
        this.setState({ confirm: false, visible: true, loading: true })
        console.log(activoId)
        Api.delete({ id: activoId }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false,
                    loading: true
                }, () => { this.listActivos(1); this.listActivosTree(1) })
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    deleteLocalizacion = () => {
        const { localizacionoId } = this.state;
        this.setState({ confirm: false, visible: true, loading: true })
        Api.deleteLocalizacion({ id: localizacionoId }).then((response) => {
            if (response) {
                this.setState({
                    loading: true,
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false,
                }, () => { this.listActivos(1); this.listActivosTree(1) })
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    showDialog = (value) => {
        const type = value.split("-");
        switch (type[0]) {
            case "1"://activo
                this.setState({ confirm: true, activoId: type[1], delete1: true, delete: false })
                break;
            case "2"://localizacion
                this.setState({ confirm: true, localizacionoId: type[1], delete1: false, delete: true })
                break;
            default:

        }

    }

    printTableView = () => {
        const { dataTable } = this.state;
        const data = dataTable;
        return <Table hover responsive className="mb-0 c_list c_table break_table theme-color">
            <thead>
                <tr>
                    <TableHeaderlist title="#" />
                    <TableHeaderlist title="Nombre" />
                    <TableHeaderlist title="Tipo" />
                    <TableHeaderlist title="Localización" />
                    {this.state.userRol < 3 && <TableHeaderlist title="Accion" />}
                </tr>
            </thead>
            <tbody>
                {
                    data.map((value, i) => {
                        return (
                            <tr key={i}>
                                <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} style={{ width: "60px" }} />
                                <td>
                                    {value.nombre}<br />
                                    <small>{value.codigo} {value.fueraServicio && <Badge color="danger">Fuera de Servicio</Badge>}</small>

                                </td>
                                <TableItemlist item={value.tipoEquipo} />
                                <TableItemlist item={value.localizacion} />
                                <td>
                                    {
                                        this.state.userRol < 3 &&
                                        <div style={{ width: "150px" }}>
                                            <Button color="warning" size="sm" onClick={() => this.updateActivo(value.id, true)}><i className="zmdi zmdi-eye" /></Button>
                                            <Button color="primary" size="sm" onClick={() => this.updateActivo(value.id)}><i className="zmdi zmdi-edit" /></Button>
                                            <Button color="danger" size="sm" onClick={() => this.showDialog(value.id)} ><i className="zmdi zmdi-delete" /></Button>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    }

    onParentClick = (row, isExpanded) => {
        // console.log("🚀 ~ file: Assest.js:385 ~ Assest ~ row:", row)
        let { childrenExpanded } = this.state;
        if (isExpanded || childrenExpanded.includes(row.data.id)) {
            row.toggleChildren()
        } else {
            let { data } = this.state;
            Api.listChildren({ parent: row.data.realId }).then((response) => {
                if (response) {
                    for (let key in response) {
                        data.push({
                            id: response[key].categoria + "-" + response[key].id,
                            categoriaActivo: response[key].categoria,
                            nombre: response[key].nombre,
                            codigo: response[key].codigo,
                            localizacion: response[key].localizacionDescripcion,
                            tipoEquipo: response[key].tipoActivo,
                            esHijo: response[key].tieneHijos,
                            padreId: response[key].padreId ? response[key].categoriaPadre + "-" + response[key].padreId : "-1",
                            realId: response[key].id,
                            esPadre: response[key].esPadre,
                        })
                    }
                }
            }).finally(() => {
                //console.log({data})
                childrenExpanded.push(row.data.id)
                const tree = this.getNestedChildren(data, "-1");
                //console.log({tree})
                const treeValue = TreeState.create(tree.map((node) => this.processNode(node)));
                // console.log("🚀 ~ file: Assest.js:414 ~ Assest ~ Api.listChildren ~ treeValue:", treeValue)
                const index = treeValue.data.findIndex(item => item.data.id === row.data.id);
                const rowModel = treeValue.findRowModel(treeValue?.data[index + 1]);
                //console.log({treeValue})
                if (rowModel !== null) {
                    this.setState({
                        childrenExpanded,
                        treeValue: TreeState.expandAncestors(treeValue, rowModel),
                    })
                }
                /*           row.updateData({
                               ...row.data,
                               children: tree[0].nombre,
                           })
               */
                //row.toggleChildren()
            })
        }
    }

    printTreeView = () => {
        const { treeValue } = this.state;
        return (
            <TreeTable
                ref={elem => { this.treeTable = elem }}
                value={treeValue}
                headerHeight={34}
                height={300}
                className={"table table-hover mb-0 c_list c_table theme-color"}
                onChange={(newValue) => {
                    this.setState({ treeValue: newValue });
                }}>
                <TreeTable.Column basis="300px" grow="1"
                    renderCell={(row) => {
                        return (
                            <div style={{ paddingLeft: (row.data.esPadre ? 0 : 17) + (row.metadata.depth * 15) + 'px' }}
                                className={row.data.esPadre ? 'with-children' : 'without-children'}>
                                {(row.data.esPadre)
                                    ? (
                                        <Button className={`toggle-button ${row.$state.isExpanded ? 'expanded' : ''}`} onClick={() => this.onParentClick(row, row.$state.isExpanded)}></Button>
                                    )
                                    : ''
                                }
                                {row.data.nombre}<br></br>
                                <small style={{ paddingLeft: (row.data.esPadre ? 17 : 0) + 'px' }}>{row.data.codigo}</small>
                            </div>
                        );
                    }}
                    renderHeaderCell={() => <TableHeaderlist title="Nombre" widthout />}
                />
                <TreeTable.Column basis="500px" grow="1"
                    renderCell={(row) => <TableItemlist item={row.data.localizacion} widthout />}
                    renderHeaderCell={() => <TableHeaderlist title="Localización" widthout />}
                />
                {this.state.userRol < 3 && <TreeTable.Column basis="100px" grow="0"
                    renderCell={(row) => <div>
                        <Button color="warning" size="sm" onClick={() => this.updateActivo(row.data.id, true)}><i className="zmdi zmdi-eye" /></Button>
                        <Button onClick={() => this.updateActivo(row.data.id)} className="btn btn-primary btn-sm"><i className="zmdi zmdi-edit" /></Button>
                        <Button onClick={() => this.showDialog(row.data.id)} className="btn btn-danger btn-sm"><i className="zmdi zmdi-delete" /></Button>
                    </div>}
                    renderHeaderCell={() => <TableHeaderlist title="Acción" widthout />}
                />}
            </TreeTable>
        )
    }

    render() {

        return (
            <Container menuId="activos" submenu="todos" assetsToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={7} md={6} sm={12}>
                                <h2>Todos los activos</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Activos</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" active tag="span">Todos Los Activos</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12}>
                                {this.state.userRol < 3 &&
                                    <Button color="success" className="btn-icon float-right" type="button" onClick={() => history.push("/createassest")}>
                                        <i className="zmdi zmdi-plus" />
                                    </Button>
                                }
                                <ButtonGroup>
                                    <Button onClick={() => this.onChangeView("table")} className={this.state.view === "table" ? "btngroup active" : ""} ><BsTable size={18} /></Button>
                                    <Button onClick={() => this.onChangeView("tree")} className={this.state.view === "tree" ? "btngroup active" : ""}><GiCheckboxTree size={18} /></Button>
                                </ButtonGroup>
                                <InputGroup className="float-right">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1, "table") }}
                                        className="form-control"
                                        placeholder="Busqueda..."
                                        value={this.state.search}
                                        onChange={(event) => this.setState({ search: event.target.value })} />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-fluid">
                        <Row className="clearfix">
                            <Col lg={12} >
                                <Card >
                                    {this.state.view === "table" ? this.printTableView() : this.printTreeView()}
                                    {this.state.view === "table" ?
                                        <div className="justify-content-center">
                                            <Pagination className="pagination pagination-success mt-4">
                                                {
                                                    this.state.pages.map((value, index) => {
                                                        return (
                                                            <PaginationItem
                                                                key={index}
                                                                active={value === this.state.currentPage}>
                                                                <PaginationLink href="#" onClick={() => this.onSearch(value, " table")}>{value}</PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                    })
                                                }
                                            </Pagination>
                                        </div> :
                                        <div className="justify-content-center">
                                            <Pagination className="pagination pagination-success mt-4">
                                                {
                                                    this.state.pages2.map((value, index) => {
                                                        return (
                                                            <PaginationItem active={value === this.state.currentPage2} key={index}>
                                                                <PaginationLink href="#" onClick={() => this.onSearch(value, "tree")}>{value}</PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                    })
                                                }
                                            </Pagination>
                                        </div>}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, eliminar"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.state.delete1 ? this.deleteActivo : this.deleteLocalizacion}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    Al eliminar la localización se eliminarán todos los activos de esta localización
                </SweetAlert>
                <Modal
                    title="Basic Modal"
                    visible={this.state.showAssest}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <CreateAssest id={this.state.id} e={true} />
                </Modal>
            </Container>
        )
    }
}