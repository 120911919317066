import React from 'react';
import Select from 'react-select';

export default class SelectCategoriadisabled extends React.Component {
    render() {
        return (
            <div>
                <Select noOptionsMessage={() => 'Sin Resultados'}
                    placeholder={"-- Categoria --"}
                    isDisabled
                />
            </div>
        )
    }
}