import React from 'react';
import { Button } from 'reactstrap';

export default class ReportCard extends React.Component {

    render(){
        return(
            <li>
                <Button color="link" onClick={this.props.onPress}>
                    <div className="icon-circle bg-green"><i className={"zmdi zmdi-"+this.props.icon} /></div>
                    <div className="menu-info">
                        <h4>{this.props.title}</h4>
                        <p><i className="zmdi zmdi-time" /> {this.props.time} </p>
                    </div>
                </Button>
            </li>
        )
    }
}