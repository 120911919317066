import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import { Input, Button, Alert, Card, Col, Row, FormGroup, Spinner } from 'reactstrap';
import Api from "../modules/inventario"
import Select from 'react-select';
import { AsyncPaginate } from "react-select-async-paginate";
import history from '../history';
import NumberFormat from 'react-currency-format';
import { AvForm } from 'availity-reactstrap-validation';
import Breadcrumb from 'reactstrap/lib/Breadcrumb';
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem';
import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
const defaultAdditional = {
    page: 1
};

export default class CrearMovimientoInventario extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            tipos: [],
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            editing: false,
            cantidad: 0,
            costo: 0,
            costoActual: 0,
            repuestoError: "none",
            producto: null,
            tipoMovimiento: null,
            tipoMovError: "none"
        }
    }


    async componentDidMount() {
        await this.getTipoMovimientos();
    }

    async getTipoMovimientos() {
        let tipos = []
        Api.listTipoMovimientos().then((response) => {
            if (response) {
                for (let key in response) {
                    tipos.push({
                        value: response[key].tipoMovimientoId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ tipos, loading: false })
        })
    }

    selectTipoMovimiento = selectedOption => {
        this.setState(
            { tipoMovimiento: selectedOption }
        );
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    loadOptions = async (search, prevOptions, { page }) => {
        if (search === "") search = "a";
        let productos = []
        const res = await Api.listProductoBySearch({ search, page })
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                productos.push({
                    label: response[key].nombre,
                    value: response[key].productoId,
                    costoActual: response[key].costo,
                    stockMinimo: response[key].stockMinimo
                })
            }
        }
        const results = {
            options: productos,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    upCantidad = () => {
        this.setState({ cantidad: this.state.cantidad + 1 })
    }

    downCantidad = () => {
        if (this.state.cantidad > 0)
            this.setState({ cantidad: this.state.cantidad - 1 })
    }

    save() {
        this.setState({ disabled: true, isLoading: true })
        const { cantidad, costoActual, tipoMovimiento, producto } = this.state;
        const data = {
            tipoMovimiento: {
                tipoMovimientoId: tipoMovimiento.value,
                descripcion: tipoMovimiento.label
            },
            descripcion: "",
            producto: {
                productoId: producto.value
            },
            costo: costoActual,
            cantidad
        }
        Api.saveMovimiento(data).then((response) => {
            if (response && response.movimientoInventarioId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    onSearchChange = (selectedOption) => {
        if (selectedOption) {

            this.setState({
                producto: selectedOption,
                costo: selectedOption.costoActual + "",
                costoActual: selectedOption.costoActual
            });
        }
    };

    validate() {
        //validate selects
        const { producto, tipoMovimiento } = this.state;
        this.setState({
            repuestoError: producto === null ? "block" : "none",
            tipoMovError: tipoMovimiento === null ? "block" : "none"
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        //validate select
        const { repuestoError, tipoMovError } = this.state;
        if (repuestoError === "none" && tipoMovError === "none") {
            this.save()
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    render() {
        const { tipos, loading } = this.state;
        if (loading) return <Loading />;
        return (
            <Container menuId="reservas">
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={7} md={6} sm={12} >
                                <h2>Entrada y Salidas</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Inventario</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item App-link active" href="/managestock" tag="a">Movimientos</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Entrada y Salidas</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                        </Row>
                    </div>
                    <AvForm onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}
                        onInvalidSubmit={() => this.handleInvalidSubmit()}>
                        <Card >
                            <div className="body">
                                <Row className="clearfix">
                                    <Col sm={12} >
                                        <Row className="clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                                        ref={ref => { this.repuestoRef = ref; }}
                                                        additional={defaultAdditional}
                                                        loadingMessage={() => "Buscando repuesto o material.."}
                                                        placeholder={"-- Seleccione Repuesto o material --"}
                                                        cacheOptions
                                                        defaultOptions
                                                        value={this.state.producto}
                                                        onChange={(e) => {
                                                            this.onSearchChange(e);
                                                        }}
                                                        loadOptions={this.loadOptions}
                                                        onInputChange={this.handleInputChange}
                                                        onBlur={() => { this.state.producto === null ? this.setState({ repuestoError: "block" }) : this.setState({ repuestoError: "none" }) }}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.repuestoError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                    />
                                                    <div style={{ display: this.state.repuestoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el repuesto o material</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <AvGroup>
                                                        <AvInput tag={NumberFormat}
                                                            name="cantidad"
                                                            onKeyUp={(event) => { if (event.keyCode === 13) this.tipoMovRef.focus() }}
                                                            type="text"
                                                            className="form-control"
                                                            value={this.state.cantidadText}
                                                            innerRef={(e) => this.inputCantidad = e}
                                                            customInput={Input}
                                                            thousandSeparator={true}
                                                            prefix={''}
                                                            allowNegative={false}
                                                            placeholder="Cantidad"
                                                            hintText="Cantidad"
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                this.setState({ cantidadText: formattedValue, cantidad: value })
                                                            }}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Ingrese la cantidad' },
                                                            }}
                                                        />
                                                        <AvFeedback>Ingrese la cantidad</AvFeedback>
                                                    </AvGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="row clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                                        styles={{
                                                            container: base => ({
                                                                ...base,
                                                                border: this.state.tipoMovError === "block" ? "1px solid #ced4da" : "none",
                                                                borderRadius: ".25rem",
                                                                borderColor: "#dc3545"
                                                            }),
                                                        }}
                                                        ref={ref => { this.tipoMovRef = ref; }}
                                                        placeholder={"-- Seleccione tipo de Movimiento --"}
                                                        value={this.state.tipoMovimiento}
                                                        onChange={this.selectTipoMovimiento}
                                                        options={tipos}
                                                        onBlur={() => { this.state.tipoMovimiento === null ? this.setState({ tipoMovError: "block" }) : this.setState({ tipoMovError: "none" }) }}
                                                    />
                                                    <div style={{ display: this.state.tipoMovError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el tipo de movimiento</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <AvGroup>
                                                        <AvInput tag={NumberFormat}
                                                            name="costo"
                                                            onKeyUp={(event) => { if (event.keyCode === 13) this.validate() }}
                                                            innerRef={e => this.costoRef = e}
                                                            value={this.state.costo}
                                                            customInput={Input}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            placeholder="Costo Actual"
                                                            hintText="Costo Actual"
                                                            allowNegative={false}
                                                            onValueChange={(values) => {
                                                                const { formattedValue, value } = values;
                                                                this.setState({ costo: formattedValue, costoActual: value })
                                                            }}
                                                            validate={{
                                                                required: { value: true, errorMessage: 'Ingrese existencia Minima' },
                                                            }}
                                                        />
                                                        <AvFeedback>Ingrese costo actual</AvFeedback>
                                                    </AvGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        <Button disabled={this.state.disabled} onClick={() => this.validate()} className="btn  badge-success btn-lg">{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>
                        <Button onClick={() => history.goBack()} className="btn badge-default btn-lg">Cancelar</Button>
                    </AvForm>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {/* {this.state.message} <a
                            // href='/'
                               href="javascript:location.reload()" 
                            className="alert-link"> ¿Crear Otro?</a> */}
                        <Button
                            onClick={() => window.location.reload()}
                            // href="javascript:location.reload()"
                            color="link"
                        >{this.state.message} ¿Crear Otro?
                        </Button>
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al guardar
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                </section>
            </Container>
        )
    }
}