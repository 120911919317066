export const TIPO_MANTENIMIENTO_STATUS = {
    PREVENTIVO: 1,
    CORRECTIVO: 2,
    PREDICTIVO: 3,
    EMERGENCIA: 4
}

export const TIPO_MANTENIMIENTO = {
    1: "Preventivo",
    2: "Correctivo",
    3: "Predictivo",
    4: "Emergencia"
}

export const ESTADOS_ORDEN = {
    ABIERTA: 1,
    EN_PROGRESO: 2,
    COMPLETADA: 3,
    NO_COMPLETADA: 4
}

export const DATOS_BASICOS = {
    NOMBRE_EMPRESA: "SIACOM",
    NOMEBRE_SISTEMA: "CMMS",
    VERSION: "1.0.0",
}