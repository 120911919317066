import React from 'react';
import Container from "../Container";
import Select from 'react-select';
import { Row, Col, Breadcrumb, BreadcrumbItem, Button, Card, FormGroup, CardBody, Label, Input } from 'reactstrap';
import { server } from "../services/config"
import IframeResizer from 'iframe-resizer-react'
import ApiActivos from "../modules/activos"
import ApiInv from "../modules/inventario"
import APiUser from "../modules/user"
import ApiPlan from "../modules/plan"
import ApiProv from "../modules/proveedor"
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment"
import "moment/locale/es";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ReporteOrdenTrabajo from '../Order/reporte/ReporteOrdenTrabajo';
import { Modal as ModalAnt } from 'antd';
//eslint-disable-next-line
import ReactToPrint from "react-to-print";

const defaultAdditional = {
    page: 1
};

export default class Reports extends React.Component {
    state = {
        reportes: [
            { label: "Activos", value: 0, placeholder: "" },
            { label: "Repuestos y Materiales", value: 1 },
            { label: "Ordenes", value: 2 },
            { label: "Usuarios", value: 3 },
            { label: "Indicadores de Desempeño", value: 4 }
        ],
        reporteSel: { label: "Activos", value: 0 },
        show: false,
        reportUri: "",
        listPlaceholder: "Seleccione Localizacion",
        listSel: null,
        list: [],
        optioSearch: "",
        searchList: false,
        reportId: 0,
        isOpen: false,
        isOpen2: false,
        showDate: false,
        value: "",
        showInput: false,
        tipoOrdenSel: { label: "Todos", value: 0, tipo1: 1, tipo2: 2 },
        clasificacionesActivo: [],
        loading: false,
        open: false,
        showot: false,
    }

    async componentDidMount() {
        const results = await this.fillTipoActivos("", null, { page: 1 })
        this.setState({
            clasificacionesActivo: results
        })
    }

    componentRef = null;


    handleAfterPrint = () => {
        console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    };

    handleBeforePrint = () => {
        console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    };

    handleOnBeforeGetContent = () => {
        console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        this.setState({ text: "Loading new text...", isLoading: true });

        return new Promise((resolve) => {
            setTimeout(() => {
                this.setState(
                    { text: "New, Updated Text!", isLoading: false },
                    resolve
                );
            }, 2000);
        });
    };

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };

    reactToPrintTrigger = () => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.

        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

        // Good
        return <button>Print using a Class Component</button>;
    };



    generar = () => {
        const { reporteSel, optioSearch, listSel, fechaInicio, fechaFinal, value, tipoOrdenSel } = this.state;
        let report = ""
        switch (reporteSel.value) {
            case 0:
                //activos
                if (optioSearch === "") report = "activos";
                if (optioSearch === "localizaciones") report = "activos/localizacion?id=" + listSel.value;
                if (optioSearch === "clasificacion") report = "activos/clasificacion?id=" + listSel.value;
                if (optioSearch === "enservicio") report = "activos/servicio";
                break;
            case 1:
                //inventario
                if (optioSearch === "repuestos") report = "inventario";
                if (optioSearch === "repuestosclasificacion") report = "repuestos/categoria?id=" + listSel.value;
                if (optioSearch === "repuestosubicacion") report = "repuestos/ubicacion?id=" + listSel.value;
                if (optioSearch === "repuestosreposicion") report = "reposicion";
                break;
            case 2:
                //ordenes
                if (optioSearch === "ordenes") report = "ordenes";
                if (optioSearch === "ordendetalletrabajo") {
                    // report = `ordennumero?id=${value}&tipo=1`
                    let data = {
                        ordenTrabajoId: value,
                        activo_id: 0,
                        plan_id: 0,
                        tipoOT: 1,
                        fechaOT: 0
                    }
                    this.setState({ dataReporte: data, open: true })
                };
                if (optioSearch === "ordendetalleservicio") report = `ordennumero?id=${value}&tipo=2`;
                if (optioSearch === "ordenestado") report = `ordenes/filtro?estado=${listSel.value}&from=${moment(fechaInicio).format("YYYY-MM-DD")}&to=${moment(fechaFinal).format("YYYY-MM-DD")}&tipo1=${tipoOrdenSel.tipo1}&tipo2=${tipoOrdenSel.tipo2}`;
                if (optioSearch === "ordenasignadatec") report = "user/orden/" + listSel.value;
                if (optioSearch === "ordenasignadaprov") report = "proveedor/orden/" + listSel.value;
                if (optioSearch === "ordenactivo") report = `ordenactivo?id=${listSel.value}&from=${moment(fechaInicio).format("YYYY-MM-DD")}&to=${moment(fechaFinal).format("YYYY-MM-DD")}`;
                break;
            case 3:
                report = `usuarios`;
                break;
            case 4:
                //desempeño
                report = `indicadores?from=${moment(fechaInicio).format("YYYY-MM-DD")}&to=${moment(fechaFinal).format("YYYY-MM-DD")}`;
                break;
            default:
                report = "activos";
        }
        this.setState({
            reportUri: report,
            show: true
        })
    }

    handleChange = selectedOption => {
        if (selectedOption) {
            this.setState(
                { listSel: selectedOption },

            );
        }
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    loadOptions = async (search, prevOptions, { page }) => {
        const { optioSearch } = this.state;
        if (optioSearch === "localizaciones") return this.fillLocalizaciones(search, prevOptions, { page })
        if (optioSearch === "clasificacion") return this.fillTipoActivos(search, prevOptions, { page })
        if (optioSearch === "repuestosclasificacion") return this.fillCategoriasInv(search, prevOptions, { page })
        if (optioSearch === "repuestosubicacion") return this.fillUbicacionInv(search, prevOptions, { page })
        if (optioSearch === "ordenestado") return this.fillOrdenEstados(search, prevOptions, { page })
        if (optioSearch === "ordenasignadatec") return this.fillTecnicos(search, prevOptions, { page })
        if (optioSearch === "ordenasignadaprov") return this.fillProveedor(search, prevOptions, { page })
        if (optioSearch === "ordenactivo") return this.fillActivos(search, prevOptions, { page })
        return []
    }

    fillLocalizaciones = async (search, prevOptions, { page }) => {
        let localizaciones = []
        if (search === "") search = "a";
        const res = await ApiActivos.search({ search, page });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                localizaciones.push({
                    value: response[key].localizacionId,
                    label: response[key].nombre + " [" + response[key].codigo + "]",
                    descripcion: response[key].localizacionDescripcion
                })
            }
        }
        return {
            options: localizaciones,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        };
    };

    fillTipoActivos = async (search, prevOptions, { page }) => {
        let tipoActivos = []
        const response = await ApiActivos.listTiposActivos();
        if (response) {
            for (let key in response) {
                tipoActivos.push({
                    value: response[key].tipoActivoId,
                    label: response[key].descripcion
                })
            }
        }
        return tipoActivos;
    };

    fillCategoriasInv = async (search, prevOptions, { page }) => {
        let categorias = []
        const response = await ApiInv.listCategorias();
        if (response) {
            for (let key in response) {
                categorias.push({
                    value: response[key].categoriaId,
                    label: response[key].descripcion
                })
            }
        }
        return {
            options: categorias,
            hasMore: false,
            additional: null
        };
    }

    fillUbicacionInv = async (search, prevOptions, { page }) => {
        let ubicaciones = []
        const response = await ApiInv.listUbicaciones();
        if (response) {
            for (let key in response) {
                ubicaciones.push({
                    value: response[key].ubicacionId,
                    label: response[key].descripcion
                })
            }
        }
        return {
            options: ubicaciones,
            hasMore: false,
            additional: null
        };
    }

    fillOrdenEstados = async (search, prevOptions, { page }) => {
        return {
            options: [
                { value: "1", label: "Abierta" },
                { value: "2", label: "En Progreso" },
                { value: "3", label: "Completada" },
                { value: "4", label: "No Completada" }
            ],
            hasMore: false,
            additional: null
        };
    }

    fillTecnicos = async (search, prevOptions, { page }) => {
        let asignadoa = []
        const response = await APiUser.list({ filtro: 1 });
        console.log(response)
        if (response) {
            const res = response.data
            for (const key in res) {
                asignadoa.push({
                    value: res[key].usuarioId,
                    label: res[key].persona.nombre + " " + res[key].persona.apellido
                })
            }
        }
        return {
            options: asignadoa,
            hasMore: false,
            additional: null
        };
    }

    fillProveedor = async (search, prevOptions, { page }) => {
        let asignadoa = []
        const response = await ApiProv.listBy();
        console.log(response)
        if (response) {

            for (const key in response) {
                asignadoa.push({
                    value: response[key].proveedorId,
                    label: response[key].persona.nombre
                })
            }
        }
        return {
            options: asignadoa,
            hasMore: false,
            additional: null
        };
    }

    fillActivos = async (search, prevOptions, { page }) => {
        if (search === "") search = "a";
        let activos = []
        const res = await ApiPlan.listActivoBySearch({ search, page, filtro: 1 });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                activos.push({
                    label: `${response[key].nombre} (${response[key].codigo})`,
                    value: response[key].activoId,
                    tipo: response[key].tiposActivo.tipoActivoId
                })
            }
        }
        return {
            options: activos,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        };
    }




    render() {
        return (
            <Container menuId="reportes">
                <section className="content">
                    <div className="block-header">
                        <Row className="row">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Reportes</h2>
                                <Breadcrumb tag="ul" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Reportes</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <div className="header">
                                    <h2><strong>Generación</strong> de Reportes</h2>
                                </div>
                                <CardBody>
                                    <p>Seleccione los filtros que desea aplicar para el reporte a visualizar</p>
                                    <FormGroup lg={12}>

                                        <Select noOptionsMessage={() => 'Sin Resultados'}
                                            placeholder={"-- Seleccione Reporte --"}
                                            value={this.state.reporteSel}
                                            onChange={(selectedOption) => {
                                                this.setState({ reporteSel: selectedOption, searchList: false, optioSearch: "" });
                                            }}
                                            options={this.state.reportes}
                                        />
                                    </FormGroup>
                                    {this.state.reporteSel.value === 0 &&
                                        <FormGroup row>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radio" id="radio0" value="option0" onClick={() => this.setState({ optioSearch: "", listSel: null, searchList: false, listPlaceholder: "", showDate: false, showInput: false })} />
                                                    <Label for="radio0">Todos los Activos</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radio" id="radio1" value="option1" onClick={() => this.setState({ optioSearch: "localizaciones", listSel: null, searchList: true, listPlaceholder: "Seleccione Localización", showDate: false, showInput: false })} />
                                                    <Label for="radio1">Por Localización</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radio" id="radio2" value="option2" onClick={() => this.setState({ optioSearch: "clasificacion", listSel: null, searchList: false, listPlaceholder: "Seleccione Tipo de Activo", showDate: false, showInput: false, })} />
                                                    <Label for="radio2">Por Clasificación</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radio" id="radio3" value="option3" onClick={() => this.setState({ optioSearch: "enservicio", listSel: null, searchList: false, listPlaceholder: "", showDate: false, showInput: false })} />
                                                    <Label for="radio3">En Servicio</Label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {this.state.reporteSel.value === 1 &&
                                        <FormGroup row>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioinv" id="radioinv0" value="option0" onClick={() => this.setState({ optioSearch: "repuestos", listSel: null, searchList: false, listPlaceholder: "", showDate: false, showInput: false })} />
                                                    <Label for="radioinv0">Todos los repuestos y materiales</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioinv" id="radioinv1" value="option1" onClick={() => this.setState({ optioSearch: "repuestosclasificacion", listSel: null, searchList: true, listPlaceholder: "Seleccione Categoría", showDate: false, showInput: false })} />
                                                    <Label for="radioinv1">Por Categoría</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioinv" id="radioinv2" value="option2" onClick={() => this.setState({ optioSearch: "repuestosubicacion", listSel: null, searchList: true, listPlaceholder: "Seleccione Ubicación", showDate: false, showInput: false })} />
                                                    <Label for="radioinv2">Por Ubicación</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioinv" id="radioinv3" value="option3" onClick={() => this.setState({ optioSearch: "repuestosreposicion", listSel: null, searchList: false, listPlaceholder: "", showDate: false, showInput: false })} />
                                                    <Label for="radioinv3">Pendiente de Reposición</Label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {
                                        this.state.reporteSel.value === 2 &&
                                        <FormGroup row>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot0" value="option0" onClick={() => this.setState({ optioSearch: "ordendetalletrabajo", listSel: null, listPlaceholder: "", searchList: false, showDate: false, showInput: true })} />
                                                    <Label for="radioot0">Orden de Trabajo</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot01" value="option01" onClick={() => this.setState({ optioSearch: "ordendetalleservicio", listSel: null, listPlaceholder: "", searchList: false, showDate: false, showInput: true })} />
                                                    <Label for="radioot01">Orden de Servicio</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot1" value="option1" onClick={() => this.setState({ optioSearch: "ordenes", listSel: null, searchList: false, showDate: false, showInput: false, listPlaceholder: "" })} />
                                                    <Label for="radioot1">Todas las Ordenes</Label>
                                                </div>
                                            </Col>
                                            {/* <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot2" value="option2" onClick={() => this.setState({ optioSearch: "ordenestado", listSel: null, searchList: true, showDate: true, showInput: false, listPlaceholder: "Seleccione Estado Actual" })} />
                                                    <Label for="radioot2">Según Estado</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot3" value="option3" onClick={() => this.setState({ optioSearch: "ordenasignadatec", listSel: null, searchList: true, showDate: false, showInput: false, listPlaceholder: "Seleccione Técnico" })} />
                                                    <Label for="radioot3">Asignadas a Técnico</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot03" value="option03" onClick={() => this.setState({ optioSearch: "ordenasignadaprov", listSel: null, searchList: true, showDate: false, showInput: false, listPlaceholder: "Seleccione Proveedor" })} />
                                                    <Label for="radioot03">Asignadas a Proveedor</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="radio">
                                                    <Input type="radio" name="radioot" id="radioot4" value="option4" onClick={() => this.setState({ optioSearch: "ordenactivo", listSel: null, searchList: true, showDate: true, showInput: false, listPlaceholder: "Seleccione Activo" })} />
                                                    <Label for="radioot4">Según Activo</Label>
                                                </div>
                                            </Col> */}
                                        </FormGroup>
                                    }
                                    {
                                        this.state.searchList &&
                                        <FormGroup row>
                                            <Col>
                                                <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                                    additional={defaultAdditional}
                                                    placeholder={this.state.listPlaceholder}
                                                    loadingMessage={() => "Buscando ..."}
                                                    value={this.state.listSel}
                                                    onChange={this.handleChange}
                                                    loadOptions={this.loadOptions}
                                                    onInputChange={this.handleInputChange}
                                                    cacheOptions
                                                    loadOptionsOnMenuOpen={true}
                                                    defaultOptions
                                                    cacheUniqs={[this.state.optioSearch]}
                                                />
                                            </Col>
                                            {this.state.optioSearch === "ordenestado" && <Col>
                                                <Select noOptionsMessage={() => 'Sin Resultados'}
                                                    placeholder={"-- Tipo de Orden --"}
                                                    value={this.state.tipoOrdenSel}
                                                    onChange={(selectedOption) => {
                                                        this.setState({ tipoOrdenSel: selectedOption });
                                                    }}
                                                    options={[
                                                        { label: "Todas", value: 0, tipo1: 1, tipo2: 2 },
                                                        { label: "Orden de Trabajo", value: 1, tipo1: 1, tipo2: 1 },
                                                        { label: "Orden de Servicio", value: 2, tipo1: 2, tipo2: 2 }
                                                    ]}
                                                />
                                            </Col>}
                                        </FormGroup>
                                    }
                                    {
                                        this.state.optioSearch === "clasificacion" && <FormGroup row><Col>
                                            <Select noOptionsMessage={() => 'Sin Resultados'}
                                                placeholder={this.state.listPlaceholder}
                                                value={this.state.listSel}
                                                onChange={this.handleChange}
                                                options={this.state.clasificacionesActivo}
                                            />
                                        </Col></FormGroup>
                                    }
                                    {(this.state.showDate || this.state.reporteSel.value === 4) &&
                                        <FormGroup row>
                                            <Col lg={3}>
                                                <Label>Fecha de Inicio</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control datetimepicker"
                                                    placeholder="Fecha de Inicio"
                                                    onClick={() => this.setState({ isOpen: true })}
                                                    value={this.state.fechaInicio !== "" ? moment(this.state.fechaInicio).format("DD/MM/YYYY") : ""}
                                                />
                                                <div style={{ display: "none" }}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                        <DatePicker
                                                            value={this.state.fechaInicio}
                                                            open={this.state.isOpen}
                                                            onOpen={() => this.setState({ isOpen: true })}
                                                            onClose={() => this.setState({ isOpen: false })}
                                                            onChange={(date) => this.setState({ fechaInicio: date })}
                                                            label="Fecha de Inicio"
                                                            showTodayButton
                                                            cancelLabel={"Cancelar"}
                                                            okLabel={"Aceptar"}
                                                            todayLabel={"HOY"}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <Label>Fecha de Fin</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control datetimepicker"
                                                    placeholder="Fecha de Fin"
                                                    onClick={() => this.setState({ isOpen2: true })}
                                                    value={this.state.fechaFinal !== "" ? moment(this.state.fechaFinal).format("DD/MM/YYYY") : ""}
                                                />
                                                <div style={{ display: "none" }}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                                        <DatePicker
                                                            value={this.state.fechaFinal}
                                                            open={this.state.isOpen2}
                                                            onOpen={() => this.setState({ isOpen2: true })}
                                                            onClose={() => this.setState({ isOpen2: false })}
                                                            onChange={(date) => this.setState({ fechaFinal: date })}
                                                            label="Fecha Final"
                                                            showTodayButton
                                                            cancelLabel={"Cancelar"}
                                                            okLabel={"Aceptar"}
                                                            todayLabel={"HOY"}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {this.state.showInput &&
                                        <FormGroup >
                                            <Col lg={3}>
                                                <Label>No.Orden</Label>
                                                <Input placeholder="000001"
                                                    name="noorden"
                                                    value={this.state.value}
                                                    onChange={(event) => this.setState({ value: event.target.value })}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }
                                    <Button color="success" onClick={() => this.generar()}>Generar</Button>
                                    {!this.state.open ? this.state.show && <IframeResizer
                                        log={false}
                                        src={`${server}/report/${this.state.reportUri}`}
                                        style={{ width: '1px', minWidth: '100%', height: '500px' }}
                                    /> : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </section>
                <ModalAnt
                    title=''
                    centered
                    open={this.state.open}
                    onOk={() => this.setState({ open: false, show: false })}
                    onCancel={() => this.setState({ open: false, show: false })}
                    width={1000}
                    // okText={this.state.loading ? "Cargando..." : "Imprimir todo"}
                    // okButtonProps={{
                    //     icon: this.state.loading ? <Spinner size='sm' /> : <PrinterOutlined style={{display:'inline-flex'}}/>,
                    //     style: { backgroundColor: '#0c7ce6', color: '#fff' },
                    // }}
                    closable={false}
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}
                >
                    <ReporteOrdenTrabajo data={this.state.dataReporte} show={false} handlePrint />
                </ModalAnt>
            </Container>
        )
    }
}