import { server } from './config'
import client from './client'

export default {
    login (data, config = {}) {
		return client.post(`${server}/auth`, data, config)
	},
	checkPassword(data, config = {}){
		return client.post(`${server}/auth/check`, data, config)
	},
	forgotPassword(data, config = {}){
		return client.post(`${server}/auth/reset`, data, config)
	}
}