import React from 'react';
import Container from "../Container";
import { Label, Button, Row, Col, Breadcrumb, BreadcrumbItem, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import Notificaciones from "../modules/notificaciones"
import moment from "moment"

export default class RightPanel extends React.Component {

    componentDidMount() {
        this.getNotifications(1);
    }

    state = {
        data: [],
        totalPages: 0,
        currentPage: 0,
        pages: [],

    }

    getNotifications(page) {
        let notificaciones = [], pages = [], totalPages = 0, currentPage = 0;
        Notificaciones.list({ page }).then((res) => {
            if (res) {
                const data = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (const key in data) {
                    let descripcion = data[key].descripcion !== null ? data[key].descripcion : "";
                    notificaciones.push({
                        id: data[key].notificacionId,
                        description: descripcion,
                        title: data[key].titulo,
                        time: moment().diff(moment(data[key].fecha), 'days') >= 7 ? moment(data[key].fecha).calendar() : moment(data[key].fecha).fromNow(),
                        date: moment(data[key].fecha).format("DD/MM/YYYY"),
                        fromNow: moment(data[key].fecha).fromNow(),
                        icon: data[key].icono
                    })
                }
            }
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data: notificaciones,
                pages,
                currentPage,
                totalPages,
            }, () => this.setLeidos(notificaciones));
        })
    }


    async setLeidos(notificaciones) {
        //marca notificaciones vista como leidas
        for (const key in notificaciones) {
            const id = notificaciones[key].id;
            Notificaciones.update({ notificacionId: id, leido: true });
        }
    }

    render() {
        return (
            <Container >
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={7} md={6} sm={12}>
                                <h2>Notificaciones</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="span">Notificaciones</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-fluid">
                        <Row className="clearfix">
                            <Col sm={12}>
                                {
                                    this.state.data.length === 0 && <Label>
                                        <br /><br />¡No hay notificaciones!
                                    </Label>
                                }
                                <ul className="cbp_tmtimeline">
                                    {
                                        this.state.data.map((value, index) => {
                                            return (<li>
                                                <div className="cbp_tmicon bg-success">
                                                    <i className={"zmdi zmdi-" + value.icon}></i>
                                                </div>
                                                <div className="cbp_tmlabel">
                                                    <div className="cbp_tmtime">
                                                        <span>{value.date}</span>
                                                        <span> {value.fromNow}</span>
                                                    </div>
                                                    <h5>{value.title}</h5>
                                                    <p>{value.description}</p>
                                                </div>
                                            </li>)
                                        })
                                    }
                                </ul>
                            </Col>
                        </Row>
                    </div>
                    <div className="justify-content-center">
                        <Pagination className="pagination pagination-success mt-4">
                            {
                                this.state.pages.map((value, index) => {
                                    return (
                                        <PaginationItem active={value === this.state.currentPage}>
                                            <PaginationLink href="#" onClick={() => this.getNotifications(value)}>{value}</PaginationLink>
                                        </PaginationItem>
                                    );
                                })
                            }
                        </Pagination>
                    </div>
                </section>
            </Container>
        )
    }
}