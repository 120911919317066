import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Col, Button, Breadcrumb, BreadcrumbItem, Alert, Card, FormGroup, Row, Table, Input, Label, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import AssestLocationDetailsComponent from "../components/AssestLocationDetailsComponent";
import AssestLocationMachineDetailsComponent from "../components/AssestLocationMachineDetailsComponent";
import SelectCategoriadisabled from '../components/SelectCategoriadisabled';
import Api from "../modules/activos";
import history from '../history';
import Dropzone from 'react-dropzone'
import { AiOutlineCloudUpload } from 'react-icons/ai';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import moment from "moment";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import Plan from "../modules/plan";
import { TIPO_MANTENIMIENTO } from "../constants";
import { PrinterOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ReporteOrdenTrabajo from '../Order/reporte/ReporteOrdenTrabajo';
import { Modal as ModalAnt, Image } from 'antd';

const ordenEstado = [
    "Abierta",
    "En Progreso",
    "Completada",
    "No Completada"
]

export default class CreateAssestBuildings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            selectid: "0",
            title: "Activo",
            categoriaActivos: [
                { value: "1", label: "Localización o Instalación" },
                { value: "2", label: "Equipos o Herramientas" },
            ],
            tipoActivos: [],
            unidadesMedicion: [],
            unidad: "",
            categoriasLocalizacion: [],
            nombre: "",
            categoria: [],
            descripcion: "",
            codigo: "",
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            editing: false,
            activoId: undefined,
            localizacionId: undefined,
            modelo: "",
            marca: "",
            numero: "",
            codigoBarra: "",
            esEquipo: true,
            optionDefault: "optionB",
            localizacion: null,
            usuarioAsignado: null,
            files: [],
            oldFiles: [],
            categoriaLocalizacion: null,
            localizacionChecked: false,
            tipoActivo: null,
            esHijo: false,
            activoPadre: null,
            activoPadreError: "none",
            fueraServicio: 0,
            valorLectura: "",
            tabSelect: "1",
            planesActivos: [],
            confirm: false,
            historialOrdenes: [],
            state: "",
            tareasPopup: false,
            actividadesList: [],
            clasificaciones: [],
            clasificacion: null,
            seccion: '',
            lugar: '',
            posicion: '',
            edit: false,
            open: false,
            dataReporte: {},
            scaleStep: 0.5,
            visibleImage: false,
            categoriaSel: { value: "1", label: "Localización o Instalación" }
        }
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        let userRol = (localStorage.getItem('user_rol'));
        if (userRol === "3") {
            this.setState({ disabled: true });
        }
        const state = this?.props?.location?.state
        if (state?.e) {
            this.setState({ edit: this.props.location.state.e });
        }
        this.setState({ state });
        this.setState({
            selectid: "1",
            categoriaSel: { value: "1", label: "Localización o Instalación" }
        })

        this.listTiposActivosPath();
        this.getCategoriasLocalizacion();
        this.getUnidadesMedicion();
        this.getClasificaciones();
        if (state && (state.id !== "0")) {
            this.getUserData(state.id, 1);
        }
    }

    // async getTipoActivos() {
    //     let tipoActivos = []
    //     Api.listTiposActivos().then((response) => {
    //         console.log("🚀 ~ file: CreateaAssest.js:123 ~ Api.listTiposActivos ~ response", response)
    //         if (response) {
    //             for (let key in response) {
    //                 tipoActivos.push({
    //                     value: response[key].tipoActivoId,
    //                     label: response[key].descripcion
    //                 })
    //             }
    //         }
    //     }).finally(() => {
    //         this.setState({ tipoActivos, loading: false })
    //     })
    // }

    async listTiposActivosPath() {
        let tipoActivos = []
        Api.listTiposActivosPath().then((response) => {
            // console.log("🚀 ~ file: CreateaAssest.js:141 ~ Api.listTiposActivosPath ~ response", response)
            if (response) {
                for (let key in response) {
                    tipoActivos.push({
                        value: response[key].id,
                        label: response[key].path
                    })
                }
            }
        })
            .finally(() => {
                this.setState({ tipoActivos, loading: false })
            })
    }

    async getClasificaciones() {
        let clasificaciones = []
        Api.listClasificaciones().then((response) => {
            if (response) {
                for (let key in response) {
                    clasificaciones.push({
                        value: response[key].clasificacionActivoId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ clasificaciones, loading: false })
            // console.log("🚀 ~ file: CreateaAssest.js:145 ~ CreateAssest ~ Api.listClasificaciones ~ clasificaciones", clasificaciones)
        })
    }

    async getUnidadesMedicion() {
        let unidadesMedicion = [];
        Api.listUnidadesMedicion().then((response) => {
            if (response) {
                for (let key in response) {
                    unidadesMedicion.push({
                        value: response[key].unidadMedicionId,
                        label: `${response[key].descripcion} (${response[key].simbolo})`,
                        simbolo: response[key].simbolo
                    })
                }
            }
        }).finally(() => {
            this.setState({ unidadesMedicion, loading: false })
        })
    }

    async getCategoriasLocalizacion() {
        let categoriasLocalizacion = []
        Api.listCategoriasLocalizacion().then((response) => {
            if (response) {
                for (let key in response) {
                    categoriasLocalizacion.push({
                        value: response[key].categoriaLocalizacionId,
                        label: response[key].descripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ categoriasLocalizacion, loading: false })
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectid: selectedOption.value, categoriaSel: selectedOption },
            () => this.printTitle()
        );

    };

    //eslint-disable-next-line
    handleChangeClasificacion = selectedOption => {
        this.setState({ clasificacion: selectedOption.value });
    }

    printAssetsDetail = () => {
        const { selectid } = this.state
        //const value = selectid.value;
        if (selectid === "1") {
            return <AssestLocationDetailsComponent
                key={this.state.localizacionId}
                ref={(ref) => this.AssestLocationDetail = ref}
                selected={this.state.localizacion}
                option={this.state.optionDefault}
                onChange={(selectedOption) => this.setState({ localizacion: selectedOption })}
                onCheckedChange={(checked) => this.setState({ localizacionChecked: checked })}
                currentLocalizacion={this.state.localizacionId}
                edit={this.state.edit}
            />
        }
        if (selectid === "2") {
            return <div>
                <Card >
                    <div className="header">
                        <h2><strong>Datos</strong> del Activo</h2>
                    </div>
                    <div className="body">
                        <Row className="clearfix">
                            <Col lg={4} md={6}>
                                <FormGroup >
                                    <label>Modelo</label>
                                    <AvField name="modelo"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.marcaRef.focus() }}
                                        innerRef={e => this.modeloRef = e}
                                        type="text"
                                        value={this.state.modelo}
                                        onChange={(event) => this.setState({ modelo: event.target.value })}
                                        className="form-control"
                                        placeholder="Modelo"
                                        validate={{
                                            required: { value: false, errorMessage: '' },
                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El modelo debe contener solo letras y numeros' },
                                            maxLength: { value: 50, errorMessage: 'El modelo debe tener menos de 50 caracteres' }
                                        }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Marca</label>
                                    <AvField name="marca"
                                        type="text"
                                        value={this.state.marca}
                                        onChange={(event) => this.setState({ marca: event.target.value })}
                                        className="form-control"
                                        placeholder="Marca"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.numeroRef.focus() }}
                                        innerRef={e => this.marcaRef = e}
                                        validate={{
                                            required: { value: false, errorMessage: '' },
                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'La marca debe contener solo letras y numeros' },
                                            maxLength: { value: 50, errorMessage: 'La marca debe tener menos de 50 caracteres' }
                                        }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Número</label>
                                    <AvField name="numero"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.codigoBarraRef.focus() }}
                                        innerRef={e => this.numeroRef = e}
                                        type="text"
                                        value={this.state.numero}
                                        onChange={(event) => this.setState({ numero: event.target.value })}
                                        className="form-control"
                                        placeholder="Número de serie"
                                        validate={{
                                            required: { value: false, errorMessage: '' },
                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El numero de serie debe contener solo letras y numeros' },
                                            maxLength: { value: 50, errorMessage: 'El numero de serie debe tener menos de 50 caracteres' }
                                        }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Código</label>
                                    <AvField name="codigoBarra"
                                        //onKeyUp={(event)=> {if(event.keyCode === 13) this.unidadRef.focus()}} 
                                        innerRef={e => this.codigoBarraRef = e}
                                        type="text"
                                        value={this.state.codigoBarra}
                                        onChange={(event) => this.setState({ codigoBarra: event.target.value })}
                                        className="form-control"
                                        placeholder="Código de barras"
                                        validate={{
                                            required: { value: false, errorMessage: '' },
                                            pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El códgo de barra debe contener solo letras y numeros' },
                                            maxLength: { value: 50, errorMessage: 'El códgo de barra debe tener menos de 50 caracteres' }
                                        }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Valor de Medición</label>
                                    <AvField name="valorMedicion"
                                        innerRef={e => this.valorLecturaRef = e}
                                        type="text"
                                        value={this.state.valorLectura}
                                        onChange={(event) => this.setState({ valorLectura: event.target.value })}
                                        className="form-control"
                                        placeholder="Valor de Medición"
                                        disabled={this.state.edit}
                                    >
                                    </AvField>
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Unidad de Medición</label>
                                    <Select
                                        noOptionsMessage={() => 'Sin Resultados'}
                                        ref={ref => { this.unidadesMedicionRef = ref; }}
                                        placeholder={"-- Unidad de Medición --"}
                                        value={this.state.unidad}
                                        onChange={(selectedOption) => {
                                            this.setState({ unidad: selectedOption });
                                        }}
                                        options={this.state.unidadesMedicion}
                                        // onBlur={()=> {this.state.unidad===null ? this.setState({unidadError: "block"}) : this.setState({unidadError: "none"})}}
                                        isDisabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup >
                                    <label>Sección</label>
                                    <AvField name="seccion"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.marcaRef.focus() }}
                                        innerRef={e => this.modeloRef = e}
                                        type="text"
                                        value={this.state.seccion}
                                        onChange={(event) => this.setState({ seccion: event.target.value })}
                                        className="form-control"
                                        placeholder="Sección"
                                        // validate={{
                                        //     required: { value: false, errorMessage: '' },
                                        //     pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El modelo debe contener solo letras y numeros' },
                                        //     maxLength: { value: 50, errorMessage: 'El modelo debe tener menos de 50 caracteres' }
                                        // }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup>
                                    <label>Lugar</label>
                                    <AvField name="lugar"
                                        type="text"
                                        value={this.state.lugar}
                                        onChange={(event) => this.setState({ lugar: event.target.value })}
                                        className="form-control"
                                        placeholder="Lugar"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.numeroRef.focus() }}
                                        innerRef={e => this.marcaRef = e}
                                        // validate={{
                                        //     required: { value: false, errorMessage: '' },
                                        //     pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'La marca debe contener solo letras y numeros' },
                                        //     maxLength: { value: 50, errorMessage: 'La marca debe tener menos de 50 caracteres' }
                                        // }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={6}>
                                <FormGroup >
                                    <label>Posición</label>
                                    <AvField name="posicion"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.marcaRef.focus() }}
                                        innerRef={e => this.modeloRef = e}
                                        type="text"
                                        value={this.state.posicion}
                                        onChange={(event) => this.setState({ posicion: event.target.value })}
                                        className="form-control"
                                        placeholder="Posición"
                                        // validate={{
                                        //     required: { value: false, errorMessage: '' },
                                        //     pattern: { value: '^[A-z0-9À-ž .,:-]+$', errorMessage: 'El modelo debe contener solo letras y numeros' },
                                        //     maxLength: { value: 50, errorMessage: 'El modelo debe tener menos de 50 caracteres' }
                                        // }}
                                        disabled={this.state.edit}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <AssestLocationMachineDetailsComponent
                    key={this.state.activoId}
                    ref={(ref) => this.AssestLocationMachineDetail = ref}
                    selected={this.state.localizacion}
                    onChange={(selectedOption) => this.setState({ localizacion: selectedOption })}
                    onActivoChange={(selectedOption) => this.setState({ activoPadre: selectedOption })}
                    onChangeUser={(selectedOption) => this.setState({ usuarioAsignado: selectedOption })}
                    esEquipo={(esEquipo) => this.setState({ esEquipo })}
                    esHijo={(esHijo) => this.setState({ esHijo })}
                    hijo={this.state.esHijo ? "optionB" : "optionA"}
                    option={this.state.esEquipo ? "optionA" : "optionB"}
                    userSel={this.state.usuarioAsignado}
                    activoPadre={this.state.activoPadre}
                    currentActivo={this.state.activoId}
                    edit={this.state.edit}
                />
            </div>
        }
        return null;
    };

    printPicker = () => {
        const { selectid, categoriasLocalizacion, tipoActivos } = this.state
        if (selectid === "0") {
            return <FormGroup><SelectCategoriadisabled /></FormGroup>
        }
        if (selectid === "1") {
            return <FormGroup>
                <Select

                    noOptionsMessage={() => 'Sin Resultados'}
                    styles={{
                        container: base => ({
                            ...base,
                            border: this.state.catLocalizacionError === "block" ? "1px solid #ced4da" : "none",
                            borderRadius: ".25rem",
                            borderColor: "#dc3545"
                        }),
                    }}
                    ref={ref => { this.categoriaLocalizacion = ref; }}
                    placeholder={"-- Categoria Localización --"}
                    value={this.state.categoriaLocalizacion}
                    onChange={(selectedOption) => {
                        this.setState({ categoriaLocalizacion: selectedOption });
                    }}
                    options={categoriasLocalizacion}
                    onBlur={() => { this.state.categoriaLocalizacion === null ? this.setState({ catLocalizacionError: "block" }) : this.setState({ catLocalizacionError: "none" }) }}
                    isDisabled={this.state.edit}
                />
                <div style={{ display: this.state.catLocalizacionError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la categoria de la localización</div>
            </FormGroup>
        } else {
            return <FormGroup>
                <Select noOptionsMessage={() => 'Sin Resultados'}
                    styles={{
                        container: base => ({
                            ...base,
                            border: this.state.tipoActivoError === "block" ? "1px solid #ced4da" : "none",
                            borderRadius: ".25rem",
                            borderColor: "#dc3545"
                        }),
                    }}
                    ref={ref => { this.tipoActivoRef = ref; }}
                    placeholder={"-- Tipo de Activo --"}
                    value={this.state.tipoActivo}
                    onChange={(selectedOption) => {
                        this.setState({ tipoActivo: selectedOption });
                    }}
                    options={tipoActivos}
                    onBlur={() => { this.state.tipoActivo === null ? this.setState({ tipoActivoError: "block" }) : this.setState({ tipoActivoError: "none" }) }}
                    isDisabled={this.state.edit}
                />
                <div style={{ display: this.state.tipoActivoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el tipo de activo</div>
            </FormGroup>
        }
    }

    printTitle = () => {
        const { selectid } = this.state
        if (selectid === "1") {
            this.setState({ title: "Localización o Instalación" })
        }
        if (selectid === "2") {
            this.setState({ title: "Equipos o Herramientas" })
        }
    }

    onFile = (acceptedFiles) => {
        //add new files to array
        this.setState({
            files: [...this.state.files, ...acceptedFiles]
        })


    }

    onDrop = (acceptedFiles) => {
        this.setState({
            preview: URL.createObjectURL(acceptedFiles[0]),
            file: acceptedFiles[0]
        })
        this.dropzone.className = "file-wrapper has-preview"
    }

    removeImage = () => {
        this.setState({
            preview: "",
            file: null
        })
        this.dropzone.className = "file-wrapper"
    }

    save = () => {
        if (this.state.disabled) {
            alert("Imposible guardar")
        } else {
            this.setState({ disabled: true, isLoading: true })
            const { file, selectid } = this.state
            if (file) {
                //upload picture test
                const data = new FormData();
                data.append('picture', file);
                Api.upload(data).then((response) => {
                    if (response && response.success) {
                        if (selectid === "1")
                            this.saveData(response.donwloadUrl);
                        if (selectid === "2")
                            this.saveActivo(response.donwloadUrl);
                    } else {
                        this.setState({ error: true, disabled: false })
                    }
                })
            } else {
                if (selectid === "1")
                    this.saveData("");
                if (selectid === "2")
                    this.saveActivo("");
            }
        }

    }

    saveData = async (imageUri) => {
        const { codigo, nombre, descripcion, categoriaLocalizacion, localizacion, files, localizacionChecked, fueraServicio } = this.state
        let data = {
            codigo,
            nombre,
            descripcion,
            fotoUri: imageUri,
            categoriaLocalizacion: {
                categoriaLocalizacionId: categoriaLocalizacion.value,
                descripcion: categoriaLocalizacion.label
            },
            localizacion: !localizacionChecked ? null : {
                localizacionId: localizacion.value,
                localizacionDescripcion: localizacion.descripcion
            },
            fueraServicio,
            tieneHijos: localizacionChecked //eshijo
        }
        if (files.length > 0) {
            let activoFiles = [];
            for (const key in files) {
                //upload picture test
                const file = new FormData();
                file.append('picture', files[key]);
                const res = await Api.upload(file);
                if (res && res.success) {
                    activoFiles.push({
                        descripcion: files[key].name,
                        fileUri: res.donwloadUrl,
                        tipoArchivo: files[key].type,
                        fecha: moment().format("YYYY-MM-DD")
                    })
                }
            }
            data.activosFiles = activoFiles;
        }
        Api.saveLocalizacion(data).then((response) => {
            if (response && response.localizacionId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    saveActivo = async (imageUri) => {
        const { tipoActivo, codigo, nombre, descripcion, localizacion, marca, modelo, numero, codigoBarra, esEquipo, usuarioAsignado, files, assignTool, activoPadre, esHijo, fueraServicio, valorLectura, unidad, clasificacion, lugar, seccion, posicion } = this.state
        let data = {
            codigo,
            nombre,
            descripcion,
            marca,
            modelo,
            numeroSerie: numero,
            codigoBarra: codigoBarra,
            esEquipo,
            esHerramienta: !esEquipo,
            fueraServicio,
            fotoUri: imageUri,
            localizacion: esHijo ? activoPadre.localizacion : !localizacion ? null : {
                localizacionId: localizacion.value,
                localizacionDescripcion: localizacion.label
            },
            tiposActivo: {
                tipoActivoId: tipoActivo.value,
                descripcion: tipoActivo.label
            },
            usuarioAsignado: !esEquipo ? !assignTool ? null : {
                usuarioId: usuarioAsignado.value
            } : null,
            esHijo,
            activoPadre: esHijo ? {
                activoId: activoPadre.value
            } : null,
            unidadMedicion: !unidad ? null : {
                unidadMedicionId: unidad.value
            },
            unidad: !unidad ? "" : unidad.simbolo,
            valorLectura: !unidad ? "0" : valorLectura,
            clasificacionActivos: !clasificacion ? null : {
                clasificacionActivoId: clasificacion.value
            },
            lugar,
            seccion,
            posicion
        }
        if (files.length > 0) {
            let activoFiles = [];
            for (const key in files) {
                //upload picture test
                const file = new FormData();
                file.append('picture', files[key]);
                const res = await Api.upload(file);
                if (res && res.success) {
                    activoFiles.push({
                        descripcion: files[key].name,
                        fileUri: res.donwloadUrl,
                        tipoArchivo: files[key].type,
                        fecha: moment().format("YYYY-MM-DD")
                    })
                }
            }
            data.activosFiles = activoFiles;
        }
        Api.save(data).then((response) => {
            if (response && response.activoId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    update = () => {
        if (this.state.disabled) {
            alert("Imposible guardar")
        } else {
            this.setState({ disabled: true, isLoading: true })
            const { file, selectid } = this.state
            console.log(file)
            if (file) {
                //upload picture test
                const data = new FormData();
                data.append('picture', file);
                Api.upload(data).then((response) => {
                    if (response && response.success) {
                        if (selectid === "1")
                            this.updateLocalizacion(response.donwloadUrl);
                        if (selectid === "2")
                            this.updateActivo(response.donwloadUrl);
                    } else {
                        this.setState({ error: true, disabled: false })
                    }
                })
            } else {
                if (selectid === "1")

                    this.updateLocalizacion("");
                if (selectid === "2")
                    console.log("updateActivo")
                this.updateActivo("");
            }
        }

    }

    updateLocalizacion = async (imageUri) => {
        const { preview, localizacionId, codigo, nombre, descripcion, categoriaLocalizacion, localizacion, files, fueraServicio, localizacionChecked } = this.state
        let data = {
            localizacionId,
            codigo,
            nombre,
            descripcion,
            categoriaLocalizacion: {
                categoriaLocalizacionId: categoriaLocalizacion?.value,
                descripcion: categoriaLocalizacion?.label
            },
            localizacion: !localizacionChecked ? null : {
                localizacionId: localizacion.value,
                localizacionDescripcion: localizacion.descripcion
            },
            fueraServicio,
            tieneHijos: localizacionChecked, //eshijo
            fotoUri: imageUri !== "" ? imageUri : preview
        }
        if (files.length > 0) {
            let activoFiles = [];
            for (const key in files) {
                //upload picture test
                const file = new FormData();
                file.append('picture', files[key]);
                const res = await Api.upload(file);
                if (res && res.success) {
                    activoFiles.push({
                        descripcion: files[key].name,
                        fileUri: res.donwloadUrl,
                        tipoArchivo: files[key].type,
                        fecha: moment().format("YYYY-MM-DD")
                    })
                }
            }
            data.activosFiles = activoFiles;
        }
        Api.updateLocalizacion(data).then((response) => {
            if (response && response.localizacionId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                    disabled: false
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    updateActivo = async (imageUri) => {
        const { clasificacion, preview, activoId, tipoActivo, codigo, nombre, descripcion, localizacion, marca, modelo, numero, codigoBarra, esEquipo, usuarioAsignado, files, esHijo, activoPadre, fueraServicio, unidad, valorLectura, lugar, posicion, seccion } = this.state
        const data = {
            activoId,
            codigo,
            nombre,
            descripcion,
            marca,
            modelo,
            numeroSerie: numero,
            codigoBarra: codigoBarra,
            esEquipo,
            fueraServicio,
            esHerramienta: !esEquipo,
            localizacion: esHijo ? activoPadre.localizacion : !localizacion ? null : {
                localizacionId: localizacion.value,
                localizacionDescripcion: localizacion.label
            },
            tiposActivo: {
                tipoActivoId: tipoActivo?.value,
                descripcion: tipoActivo?.label
            },
            usuarioAsignado: !esEquipo ? !usuarioAsignado ? null : {
                usuarioId: usuarioAsignado.value
            } : null,
            esHijo,
            activoPadre: esHijo ? {
                activoId: activoPadre.value
            } : null,
            unidadMedicion: !unidad ? null : {
                unidadMedicionId: unidad.value
            },
            unidad: !unidad ? "" : unidad.simbolo,
            valorLectura: !unidad ? "0" : valorLectura,
            fotoUri: imageUri !== "" ? imageUri : preview,
            clasificacionActivos: !clasificacion ? null : {
                clasificacionActivoId: clasificacion.value
            },
            lugar,
            posicion,
            seccion,
        }

        if (files.length > 0) {
            let activoFiles = [];
            for (const key in files) {
                //upload picture test
                const file = new FormData();
                file.append('picture', files[key]);
                const res = await Api.upload(file);
                if (res && res.success) {
                    activoFiles.push({
                        descripcion: files[key].name,
                        fileUri: res.donwloadUrl,
                        tipoArchivo: files[key].type,
                        fecha: moment().format("YYYY-MM-DD")
                    })
                }
            }
            data.activosFiles = activoFiles;
        }
        Api.update(data).then((response) => {
            if (response && response.activoId > 0) {
                this.setState({
                    visible: true,
                    message: response.message,
                    disabled: false
                })
            } else {
                if (response.warning) {
                    this.setState({
                        warning: true,
                        message: response.message,
                        disabled: false
                    })
                } else {
                    this.setState({ error: true, disabled: false })
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    getUserData(id, type) {
        this.setState({ loading: true })
        let oldFiles = []
        Api.getLocalizacion({ id }).then((response) => {
            if (response) {
                for (const key in response.activosFiles) {
                    oldFiles.push({
                        name: response.activosFiles[key].descripcion,
                        type: response.activosFiles[key].tipoArchivo,
                        fileUri: response.activosFiles[key].fileUri,
                    })
                }
                this.setState({
                    selectid: "1",
                    title: "Localización o Instalación",
                    nombre: response.nombre,
                    descripcion: response.descripcion,
                    codigo: response.codigo,
                    loading: true,
                    activoId: undefined,
                    preview: response.fotoUri,
                    localizacionId: response.localizacionId,
                    categoriaLocalizacion: {
                        label: response.categoriaLocalizacion.descripcion,
                        value: response.categoriaLocalizacion.categoriaLocalizacionId
                    },
                    localizacion: response.localizacion ? {
                        label: response.localizacion.nombre + " [" + response.localizacion.codigo + "]",
                        value: response.localizacion.localizacionId
                    } : null,
                    optionDefault: response.localizacion ? "optionA" : "optionB",
                    modelo: "",
                    marca: "",
                    numero: "",
                    codigoBarra: "",
                    esEquipo: false,
                    editing: true,
                    oldFiles,
                    fueraServicio: response.fueraServicio,
                })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    printOrder = (item) => {
        // let width = 950;
        // let height = (window.screen.height / 1.2);
        // let left = (window.screen.width / 2) - (width / 2) + 50;
        // let top = (window.screen.height / 2) - (height / 2) - 25;
        // let specs = `width=900,height=${height.toString()},left=${left.toString()},top=${top.toString()}scrollbars=yes,status=yes`;
        // window.open(`${server}/report/orden/${item.ordenTrabajoId}`, "_blank")
        // let activo_id = item.activo.activo_id
        // let plan_id = item?.planMantenimiento?.planMantenimientoId
        // let ordenTrabajoId = item.ordenTrabajoId
        // let tipoOT = item.tipoMantenimiento
        // let fechaOT = item.fechaInicio
        let data = {
            ordenTrabajoId: item.ordenTrabajoId,
            activo_id: item.activo.activo_id,
            plan_id: item?.planMantenimiento?.planMantenimientoId,
            tipoOT: item.tipoMantenimiento,
            fechaOT: item.fechaInicio
        }
        this.setState({ dataReporte: data, open: true })
        // window.open(`/reporteot?activo_id=${activo_id}&ordenTrabajoId=${ordenTrabajoId}&plan_id=${plan_id}&tipo_ot=${tipoOT}&fecha=${fechaOT}`, '_blank', specs)
        // history.push({
        //     pathname: '/reporteot',
        //     state: {
        //         data: {
        //             ...item,
        //             ordenTrabajoId: item.ordenTrabajoId,
        //         },
        //     }
        // })
    }

    displayTasks = (list, ordenID) => {
        this.setState({
            tareasPopup: true,
            numOrder: ordenID,
            actividadesList: list,
        })
    }

    validate() {
        //validate selects
        const { selectid, categoriaLocalizacion, tipoActivo, clasificacion } = this.state;
        let localizacioError, asignarError, assignTool = false, activoPadreError, clasificacionActivoError;
        switch (selectid) {
            case "0":
                localizacioError = "none";
                asignarError = "none;"
                activoPadreError = "none";
                clasificacionActivoError = "none";
                break;
            case "1":
                localizacioError = this.AssestLocationDetail.validate();
                asignarError = "none;"
                activoPadreError = "none";
                clasificacionActivoError = "none";
                break;
            case "2":
                const validate = this.AssestLocationMachineDetail.validate();
                localizacioError = validate[0];
                asignarError = validate[1];
                assignTool = validate[2];
                activoPadreError = validate[3];
                clasificacionActivoError = clasificacion === null ? "block" : "none"
                break;
            default:
                break;
        }

        this.setState({
            catLocalizacionError: categoriaLocalizacion === null ? "block" : "none",
            tipoActivoError: tipoActivo === null ? "block" : "none",
            clasificacionActivoError,
            localizacioError,
            asignarError,
            assignTool,
            activoPadreError
        },
            () => this.form.submit())
    }

    handleSubmit(event, errors, values) {
        console.log('submit')
        //validate select
        // eslint-disable-next-line
        let { selectid, catLocalizacionError, localizacioError, tipoActivoError, asignarError, editing, activoPadreError, clasificacionActivoError } = this.state;
        if (selectid === "1") { tipoActivoError = "none"; asignarError = "none"; clasificacionActivoError = "none"; }
        if (selectid === "2") catLocalizacionError = "none";
        // if (catLocalizacionError === "block" && localizacioError === "none" && tipoActivoError === "none" && asignarError === "none" && activoPadreError === "none" && clasificacionActivoError === "block") {
        if (catLocalizacionError === "none" && localizacioError === "none" && tipoActivoError === "none" && asignarError === "none" && activoPadreError === "none") {
            console.log('submit 2')
            editing ? this.update() : this.save()
        }
    }

    handleInvalidSubmit(event, errors, values) {

    }

    reset = () => {
        this.form.reset();
        this.setState({
            unidad: "",
            nombre: "",
            descripcion: "",
            codigo: "",
            disabled: false,
            editing: false,
            activoId: undefined,
            localizacionId: undefined,
            modelo: "",
            marca: "",
            numero: "",
            codigoBarra: "",
            esEquipo: true,
            optionDefault: "optionB",
            localizacion: null,
            usuarioAsignado: null,
            files: [],
            categoriaLocalizacion: null,
            localizacionChecked: false,
            tipoActivo: null,
            esHijo: false,
            activoPadre: null,
            activoPadreError: "none",
            fueraServicio: 0,
            valorLectura: "",
            clasificacionActivoError: "none",
            preview: null,
        }, () => window.scrollTo(0, 0))
    }

    showDialog(id, status) {
        this.setState({
            confirm: true,
            planId: id,
            confirmMsg: status < 5 ? "Desactivar Plan" : "Activar Plan"
        })
    }

    deactivatePlan = () => {
        const { planId, confirmMsg } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(planId)
        Plan.update({
            planMantenimientoId: planId,
            status: (confirmMsg === "Desactivar Plan" ? 5 : 1)
        }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.llenarPlanesActivo())
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    llenarPlanesActivo = () => {
        const { activoId } = this.state;
        Api.getPlanes({ activoId }).then((result) => {
            if (result) {
                this.setState({ planesActivos: result })
            }
        })
    }

    reloadPage = () => {
        this.setState({
            visible: false,
            error: false,
            warning: false,
        }, () => this.reset())
        //reload page
        // window.location.reload();
    }

    showConfirm = (file) => {

        const { confirm } = ModalAnt;
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: '¿Está seguro de eliminar el archivo?',
            onOk: () => {
                this.removeFile(file);
            },
            onCancel: () => {
                console.log('Cancel');
            },
        });
    }

    removeFile = (file) => {
        if (file.activoFileId) {
            Api.removeFile({ fileActivoId: file.activoFileId }).then((response) => {
                if (response) {
                    //updte oldfiles
                    const { oldFiles } = this.state;
                    const newFiles = oldFiles.filter((item) => item.activoFileId !== file.activoFileId);
                    this.setState({
                        visible: response.success,
                        message: 'Archivo eliminado',
                        oldFiles: newFiles
                    })
                } else {
                    this.setState({
                        error: true,
                        message: 'Error al eliminar el archivo',
                    })
                }
            })
        } else {
            const { files } = this.state;
            const newFiles = files.filter((item) => item.name !== file.name);
            this.setState({
                files: newFiles
            })
        }
    }

    render() {
        const { categoriaActivos, loading } = this.state
        if (loading) return <Loading />;
        return (
            <Container menuId="activos" >
                <section className="content">
                    <div className="block-header">
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h2>Crear Localización o Instalación</h2>
                                {/* <h2>Crear {title}</h2> */}
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Activos</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" active tag="span">Crear Localización o Instalación</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"><i className="zmdi zmdi-sort-amount-desc" /></Button>
                            </Col>
                        </Row>
                    </div>
                    <AvForm
                        onValidSubmit={() => this.handleSubmit()}
                        ref={e => this.form = e}
                        onInvalidSubmit={() => this.handleInvalidSubmit()}>
                        <Card >
                            <div className="header">
                                <h2><strong>Seleccione</strong> una categoria</h2>
                            </div>
                            <div className="body">
                                <Col sm={12}>
                                    <FormGroup>
                                        <label>Seleccione categoria de activo:</label>
                                        <Select
                                            noOptionsMessage={() => 'Sin Resultados'}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    border: this.state.categoriaActivoError === "block" ? "1px solid #ced4da" : "none",
                                                    borderRadius: ".25rem",
                                                    borderColor: "#dc3545"
                                                }),
                                            }}
                                            placeholder={"--  Categoria activos --"}
                                            value={this.state.categoriaSel}
                                            onChange={this.handleChange}
                                            options={categoriaActivos}
                                            ref={ref => { this.categoriaActivoRef = ref; }}
                                            onBlur={() => { this.state.categoriaSel === null ? this.setState({ categoriaActivoError: "block" }) : this.setState({ categoriaActivoError: "none" }) }}
                                            isDisabled={true}
                                        />
                                        <div style={{ display: this.state.categoriaActivoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la categoría del Activo</div>
                                    </FormGroup>
                                </Col>
                            </div>
                        </Card>
                        <Card >
                            <div className="header">
                                <h2><strong>Datos</strong> Básicos</h2>
                            </div>
                            <div className="body">
                                <Row className="clearfix">
                                    <Col sm={4}>
                                        <label>Agregar imagen</label>
                                        <Dropzone onDrop={this.onDrop} multiple={false} accept="image/*" disabled={this.state.edit} onClick={() => this.setState({ visible: true })}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="file-wrapper" ref={e => this.dropzone = e} style={{ height: 280 }}>
                                                    <div {...getRootProps({ className: 'file-message' })}>
                                                        <AiOutlineCloudUpload size={60} color={"#CCC"} />
                                                        <p>Arrastra la imagen o hacer click aqui</p>
                                                    </div>
                                                    <input {...getInputProps()} className={"form-control-file"} />
                                                    <Button className="file-clear" onClick={() => this.removeImage()} disabled={this.state.edit}>Quitar</Button>
                                                    <div className="file-preview" style={{ display: this.state.preview && this.state.preview !== "" ? "flex" : "none", justifyContent: 'center' }} onClick={() => this.setState({ visibleImage: true })}>
                                                        <Image
                                                            src={this.state.preview}
                                                            alt=""
                                                            width={250}
                                                            preview={{
                                                                visible: this.state.visibleImage,
                                                                scaleStep: 0.5,
                                                                src: this.state.preview,
                                                                onVisibleChange: (value) => {
                                                                    this.setState({ visibleImage: value });
                                                                },
                                                            }}
                                                        />
                                                        <div className="file-infos">
                                                            <div className="file-infos-inner">
                                                                {/* <p className="file-filename"><span className="file-icon"></span> <span className="file-filename-inner">1caf54794d2ce9c62f00ee45f4f9d963.jpg</span></p> */}
                                                                <p className="file-infos-message">hacer click para hacer zoom</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        {/* <Button onClick={()=>this.setState({visible:true})} className="file-preview-btn">Ver imagen</Button> */}

                                    </Col>
                                    <Col sm={8}  >
                                        <FormGroup>
                                            <label>Nombre</label>
                                            <AvField name="nombre"
                                                onKeyUp={(event) => { if (event.keyCode === 13) this.codigoRef.focus() }}
                                                innerRef={e => this.nombreRef = e}
                                                type="text"
                                                value={this.state.nombre}
                                                onChange={(event) => this.setState({ nombre: event.target.value })}
                                                className="form-control"
                                                placeholder="Nombre"
                                                validate={{
                                                    required: { value: true, errorMessage: 'Ingrese un nombre para el activo' },
                                                    pattern: { value: '^[A-z0-9À-ž ]+$', errorMessage: 'El nombre debe contener solo letras y numeros' },
                                                    maxLength: { value: 50, errorMessage: 'El nombre debe tener menos de 50 caracteres' }
                                                }}
                                                disabled={this.state.edit}
                                            />
                                        </FormGroup>
                                        <div className="row clearfix">
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <label>Codigo</label>
                                                    <AvField name="codigo"
                                                        onKeyUp={(event) => { if (event.keyCode === 13) this.codigoRef.focus() }}
                                                        innerRef={e => this.codigoRef = e}
                                                        type="text"
                                                        value={this.state.codigo}
                                                        onChange={(event) => this.setState({ codigo: event.target.value })}
                                                        className="form-control"
                                                        placeholder="Codigo"
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Ingrese un código para el activo' },
                                                            pattern: { value: '^[A-z0-9 -]+$', errorMessage: 'El código debe contener solo letras y numeros' },
                                                            maxLength: { value: 15, errorMessage: 'El código debe tener menos de 15 caracteres' }
                                                        }}
                                                        disabled={this.state.edit}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <label>Categoria</label>
                                                {this.printPicker()}
                                            </Col>
                                        </div>
                                        <div className="form-line">
                                            <label>Descripción</label>
                                            <AvField name="descripcion"
                                                type="textarea"
                                                rows={3}
                                                value={this.state.descripcion}
                                                onChange={(event) => this.setState({ descripcion: event.target.value })}
                                                className="form-control"
                                                placeholder="Descripción"
                                                validate={{
                                                    required: { value: false, errorMessage: '' },
                                                    // eslint-disable-next-line
                                                    pattern: { value: '/^[A-z0-9À-ž .()-_\s]+$/m', errorMessage: 'La descripción debe contener solo letras y numeros' },
                                                    maxLength: { value: 150, errorMessage: 'La descripción debe tener menos de 150 caracteres' }
                                                }}
                                                disabled={this.state.edit}
                                            />
                                        </div>
                                        <Col className="p-0">
                                            <div className="checkbox" >
                                                <Input id="enable" type="checkbox" checked={this.state.fueraServicio} onChange={() => this.setState({ fueraServicio: !this.state.fueraServicio })} disabled={this.state.edit} />
                                                <Label htmlFor="enable">¿Fuera de Servicio?</Label>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                        {this.printAssetsDetail()}
                        <Card>
                            <div className="header">
                                <h2><strong>Archivos</strong> del activo</h2>
                            </div>
                            <div className="body">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.tabSelect === "1" ? "active" : ''}
                                            onClick={() => { this.setState({ tabSelect: "1" }) }}
                                        >
                                            Documentos
                                        </NavLink>
                                    </NavItem>
                                    {this.state.selectid === "2" &&
                                        <NavItem>
                                            <NavLink className={this.state.tabSelect === "2" ? "active" : ''}
                                                onClick={() => { this.setState({ tabSelect: "2" }) }}>
                                                Planes de Mantenimento
                                            </NavLink>
                                        </NavItem>
                                    }
                                    {this.state.selectid === "2" &&
                                        <NavItem>
                                            <NavLink className={this.state.tabSelect === "3" ? "active" : ''}
                                                onClick={() => { this.setState({ tabSelect: "3" }) }}>
                                                Ordenes de Trabajo
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                                <TabContent activeTab={this.state.tabSelect}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm={4}>
                                                <Dropzone onDrop={this.onFile} multiple={true} accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" disabled={this.state.edit}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="file-wrapper" ref={e => this.dropzoneFile = e}>
                                                            <div {...getRootProps({ className: 'file-message' })}>
                                                                <AiOutlineCloudUpload size={50} color={"#CCC"} />
                                                                <p>Arrastre aqui los documentos del activo</p>
                                                            </div>
                                                            <input {...getInputProps()} className={"form-control-file"} />
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </Col>
                                            <Col sm={8}>
                                                <Table hover responsive className="mb-0 c_table">
                                                    <thead>
                                                        <tr>
                                                            <TableHeaderlist title="Nombre del Archivo" />
                                                            <TableHeaderlist title=" " />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.oldFiles.map((item, index) => {
                                                                let classes = "zmdi zmdi-file-text w25 text-amber"
                                                                if (item.type.includes("image")) {
                                                                    classes = "zmdi zmdi-collection-folder-image"
                                                                }
                                                                if (item.type.includes("pdf")) {
                                                                    classes = "zmdi zmdi-collection-pdf w25 text-blush"
                                                                }
                                                                return (<tr key={index}>
                                                                    <TableItemlist
                                                                        item={
                                                                            <Row className="justify-content-center align-items-center">
                                                                                <Col md={10}>
                                                                                    <a href={item.fileUri}
                                                                                        style={{ color: "#000" }}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        <i className={classes}></i>
                                                                                        <span>{item.name}</span>
                                                                                    </a>
                                                                                </Col>
                                                                                <Col md={2}>
                                                                                    <DeleteOutlined onClick={() => this.showConfirm(item)} />
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    />
                                                                </tr>)
                                                            })
                                                        }
                                                        {
                                                            this.state.files.map((item, index) => {
                                                                let classes = "zmdi zmdi-file-text w25 text-amber"
                                                                if (item.type.includes("image")) {
                                                                    classes = "zmdi zmdi-collection-folder-image"
                                                                }
                                                                if (item.type.includes("pdf")) {
                                                                    classes = "zmdi zmdi-collection-pdf w25 text-blush"
                                                                }
                                                                return (<tr key={index}>
                                                                    <TableItemlist item={
                                                                        <Row className="justify-content-center align-items-center">
                                                                            <Col md={10}>
                                                                                <span><i className={classes}></i>{item.name}</span>
                                                                            </Col>
                                                                            <Col md={2}>
                                                                                <DeleteOutlined onClick={() => this.showConfirm(item)} />
                                                                            </Col>
                                                                        </Row>
                                                                    } /></tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col>
                                                <Table hover responsive className="mb-0 c_table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Descripción</th>
                                                            <th>Estado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.planesActivos.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td
                                                                        // scope="row"
                                                                        >{index + 1}</td>
                                                                        <td >{item.descripcion}</td>
                                                                        <td><Button title={item.estado < 5 ? "Desactivar Plan" : "Activar Plan"} onClick={() => this.showDialog(item.planMantenimientoId, item.status)} color={item.status < 5 ? "danger" : "success"} className="btn btn-sm"><i className={"zmdi " + (item.status < 5 ? "zmdi-block" : "zmdi-check")} /></Button></td>
                                                                    </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col>
                                                <Table hover responsive className="mb-0 c_table">
                                                    <thead>
                                                        <tr>
                                                            <th># Orden.</th>
                                                            <th>Tipo de Orden</th>
                                                            <th>Tipo de Mantenimiento</th>
                                                            <th>Fecha Emisión</th>
                                                            <th>Estado Actual</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.historialOrdenes.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{item.ordenTrabajoNumero ? `${('00000000' + item.ordenTrabajoNumero).slice(-8)}` : ''}</td>
                                                                        <td>{item.tipoOrden === 1 ? "Trabajo" : "Servicio"}</td>
                                                                        <td>{TIPO_MANTENIMIENTO[item.tipoMantenimiento]}</td>
                                                                        <td>{moment(item.fechaInicio).format("DD/MM/YYYY")}</td>
                                                                        <td>{ordenEstado[item.estado - 1]}</td>
                                                                        <td>
                                                                            <Button title="Ver Actividades" onClick={() => this.displayTasks(item.checkList, item.ordenTrabajoNumero)} color="default" size="sm"><i className="zmdi zmdi-format-list-bulleted" /></Button>
                                                                            <Button title={"Imprimir Orden"} onClick={() => this.printOrder(item)} size="sm"><i className="zmdi zmdi-print" /></Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </div>
                        </Card>
                        {!this.state.edit && <div>
                            <Button disabled={this.state.disabled} onClick={() => this.validate()} className="btn  badge-success btn-lg" type="submit">{this.state.isLoading ? <Spinner color="light" size="sm" /> : "Guardar"}</Button>
                            <Button onClick={() => history.goBack()} className="btn badge-default btn-lg">Cancelar</Button>
                        </div>}
                    </AvForm>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {!this.state.editing ?
                            <Button
                                onClick={() => this.reloadPage()}
                                //window.location.reload()}
                                // href="javascript:location.reload()"
                                color="link"
                            >{this.state.message} ¿Crear Otro?
                            </Button> :
                            <p>
                                {this.state.message}
                            </p>
                        }
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        No se pudo crear el activo
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <SweetAlert warning showCancel
                        confirmBtnText="Sí"
                        cancelBtnText="No"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="default"
                        focusCancelBtn
                        title={"¿Estas Seguro?"}
                        onConfirm={this.deactivatePlan}
                        onCancel={() => this.setState({ confirm: false })}
                        show={this.state.confirm}
                    >
                        {this.state.confirmMsg}
                    </SweetAlert>
                </section>
                <Modal isOpen={this.state.tareasPopup} toggle={() => this.setState({ tareasPopup: false })}>
                    <ModalHeader toggle={() => this.setState({ tareasPopup: false })}>Actividades en la orden #{this.state.numOrder}</ModalHeader>
                    <ModalBody>
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Table responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Descripción" />
                                            <TableHeaderlist title="Completada" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.actividadesList.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <TableItemlist item={(index + 1)} />
                                                        <TableItemlist item={item.descripcion} />
                                                        <TableItemlist item={item.checked && <i class="zmdi zmdi-check"></i>} />
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <ModalAnt
                    title=''
                    centered
                    open={this.state.open}
                    onOk={() => { }}
                    onCancel={() => this.setState({ open: false })}
                    width={1000}
                    okText={loading ? "Cargando..." : "Imprimir todo"}
                    cancelText="Cancelar"
                    okButtonProps={{
                        icon: loading ? <Spinner size='sm' /> : <PrinterOutlined style={{ display: 'inline-flex' }} />,
                        style: { backgroundColor: '#0c7ce6', color: '#fff', alignItems: 'center' },
                    }}
                    closable={false}
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }}

                >
                    <ReporteOrdenTrabajo data={this.state.dataReporte} show={false} />
                </ModalAnt>
            </Container>
        )
    }
}