import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import Api from "../modules/proveedor"
import { InputGroup, InputGroupAddon, InputGroupText, Input, Row, Col, Button, Alert, Pagination, PaginationItem, PaginationLink, Breadcrumb, BreadcrumbItem, Table } from 'reactstrap';
import history from '../history';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class Suppliers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            confirm: false,
            proveedorId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: ""
        }
        this.deleteProveedor = this.deleteProveedor.bind(this)
    }

    componentDidMount() {
        this.listProveedores(1);
    }

    async listProveedores(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.list({ page }).then(res => {
            if (res) {
                const response = res.data
                console.log("🚀 ~ file: Suppliers.js:62 ~ Suppliers ~ Api.list ~ response", response)
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {
                    data.push({
                        id: response[key].proveedorId,
                        codigo: response[key].persona.identificacion,
                        fullName: response[key].persona.nombre + " " + response[key].persona.apellido,
                        direccion: response[key].personaContacto,
                        city: response[key].persona.telefono,
                        province: response[key].tipoProveedor
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })
    }

    updateProveedor(id) {
        history.push('/createsuppliers', { id });
    }

    deleteProveedor = () => {
        const { proveedorId } = this.state;
        this.setState({ confirm: false, visible: true })
        //console.log(proveedorId)
        Api.delete({ id: proveedorId }).then((response) => {
            if (response) {
                this.setState({
                    visible: response.success,
                    warning: response.warning,
                    message: response.message,
                    confirm: false
                }, () => this.listProveedores())
            } else {
                this.setState({ error: true, confirm: false })
            }
        })
    }

    onDismiss() {
        this.setState({
            visible: false,
            error: false,
            warning: false
        })
    }

    showDialog = (value) => {
        this.setState({ confirm: true, proveedorId: value })
    }

    onSearch = (page) => {
        const { search } = this.state;
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        if (search !== "") {
            Api.search({ search, page }).then(res => {
                if (res) {
                    const response = res.data
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {
                        data.push({
                            id: response[key].proveedorId,
                            codigo: response[key].persona.identificacion,
                            fullName: response[key].persona.nombre + " " + response[key].persona.apellido,
                            direccion: response[key].personaContacto,
                            city: response[key].persona.telefono,
                            province: response[key].tipoProveedor
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listProveedores(page);
        }
    }

    render() {
        const { data } = this.state;
        return (
            <Container menuId="reservas" submenu="proveedores" inventoryToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="block-header">
                        <Row >
                            <Col lg={7} md={6} sm={12}>
                                <h2>Proveedores</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Inventario</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Proveedores</BreadcrumbItem>
                                </Breadcrumb>
                                <button className="btn btn-primary btn-icon btn-icon-mini mobile_menu" type="button"></button>
                            </Col>
                            <div class="col-lg-5 col-md-6 col-sm-12">
                                <a href="/createsuppliers" className="App-link">
                                    <button class="btn btn-success btn-icon float-right" type="button">
                                        <i class="zmdi zmdi-plus" />
                                    </button>
                                </a>
                                <InputGroup className="float-right">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i className="zmdi zmdi-search" /></InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text"
                                        onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                        className="form-control"
                                        placeholder="Busqueda..."
                                        value={this.state.search}
                                        onChange={(event) => this.setState({ search: event.target.value })} />
                                </InputGroup>
                            </div>
                        </Row>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="card">
                                <Table responsive hove className="mb-0 c_list c_table theme-color">
                                    <thead>
                                        <tr>
                                            <TableHeaderlist title="#" />
                                            <TableHeaderlist title="Código" />
                                            <TableHeaderlist title="Nombre" />
                                            <TableHeaderlist title="Persona de Contacto" />
                                            <TableHeaderlist title="telefono" />
                                            <TableHeaderlist title="Tipo" />
                                            <TableHeaderlist title="Accion" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((value, i) => {
                                                return (
                                                    <tr>
                                                        <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} />
                                                        <TableItemlist item={value.codigo} />
                                                        <TableItemlist item={value.fullName} />
                                                        <TableItemlist item={value.direccion} />
                                                        <TableItemlist item={value.city} />
                                                        <TableItemlist item={value.province} />
                                                        <div>
                                                            <Button onClick={() => this.updateProveedor(value.id)} className="btn btn-primary btn-sm"><i className="zmdi zmdi-edit" /></Button>
                                                            <Button onClick={() => this.showDialog(value.id)} className="btn btn-danger btn-sm"><i className="zmdi zmdi-delete" /></Button>
                                                        </div>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div class="justify-content-center">
                                    <Pagination className="pagination pagination-success mt-4">
                                        {
                                            this.state.pages.map((value, index) => {
                                                return (
                                                    <PaginationItem active={value === this.state.currentPage}>
                                                        <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                                    </PaginationItem>
                                                );
                                            })
                                        }
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Alert color="success" isOpen={this.state.visible} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="warning" isOpen={this.state.warning} toggle={() => this.onDismiss()}>
                        {this.state.message}
                    </Alert>
                    <Alert color="danger" isOpen={this.state.error} toggle={() => this.onDismiss()}>
                        Error al eliminar
                    </Alert>
                </section>
                <SweetAlert warning showCancel
                    confirmBtnText="Sí, eliminar"
                    cancelBtnText="No"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    focusCancelBtn
                    title={"¿Estas Seguro?"}
                    onConfirm={this.deleteProveedor}
                    onCancel={() => this.setState({ confirm: false })}
                    show={this.state.confirm}
                >
                    Ësta acción es irreversible.
                </SweetAlert>
            </Container>
        )
    }
}