import React from 'react';
import '../App.css';
import { Col, Row, Card, Label, Input, FormGroup } from 'reactstrap';
import Api from "../modules/activos"
import Select from 'react-select';
import User from "../modules/user"
import { AsyncPaginate } from "react-select-async-paginate";
import _ from "lodash"

const defaultAdditional = {
    page: 1
};

export default class AssestLocationMachineDetailsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: this.props.option,
            localizaciones: [],
            selectedOption: this.props.selected,
            users: [],
            userSel: this.props.userSel,
            localizacioError: "none",
            asignar: this.props.userSel !== null,
            asignarError: "none",
            radio: this.props.hijo,
            activoError: "none",
            activoPadre: this.props.activoPadre,
            currentActivo: this.props.currentActivo,
            edit: this.props.edit,
        }
    }

    componentDidMount() {
        this.getUsuarios()
    }

    loadOptions = async (search, prevOptions, { page }) => {
        let localizaciones = []
        if (search === "") search = "a";
        const res = await Api.search({ search, page });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                localizaciones.push({
                    value: response[key].localizacionId,
                    label: response[key].nombre + " [" + response[key].codigo + "]",
                    descripcion: response[key].localizacionDescripcion
                })
            }
        }
        const results = {
            options: localizaciones,
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    loadActivos = async (search, prevOptions, { page }) => {
        let activos = []
        if (search === "") search = "a";
        const res = await Api.listBySearch({ search, page, filtro: 1 });
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if (response) {
            for (let key in response) {
                activos.push({
                    value: response[key].activoId,
                    label: response[key].nombre + " [" + response[key].codigo + "]",
                    localizacion: response[key].localizacion,
                })
            }
        }
        const results = {
            options: _.filter(activos, (item) => item.value !== this.state.currentActivo),
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
        }
        return results;
    };

    getUsuarios() {
        let users = []
        User.list({ filtro: 1, count: 100 }).then((res) => {
            if (res) {
                const response = res.data
                for (let key in response) {
                    users.push({
                        value: response[key].usuarioId,
                        label: response[key].persona.nombre + " " + response[key].persona.apellido,
                        descripcion: response[key].localizacionDescripcion
                    })
                }
            }
        }).finally(() => {
            this.setState({ users })
        })
    }

    _onOptionChange(option) {
        let { esEquipo } = this.props;
        this.setState({
            checked: option
        }, () => esEquipo(option === "optionA" ? true : false));
    }

    handleChange = selectedOption => {
        if (selectedOption) {
            const { onChange } = this.props;
            this.setState(
                { selectedOption },
                () => onChange(selectedOption)
            );
        }
    };

    onActivoChange = activoPadre => {
        if (activoPadre) {
            const { onActivoChange } = this.props;
            this.setState(
                { activoPadre },
                () => onActivoChange(activoPadre)
            );
        }
    };


    handleUserChange = selectedOption => {
        const { onChangeUser } = this.props;
        this.setState(
            { userSel: selectedOption },
            () => onChangeUser(selectedOption)
        );

    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    validate = () => {
        const { selectedOption, asignar, userSel, radio, activoPadre } = this.state;
        this.setState({
            localizacioError: radio === "optionA" && selectedOption === null ? "block" : "none",
            activoError: radio === "optionB" && activoPadre === null ? "block" : "none",
            asignarError: asignar && userSel === null ? "block" : "none",
        })
        return [
            radio === "optionA" && selectedOption === null ? "block" : "none",
            asignar && userSel === null ? "block" : "none",
            asignar,
            radio === "optionB" && activoPadre === null ? "block" : "none",
        ];
    }

    onRadioChange(option) {
        const { esHijo } = this.props;
        if (option === "optionB") this.setState({ localizacioError: "none" })
        if (option === "optionA") this.setState({ activoError: "none" })
        this.setState({
            radio: option,
        }, () => esHijo(option === "optionB" ? true : false));
    }

    render() {
        const { radio } = this.state
        return (
            <Card className="card">
                <div className="header">
                    <h2><strong>Datos</strong> de Localización</h2>
                </div>
                <div className="body">
                    <Row className="clearfix">
                        <Col sm={6}>
                            <div className="radio">
                                <Input type="radio" name="r1" id="r1" onChange={this.onRadioChange.bind(this, 'optionA')} checked={this.state.radio === 'optionA'} disabled={this.state.edit} />
                                <Label htmlFor="r1">Éste activo está localizado en: </Label>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            border: this.state.localizacioError === "block" ? "1px solid #ced4da" : "none",
                                            borderRadius: ".25rem",
                                            borderColor: "#dc3545"
                                        }),
                                    }}
                                    additional={defaultAdditional}
                                    // ref={ref => { this.localizacionRef = ref; }}
                                    placeholder={"Escriba para buscar localización"}
                                    loadingMessage={() => "Buscando localizaciones.."}
                                    isDisabled={radio === "optionB" || this.state.edit}
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    loadOptions={this.loadOptions}
                                    onInputChange={this.handleInputChange}
                                    cacheOptions
                                    defaultOptions
                                    onBlur={() => { radio === "optionA" && this.state.selectedOption === null ? this.setState({ localizacioError: "block" }) : this.setState({ localizacioError: "none" }) }}
                                />
                                <div style={{ display: this.state.localizacioError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione la localización del activo</div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={6}>
                            <div className="radio">
                                <Input type="radio" name="r2" id="r2" onChange={this.onRadioChange.bind(this, 'optionB')} checked={this.state.radio === 'optionB'} disabled={this.state.edit} />
                                <Label htmlFor="r2">Éste activo forma parte de:</Label>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            border: this.state.activoError === "block" ? "1px solid #ced4da" : "none",
                                            borderRadius: ".25rem",
                                            borderColor: "#dc3545"
                                        }),
                                    }}
                                    additional={defaultAdditional}
                                    cacheOptions
                                    defaultOptions
                                    // ref={ref => { this.activoRef = ref; }}
                                    isDisabled={radio === "optionA" || this.state.edit}
                                    placeholder={"Escriba para buscar activo"}
                                    loadingMessage={() => "Buscando activos.."}
                                    value={this.state.activoPadre}
                                    onChange={this.onActivoChange}
                                    loadOptions={this.loadActivos}
                                    onBlur={() => { radio === "optionB" && this.state.activoPadre === null ? this.setState({ activoError: "block" }) : this.setState({ activoError: "none" }) }}
                                />
                                <div style={{ display: this.state.activoError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el activo padre</div>
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Label htmlFor="radio3">Clasificación del Equipo:</Label>
                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={6}>
                            <div className="radio">
                                <Input type="radio" name="radio1" id="radio1" onChange={this._onOptionChange.bind(this, 'optionA')} checked={this.state.checked === 'optionA'} disabled={this.state.edit} />
                                <Label htmlFor="radio1">Es un equipo</Label>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="radio">
                                <Input type="radio" name="radio2" id="radio2" onChange={this._onOptionChange.bind(this, 'optionB')} checked={this.state.checked === 'optionB'} disabled={this.state.edit} />
                                <Label htmlFor="radio2">Es una herramienta</Label>
                            </div>
                        </Col>
                    </Row>
                    {
                        this.state.checked === 'optionB' && (<Row className="clearfix">
                            <Col sm={6}>
                                <div className="checkbox" >
                                    <Input id="procheck1" type="checkbox" checked={this.state.asignar} onChange={() => this.setState({ asignar: !this.state.asignar })} disabled={this.state.edit} />
                                    <Label htmlFor="procheck1">Asignar herramienta a: </Label>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Select noOptionsMessage={() => 'Sin Resultados'}
                                        styles={{
                                            container: base => ({
                                                ...base,
                                                border: this.state.asignarError === "block" ? "1px solid #ced4da" : "none",
                                                borderRadius: ".25rem",
                                                borderColor: "#dc3545"
                                            }),
                                        }}
                                        placeholder={"-- Usuarios --"}
                                        value={this.state.userSel}
                                        onChange={this.handleUserChange}
                                        options={this.state.users}
                                        isDisabled={!this.state.asignar || this.state.edit}
                                        onBlur={() => { this.state.asignar && this.state.userSel === null ? this.setState({ asignarError: "block" }) : this.setState({ asignarError: "none" }) }}
                                    />
                                    <div style={{ display: this.state.asignarError, marginBottom: "1rem" }} className="invalid-feedback">Seleccione el usuario a asignarle herramiena</div>
                                </FormGroup>
                            </Col>
                        </Row>)
                    }
                </div>
            </Card>
        )
    }
}