import React, { useState, useEffect } from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const OrderStatus = ({ currentStatus, onChange }) => {
  const [dropdownOpen, setOpen] = useState(false)
  const [rol, setRol ] = useState(-1)

  const toggle = () => setOpen(!dropdownOpen)

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    const newRol = ((localStorage.getItem('user_rol')))
    setRol(newRol);
  },[]);

  const statusName = [
      "Abierta",
      "En Progreso",
      ...rol<3 ? ["Completada","No Completada"] : []
  ]

  const statusColor = [
      "warning",
      "primary",
      "success",
      "danger"
  ]

  const fullList = [
    "Abierta",
    "En Progreso",
    "Completada",
    "No Completada"
  ]

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={rol>=3 && currentStatus>2} >
      <DropdownToggle caret color={statusColor[currentStatus - 1]}>{fullList[currentStatus - 1]}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Estados</DropdownItem>
        {statusName.map((item, index) => (
          <DropdownItem key={index} disabled={item === statusName[currentStatus - 1]} onClick={()=> onChange(index+1)}>{item}</DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default OrderStatus