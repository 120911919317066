import Activos from '../services/activos'

export default {

  //create new season
  async list(data) {
    let activos;
    if (!data.page) data.page = 1
    try {
      activos = await Activos.list(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listOrders(data) {
    let activos;
    if (!data.page) data.page = 1
    try {
      activos = await Activos.listOrders(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listBySearch(data) {
    let activos;
    try {
      activos = await Activos.listBySearch(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listAll(data) {
    let activos;
    try {
      activos = await Activos.listAll(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listChildren(data) {
    let activos;
    try {
      activos = await Activos.listChildren(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async searchAll(data) {
    let activos;
    try {
      activos = await Activos.searchAll(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async search(data) {
    let activos;
    try {
      activos = await Activos.search(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listLocalizaciones(data) {
    let activos;
    try {
      if (!data) data = { page: 1 }
      activos = await Activos.listLocalizaciones(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async save(data) {
    let result;
    try {
      result = await Activos.save(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async saveLocalizacion(data) {
    let result;
    try {
      result = await Activos.saveLocalizacion(data)
    } catch (error) {
      console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async listTiposActivos() {
    let activos;
    try {
      activos = await Activos.listTiposActivos();
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listTiposActivosPath() {
    let activos;
    try {
      activos = await Activos.listTiposActivosPath();
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async createTipoActivo(data) {
    let activos;
    try {
      activos = await Activos.createTipoActivo(data);
    } catch (error) {
      console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async deleteTipoActivo(tipoActivoId) {
    let activos;
    try {
      activos = await Activos.deleteTipoActivo(tipoActivoId);
    } catch (error) {
      console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  //update
  async updateTipoActivo(data) {
    let activos;
    try {
      activos = await Activos.updateTipoActivo(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async searchTipoActivo(data) {
    let activos;
    try {
      activos = await Activos.searchTipoActivo(data);
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async listCategoriasLocalizacion() {
    let activos;
    try {
      activos = await Activos.listCategoriasLocalizacion();
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async get(data) {
    let activo;
    try {
      activo = await Activos.get(data.id);
    } catch (error) {
      //console.log(error)
      activo = false;
    }
    return activo ? activo.data : false;
  },
  async getLocalizacion(data) {
    let activo;
    try {
      activo = await Activos.getLocalizacion(data.id);
    } catch (error) {
      //console.log(error)
      activo = false;
    }
    return activo ? activo.data : false;
  },
  async update(data) {
    let result;
    try {
      result = await Activos.update(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async updateLocalizacion(data) {
    let result;
    try {
      result = await Activos.updateLocalizacion(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async delete(data) {
    let activo;
    try {
      activo = await Activos.delete(data.id);
    } catch (error) {
      //console.log(error)
      activo = false;
    }
    return activo ? activo.data : false;
  },
  async deleteLocalizacion(data) {
    let activo;
    try {
      activo = await Activos.deleteLocalizacion(data.id);
    } catch (error) {
      //console.log(error)
      activo = false;
    }
    return activo ? activo.data : false;
  },
  async upload(data) {
    let result;
    try {
      result = await Activos.upload(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async removeFile(data) {
    let result;
    try {
      result = await Activos.removeFile(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async listUnidadesMedicion() {
    let activos;
    try {
      activos = await Activos.listUnidadesMedicion();
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  },
  async getPlanes(data) {
    let result;
    try {
      result = await Activos.getPlanes(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async listClasificaciones() {
    let activos;
    try {
      activos = await Activos.listClasificaciones();
    } catch (error) {
      //console.log(error)
      activos = false;
    }
    return activos ? activos.data : false;
  }
}