import React, { useEffect, useState } from 'react';
import '../../App.css';
import TableHeaderlist from '../../components/TableHeaderList';
import TableItemlist from '../../components/TableItemList';
import moment from 'moment';
import { TIPO_MANTENIMIENTO } from "../../constants"
import AsyncSelect from 'react-select/async';
import NumberFormat from 'react-currency-format';
import Inventario from "../../modules/inventario"
import OrdenTrabajo from "../../modules/ordenTrabajo"
import { server } from "../../services/config"

import { Card, Col, Button, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
// import history from '../../history';
export default function ModalOrderJob(props) {
    const { show, onHide, data } = props;

    useEffect(() => {
        if (show) {
            console.log("===================> Modal Order Job <============================");
            // console.log(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const ordenEstado = [
        "Abierta",
        "En Progreso",
        "Completada",
        "No Completada"
    ]

    const [tareasPopup, setTareasPopup] = useState(false)
    const [tareas, setTareas] = useState([])
    const [numOrder, setNumOrder] = useState(null)
    const displayTasks = (list, ordenID) => {
        setTareasPopup(true)
        setNumOrder(ordenID)
        setTareas(list)
    }
    const [modalRepuestos, setModalRepuestos] = useState(false)

    const printOrder = (item) => {
        let ordenTrabajoId = item.ordenTrabajoId
        // let activo_id = item.activo.activoId
        // let plan_id = item?.planMantenimiento?.planMantenimientoId
        // let fecha = moment(item.fechaEmision).format("DD/MM/YYYY")
        // const tipoOT = item.tipoMantenimiento
        window.open(`${server}/report/orden/${ordenTrabajoId}`, "_blank")

        // props.dataReporst([{
        //     ordenTrabajoId: ordenTrabajoId,
        //     tipoOT: tipoOT,
        //     activo_id: activo_id,
        //     plan_id: tipoOT === 1 ? plan_id : -1,
        //     fechaOT: fecha
        // }])
    }

    const [productosList, setProductosList] = useState([]);
    const [productoSel, setProductoSel] = useState(null);
    const [cantidad, setCantidad] = useState(0);
    const [cantidadtext, setCantidadtext] = useState("");
    const [orderId, setOrderId] = useState(null);

    const onSearchChange = (selectedOption) => {
        // console.log("🚀 ~ file: ModalOrderJob.js:69 ~ onSearchChange ~ selectedOption", selectedOption)
        if (selectedOption) {
            setProductoSel(selectedOption);
        }
    };

    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }
        let productos = []
        Inventario.listProductoBySearch({ search: inputValue }).then((res) => {
            if (res) {
                const response = res.data;
                for (let key in response) {
                    productos.push({
                        label: `${response[key].nombre} (${response[key].codigo})`,
                        value: response[key].productoId
                    })
                }
            }

        }).finally(() => {
            return callback(productos);
        })
    };

    const agregarProducto = () => {
        if (productoSel != null) {
            console.log(orderId, productoSel)
            OrdenTrabajo.addProductItem({
                ordenTrabajo: {
                    ordenTrabajoId: orderId
                },
                producto: {
                    productoId: productoSel.value,
                    nombre: productoSel.label
                },
                cantidad
            }).then((res) => {
                console.log("🚀 ~ file: ModalOrderJob.js:107 ~ agregarProducto ~ res", res)
                if (res) {
                    productosList.push({
                        ordenTrabajoProductoId: res.ordenTrabajoProductoId,
                        producto: {
                            nombre: productoSel.label,
                        },
                        cantidad
                    })
                    setProductosList(productosList);
                    setProductoSel(null);
                    setCantidad(0);
                    setCantidadtext("");
                }
            })
        } else {
            alert("Debe seleccionar un producto")
        }
    }

    const removeProduct = (id, index) => {
        // console.log("🚀 ~ file: ModalOrderJob.js:126 ~ removeProduct ~ id", id)
        let newproductosList = [...productosList];
        OrdenTrabajo.removeProductItem({ id }).then((res) => {
            // console.log("🚀 ~ file: ModalOrderJob.js:129 ~ OrdenTrabajo.removeProductItem ~ res", res)
            if (res) {
                newproductosList.splice(index, 1);
                setProductosList(newproductosList);
            }
        })
    }

    return (
        <>
            <Modal isOpen={show} size="xl" backdrop className="p-0" >
                <ModalHeader toggle={() => { onHide() }}>
                    <div className="header">
                        <h6><strong>ORDENES </strong> DE TRABAJO</h6>
                    </div>
                </ModalHeader>
                <section className="content" style={{ width: '100%', margin: 0 }}>
                    <ModalBody>
                        <Card className="mb-3" style={{
                            maxHeight: 'calc(80vh)',
                            overflowY: 'auto',
                            // overflowX: 'auto',
                            // minHeight: 'calc(90vh)',
                        }}>
                            {data.length > 0 ?
                                <Table hover responsive className="mb-0 c_list c_table break_table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: 95 }}># Orden.</th>
                                            <th style={{ width: 190 }}>Responsable</th>
                                            <th style={{ width: 95 }}>Tipo</th>
                                            <th style={{ width: 140 }}>Mantenimiento</th>
                                            <th style={{ width: 105 }}>Inicio</th>
                                            <th style={{ width: 115 }}>Finalizacion</th>
                                            <th style={{ width: 110 }}>Estado</th>
                                            <th style={{ width: 150 }}>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.ordenTrabajoNumero !== null ? `${('00000000' + item.ordenTrabajoNumero).slice(-8)}` : 'N/A'}</td>
                                                        <td>{item.responsable ? item.responsable.nombre : item.proveedor ? item.proveedor.persona.nombre : 'No asigando'}</td>
                                                        <td>{item.tipoOrden === 1 ? "Trabajo" : "Servicio"}</td>
                                                        <td>{TIPO_MANTENIMIENTO[item.tipoMantenimiento]}</td>
                                                        <td>{item.fechaInicio !== null ? moment(item.fechaInicio).format("DD/MM/YYYY") : ''}</td>
                                                        <td>{item.fechaFinalizacion ? moment(item.fechaFinalizacion).format("DD/MM/YYYY") : 'N/A'}</td>
                                                        <td>{ordenEstado[item.estado - 1]}</td>
                                                        <td>
                                                            <Button title="Ver Actividades" onClick={() => displayTasks(item?.planMantenimiento?.partes[0]?.actividades, item.ordenTrabajoNumero)} color="default" size="sm"><i className="zmdi zmdi-format-list-bulleted" /></Button>
                                                            <Button title={"Imprimir Orden"} onClick={() => printOrder(item)} size="sm"><i className="zmdi zmdi-print" /></Button>
                                                            <Button title="Agregar Repuestos" color="default"
                                                                onClick={() => {
                                                                    setNumOrder(('00000000' + item.ordenTrabajoNumero).slice(-8))
                                                                    setOrderId(item.ordenTrabajoId)
                                                                    setProductosList(item.productos)
                                                                    setModalRepuestos(true)
                                                                }}
                                                                // onClick={() => setState({ modal: true, numOrder: value.numero, orderId: value.id, productosList: value.productosList })} 
                                                                size="sm"><i className="zmdi zmdi-collection-plus" /></Button>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table> :
                                <Col
                                    className="d-flex justify-content-center align-items-center"
                                >
                                    <p>
                                        No hay ordenes de trabajo
                                    </p>
                                </Col>
                            }
                        </Card>
                    </ModalBody>
                </section>
            </Modal>
            <Modal
                size="xl"
                isOpen={tareasPopup} toggle={() => setTareasPopup(!tareasPopup)}>
                <ModalHeader toggle={() => setTareasPopup(!tareasPopup)}>Actividades en la orden #{numOrder}</ModalHeader>
                <ModalBody>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Table responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <TableHeaderlist title="#" />
                                        <TableHeaderlist title="Descripción" />
                                        <TableHeaderlist title="Instrucciones" />
                                        <TableHeaderlist title="Completada" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tareas.map((item, index) => {
                                            {/* console.log("🚀 ~ file: ModalOrderJob.js:232 ~ tareas.map ~ item:", item) */ }
                                            return (
                                                <tr key={index}>
                                                    <TableItemlist item={(index + 1)} />
                                                    <TableItemlist item={item.descripcion} />
                                                    <TableItemlist item={item.comentario || item.procedimientos} />
                                                    <TableItemlist item={item.checked && <i class="zmdi zmdi-check"></i>} />
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalRepuestos} toggle={() => setModalRepuestos(false)} className={""}>
                <ModalHeader toggle={() => setModalRepuestos(false)}>Seleccione los repuestos/materiales utilizados en la orden #{numOrder}</ModalHeader>
                <ModalBody>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <FormGroup>
                                <AsyncSelect noOptionsMessage={() => 'Sin Resultados'}
                                    placeholder={"Escriba para buscar"}
                                    cacheOptions
                                    value={productoSel}
                                    onChange={(e) => onSearchChange(e)}
                                    loadOptions={loadOptions}
                                    defaultOptions={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <FormGroup>
                                <NumberFormat value={cantidadtext} customInput={Input} thousandSeparator={true} placeholder="Cantidad Utilizada"
                                    onValueChange={(values) => {
                                        const { formattedValue, value } = values;
                                        setCantidadtext(formattedValue);
                                        setCantidad(value);
                                    }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Table responsive>
                                <thead className="thead-light">
                                    <TableHeaderlist title="#" />
                                    <TableHeaderlist title="Descripción" />
                                    <TableHeaderlist title="Cantidad" />
                                    <TableHeaderlist title="" />
                                </thead>
                                <tbody>
                                    {
                                        productosList.map((item, index) => {
                                            console.log("🚀 ~ file: ModalOrderJob.js:291 ~ productosList.map ~ productosList", productosList)
                                            return (
                                                <tr>
                                                    <TableItemlist item={(index + 1)} />
                                                    <TableItemlist item={item.producto.nombre} />
                                                    <TableItemlist item={item.cantidad} />
                                                    <TableItemlist item={<Button onClick={() => removeProduct(item.ordenTrabajoProductoId, index)} color="link" size="sm" style={{ padding: "3px 10px", width: "30px" }} className="waves-effect waves-float btn-sm waves-red"><i className="zmdi zmdi-delete" /></Button>} />
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" className="waves-effect btn-round" onClick={() => agregarProducto()}>Agregar y Guardar</Button>{' '}
                    <Button color="danger" className="waves-effect" onClick={() => setModalRepuestos(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}