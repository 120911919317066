import React from 'react';
import Container from "../Container";
import Loading from "../home/Loading";
import '../App.css';
import TableHeaderlist from '../components/TableHeaderList';
import TableItemlist from '../components/TableItemList';
import Api from "../modules/inventario";
import moment from "moment";
import NumberFormat from 'react-currency-format';
import { Col, Row, Button, Card, Table, InputGroup, InputGroupAddon, Input, InputGroupText, Breadcrumb, BreadcrumbItem, Pagination, PaginationItem, PaginationLink, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";

const options = {
    place: "br",
    message: "Sin Resultados",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
export default class MovimientosInventarios extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tipoMovimientos: [],
            confirm: false,
            usuarioId: undefined,
            visible: false,
            error: false,
            disabled: false,
            warning: false,
            message: "",
            loading: true,
            totalPages: 0,
            currentPage: 0,
            pages: [],
            search: "",
            filter: "todos",
            fechaInicio: null,
            isOpenDate: false

        }
    }

    componentDidMount() {
        this.listMovimientos(1);
        // let tipoMovimientos;
        Api.listTipoMovimientos().then(res => {
            if (res) {
                this.setState({
                    tipoMovimientos: res
                })
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {

        });
    }

    async listMovimientos(page) {
        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.listMovimientos({ page }).then(res => {
            if (res) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {

                    data.push({
                        id: response[key].movimientoInventarioId,
                        descripcion: response[key].producto.nombre,
                        fecha: moment(response[key].fechaMovimiento).format("DD/MM/YYYY"),
                        costoActual: response[key].producto.costo,
                        existenciaActual: response[key].producto.existenciaActual,
                        ubicacion: response[key].ubicacion.descripcion,
                        cantidad: response[key].cantidad,
                        costo: <NumberFormat value={response[key].costo} displayType={'text'} thousandSeparator={true} prefix={'$'} />,
                        concepto: response[key].tipoMovimiento.descripcion
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        });

    }

    onSearch = (page) => {
        const { search } = this.state;
        this.setState({ filter: "todos" });
        let data = [], pages = [], totalPages = 0, currentPage = 0, fecha = null;
        if (search !== "") {
            Api.listMovimientosBySearch({ search, fecha, page }).then((res) => {
                if (res.success) {
                    const response = res.data;
                    totalPages = res.totalPages;
                    currentPage = res.currentPage;
                    for (let key in response) {

                        data.push({
                            id: response[key].movimientoInventarioId,
                            descripcion: response[key].producto.nombre,
                            fecha: moment(response[key].fechaMovimiento).format("DD/MM/YYYY"),
                            costoActual: response[key].producto.costo,
                            existenciaActual: response[key].producto.existenciaActual,
                            ubicacion: response[key].ubicacion.descripcion,
                            cantidad: response[key].cantidad,
                            costo: <NumberFormat value={response[key].costo} displayType={'text'} thousandSeparator={true} prefix={'$'} />,
                            concepto: response[key].tipoMovimiento.descripcion
                        })
                    }
                    if (totalPages === 0) this.refs.notify.notificationAlert(options);
                }
            }).catch((err) => {
                this.refs.notifyError.notificationAlert(options2);
            }).finally(() => {
                for (let i = 0; i < totalPages; i++) {
                    pages.push((i + 1));
                }
                this.setState({
                    data,
                    pages,
                    currentPage,
                    totalPages,
                    loading: false
                })
            })
        } else {
            this.listMovimientos(page);
        }
    };

    filtrar = (value, page, date) => {
        this.setState({ filter: value })
        let search = value ? value : "";
        let fecha = date ? moment(date).format("YYYY-MM-DD") : null;

        let data = [], pages = [], totalPages = 0, currentPage = 0;
        Api.listMovimientosBySearch({ search, fecha, page }).then((res) => {
            console.log(res)
            if (res.success) {
                const response = res.data;
                totalPages = res.totalPages;
                currentPage = res.currentPage;
                for (let key in response) {

                    data.push({
                        id: response[key].movimientoInventarioId,
                        descripcion: response[key].producto.nombre,
                        fecha: moment(response[key].fechaMovimiento).format("DD/MM/YYYY"),
                        costoActual: response[key].producto.costo,
                        existenciaActual: response[key].producto.existenciaActual,
                        ubicacion: response[key].ubicacion.descripcion,
                        cantidad: response[key].cantidad,
                        costo: <NumberFormat value={response[key].costo} displayType={'text'} thousandSeparator={true} prefix={'$'} />,
                        concepto: response[key].tipoMovimiento.descripcion
                    })
                }
                if (totalPages === 0) this.refs.notify.notificationAlert(options);
            }
        }).catch((err) => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            for (let i = 0; i < totalPages; i++) {
                pages.push((i + 1));
            }
            this.setState({
                data,
                pages,
                currentPage,
                totalPages,
                loading: false
            })
        })

    }
    render() {
        const { data, tipoMovimientos } = this.state;
        return (
            <Container menuId="reservas" submenu="stock" inventoryToggled>
                {this.state.loading && <Loading />}
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <section className="content">
                    <div className="block-header">
                        <div className="row justify-content-end">
                            <Col lg={7} md={6} sm={12} >
                                <h2>Movimientos</h2>
                                <Breadcrumb tag="ul" listTag="li" className="breadcrumb">
                                    <BreadcrumbItem className="breadcrumb-item App-link" tag="a" href="/home"><i className="zmdi zmdi-home" /> Home</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item" tag="span">Inventario</BreadcrumbItem>
                                    <BreadcrumbItem className="breadcrumb-item active" tag="li">Movimientos</BreadcrumbItem>
                                </Breadcrumb>
                                <Button color="primary" className="btn-icon btn-icon-mini mobile_menu" type="button"></Button>
                            </Col>
                            <Col lg={5} md={6} sm={12} >
                                <a href="/createstock" className="App-link">
                                    <Button color="success" className=" btn-icon float-right" type="button">
                                        <i class="zmdi zmdi-plus" />
                                    </Button>
                                </a>
                            </Col>
                            <Row className="align-items-center">
                                <Col sm={1} lg={1} md={1} className="mr-3">
                                    <ButtonDropdown isOpen={this.state.isOpen} toggle={() => { this.setState({ isOpen: !this.state.isOpen }); }}>
                                        <DropdownToggle className="button-boder"  >
                                            <i class="zmdi zmdi-filter-list"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>Filtrar Por Concepto</DropdownItem>
                                            <DropdownItem disabled={this.state.filter === "todos"} onClick={() => this.onSearch(1)}>Todos</DropdownItem>
                                            {
                                                tipoMovimientos.map((value, i) => {
                                                    return (
                                                        <tr onClick={() => this.filtrar(value.descripcion, 1, null)}>
                                                            <DropdownItem disabled={this.state.filter === value.descripcion}>{value.descripcion}</DropdownItem>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                                <Col sm={4} lg={4} md={4}>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="zmdi zmdi-calendar" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={this.state.fechaInicio !== null ? moment(this.state.fechaInicio).format("DD/MM/YYYY") : ""}
                                            className="datetimepicker"
                                            placeholder="Fecha"
                                            onClick={() => this.setState({ isOpenDate: true })}
                                        />
                                    </InputGroup>
                                    <div style={{ display: "none" }}>
                                        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={"es"}>
                                            <KeyboardDatePicker
                                                value={this.state.fechaInicio}
                                                open={this.state.isOpenDate}
                                                onOpen={() => this.setState({ isOpenDate: true })}
                                                onClose={() => this.setState({ isOpenDate: false })}
                                                onChange={(date) => [this.setState({ fechaInicio: date }), this.filtrar("", 1, date)]}
                                                label="Fecha"
                                                showTodayButton
                                                cancelLabel={"Cancelar"}
                                                okLabel={"Aceptar"}
                                                clearLabel={"Limpiar"}
                                                todayLabel={"HOY"}
                                                clearable
                                            />

                                        </MuiPickersUtilsProvider>
                                    </div>
                                </Col>
                                <Col className="mr-3">
                                    <InputGroup >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="zmdi zmdi-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text"
                                            onKeyUp={(event) => { if (event.keyCode === 13) this.onSearch(1) }}
                                            className="form-control"
                                            placeholder="Busqueda..."
                                            value={this.state.search}
                                            onChange={(event) => this.setState({ search: event.target.value })} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <Row className="clearfix">
                            <Col lg={12} >
                                <Card >
                                    <Table hover responsive className="mb-0 c_list c_table theme-color">
                                        <thead>
                                            <tr>
                                                <TableHeaderlist title="#" />
                                                <TableHeaderlist title="Nombre" />
                                                <TableHeaderlist title="Concepto" />
                                                <TableHeaderlist title="Ubicación" />
                                                <TableHeaderlist title="Costo" />
                                                <TableHeaderlist title="Cantidad" />
                                                <TableHeaderlist title="Fecha" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((value, i) => {
                                                    return (
                                                        <tr>
                                                            <TableItemlist item={((this.state.currentPage - 1) * 15) + (i + 1)} />
                                                            <TableItemlist item={value.descripcion} />
                                                            <TableItemlist item={value.concepto} />
                                                            <TableItemlist item={value.ubicacion} />
                                                            <TableItemlist item={value.costo} />
                                                            <TableItemlist item={value.cantidad} />
                                                            <TableItemlist item={value.fecha} />
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    <div class="justify-content-center">
                                        <Pagination className="pagination pagination-success mt-4">
                                            {
                                                this.state.pages.map((value, index) => {
                                                    return (
                                                        <PaginationItem active={value === this.state.currentPage}>
                                                            <PaginationLink href="#" onClick={() => this.onSearch(value)}>{value}</PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                })
                                            }
                                        </Pagination>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
            </Container>
        )
    }
}