import Reportes from '../services/reportes'

export default {
    
    //create new season
    async dashboard () {
      let result;
      try{
        const id = localStorage.getItem('id_token')
        result = await Reportes.dashboard({id});
      } catch(error){
        result = false;
      }
      return result ? result.data : false;
    },
    async solicitudes(data){
      let result;
      try{
        const id = localStorage.getItem('id_token')
        data.id = id;
        result = await Reportes.solicitudes(data);
      } catch(error){
        result = false;
      }
      return result ? result.data : false;
    }
}