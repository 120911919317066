import React from 'react';
import history from '../history';
import Authentication from "../modules/authentication"
import { Input, Button, Spinner } from 'reactstrap';
import moment from "moment"
import NotificationAlert from 'react-notification-alert';
import "react-notification-alert/dist/animate.css";
const options = {
    place: "br",
    message: "Usuario o Contraseña Incorrecta",
    type: "warning",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}
const options2 = {
    place: "br",
    message: "Error Inesperado",
    type: "danger",
    icon: "",
    autoDismiss: 5000,
    closeButton: true,
    zIndex: 9999,
}

export default class Login extends React.Component {

    state = {
        email: "",
        password: "",
        showPassword: false,
    }

    forgotPassword = () => {
        history.replace('/forgotpassword');
    }

    login = async () => {
        this.setState({ isLoading: true })
        const correo = this.state.email.toLowerCase();
        const clave = this.state.password;

        Authentication.login({ correo, clave }).then((response) => {
            if (response) {
                //navigate to the home route
                if (response.success)
                    history.replace('/home');
                else this.refs.notify.notificationAlert(options);
            } else {
                this.refs.notifyError.notificationAlert(options2);
            }
        }).catch(() => {
            this.refs.notifyError.notificationAlert(options2);
        }).finally(() => {
            this.setState({ isLoading: false })
        })

    }

    handlePwdKeyUp = (e) => {
        if (e.charCode === 13) {
            this.login();
        }
    }

    render() {
        return (
            <div className="theme-green">
                <NotificationAlert ref="notify" />
                <NotificationAlert ref="notifyError" />
                <div className="authentication">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <form className="card auth_form">
                                    <div className="header">
                                        <img className="logo" src="/logo512.png" alt="" />
                                        <h5>Inicio de Sesión</h5>
                                    </div>
                                    <div className="body">
                                        <div className="input-group mb-3">
                                            <Input type="text" className="form-control" placeholder="correo" value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} />
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="zmdi zmdi-account-circle" /></span>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <Input
                                                type={this.state.showPassword ? "text" : "password"}
                                                className="form-control"
                                                placeholder="contraseña"
                                                value={this.state.password}
                                                onChange={(event) => this.setState({ password: event.target.value })}
                                                onKeyPress={this.handlePwdKeyUp}

                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i
                                                        className={this.state.showPassword ? "zmdi zmdi-lock-open" : "zmdi zmdi-lock"}
                                                        style={{ color: "green" }}
                                                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                                    />
                                                </span>
                                            </div>
                                        </div>

                                        <Button color="success" onClick={this.login} className="btn-block waves-effect waves-light" >{this.state.isLoading ? <Spinner color="light" size="sm" /> : "INICIAR SESIÓN"}</Button>
                                        <div className="signin_with mt-3">
                                            <Button onClick={this.forgotPassword} color="link" className="App-link">¿Olvidaste la contraseña?</Button>
                                        </div>
                                    </div>
                                </form>
                                <div className="copyright text-center">
                                    <Button color="link" className="App-link"><span>© {moment().format("YYYY")} Desarrollado por</span> SIACOM</Button>
                                </div>
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <div className="card">
                                    <img src="assets/images/signin.svg" alt="Sign In" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
