import { server } from './config'
import client from './client'

export default {
	listBy (data,config = {}) {
		return client.get(`${server}/proveedores`)
	},
    list (data,config = {}) {
		return client.get(`${server}/proveedor?p=${data.page}`)
	},
	search (data,config = {}) {
		return client.get(`${server}/search/proveedor/${data.search}?p=${data.page}`)
	},
	listByTipo (data,config = {}) {
		return client.get(`${server}/proveedor/tipo/${data.id}`)
	},
	save(data,config = {}){
       return client.post(`${server}/proveedor`, data, config)
   },
   listRol (config = {}) {
		return client.get(`${server}/rol`)
	},
	get (proveedorId,config = {}) {
		return client.get(`${server}/proveedor/${proveedorId}`)
	},
	update(data,config = {}){
		//console.log(`${server}/proveedor`)
		//console.log(data,"data")
       return client.put(`${server}/proveedor`, data, config)
   },
   delete (proveedorId,config = {}) {
	return client.delete(`${server}/proveedor/${proveedorId}`)
},
}