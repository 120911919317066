import { server } from './config'
import client from './client'

export default {
	list(data, config = {}) {
		return client.get(`${server}/user?filtro=${data.filtro}&p=${data.page}&c=${data.count}`)
	},
	search(data, config = {}) {
		return client.get(`${server}/search/user/${data.search}?p=${data.page}`)
	},
	save(data, config = {}) {
		return client.post(`${server}/user`, data, config)
	},
	listRol(config = {}) {
		return client.get(`${server}/rol`)
	},
	get(usuarioId, config = {}) {
		return client.get(`${server}/user/${usuarioId}`)
	},
	update(data, config = {}) {
		return client.put(`${server}/user`, data, config)
	},
	delete(usuarioId, config = {}) {
		return client.delete(`${server}/user/${usuarioId}`)
	},
	upload(data, config = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
		}
	}) {
		return client.post(`${server}/upload`, data, config)
	},
	newUser(data, config = {}) {
		return client.post(`${server}/tecnico`, data, config)
	},
	getTechs(config = {}) {
		return client.get(`${server}/tecnicos`)
	}
}