import Plan from '../services/plan'


export default {

  async save(data) {
    let result;
    try {
      result = await Plan.save(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async edit(data) {
    let result;
    try {
      result = await Plan.edit(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async update(data) {
    let result;
    try {
      result = await Plan.update(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async delete(data) {
    let result;
    try {
      result = await Plan.delete(data.id);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getPlanById(data) {
    let result;
    try {
      result = await Plan.getPlanById(data.id);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async generateOrder(data) {
    let result;
    try {
      result = await Plan.generateOrder(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async validateOrder(data) {
    let result;
    try {
      result = await Plan.validateOrder(data)
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async listUserByRol(data) {
    let users;
    try {
      users = await Plan.listUserByRol(data);
    } catch (error) {
      //console.log(error)
      users = false;
    }
    return users ? users.data : false;
  },
  async listActivoBySearch(data) {
    let users;
    try {
      users = await Plan.listActivoBySearch(data);
    } catch (error) {
      //console.log(error)
      users = false;
    }
    return users ? users.data : false;
  },
  async calendar(data) {
    let planes;
    try {
      planes = await Plan.calendar(data);
    } catch (error) {
      //console.log(error)
      planes = false;
    }
    return planes ? planes.data : false;
  },
  async list(data) {
    let result;
    try {
      result = await Plan.list(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async search(data) {
    let result;
    try {
      result = await Plan.search(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async listUnidadesMedicion() {
    let result;
    try {
      result = await Plan.listUnidadesMedicion();
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getChildrenTree(data) {
    let result;
    try {
      result = await Plan.getChildrenTree(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getTree() {
    let result;
    try {
      result = await Plan.getTree();
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getTreeCategorias(data) {
    let result;
    try {
      result = await Plan.getTreeCategorias(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getTreeActivos(data) {
    let result;
    try {
      result = await Plan.getTreeActivos(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getMantenimientosFromTree(data) {
    let result;
    try {
      result = await Plan.getMantenimientosFromTree(data);
    } catch (error) {
      console.log(error)
      result = false;
    }
    return result ? result.data : false;
  },
  async getReportMantenimientos(data) {
    let result;
    try {
      result = await Plan.getReportMantenimientos(data);
    } catch (error) {
      //console.log(error)
      result = false;
    }
    return result ? result.data : false;
  }
}