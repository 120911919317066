import Inventario from '../services/inventario'

export default {
    
    //create new season
    async list (data) {
      let inventarios;
      try{
        inventarios = await Inventario.list(data);
      } catch(error){
        //console.log(error)
        inventarios = false;
      }
      return inventarios ? inventarios.data : false;
    },
    async listMovimientos (data) {
      let inventarios;
      try{
        inventarios = await Inventario.listMovimientos(data);
      } catch(error){
        //console.log(error)
        inventarios = false;
      }
      return inventarios ? inventarios.data : false;
    },
    
    async save(data){
      let result;
      try {
        result = await Inventario.save(data)
        //console.log(result,"result")
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async saveMovimiento(data){
      let result;
      try {
        result = await Inventario.saveMovimiento(data)
        //console.log(result,"result")
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async listTipoMovimientos () {
      let inventarios;
      try{
        inventarios = await Inventario.listTipoMovimientos();
      } catch(error){
        //console.log(error)
        inventarios = false;
      }
      return inventarios ? inventarios.data : false;
    },
    async listCategorias () {
      let inventarios;
      try{
        inventarios = await Inventario.listCategorias();
      } catch(error){
        //console.log(error)
        inventarios = false;
      }
      return inventarios ? inventarios.data : false;
    },
    async listUbicaciones () {
      let inventarios;
      try{
        inventarios = await Inventario.listUbicaciones();
      } catch(error){
        //console.log(error)
        inventarios = false;
      }
      return inventarios ? inventarios.data : false;
    },
    async get (data) {
      let inventario;
      try{
        inventario = await Inventario.get(data.id);
      } catch(error){
        //console.log(error)
        inventario = false;
      }
      return inventario ? inventario.data : false;
    },
    async listProductoBySearch (data) {
      let inventario;
      try{
        if(!data.page) data.page = 1
        inventario = await Inventario.listProductoBySearch(data);
      } catch(error){
        //console.log(error)
        inventario = false;
      }
      return inventario ? inventario.data : false;
    },
    async listMovimientosBySearch (data) {
      let inventario;
      try{
        inventario = await Inventario.listMovimientosBySearch(data);
      } catch(error){
        //console.log(error)
        inventario = false;
      }
      return inventario ? inventario.data : false;
    },
        
    async update(data){
      let result;
      try {
        result = await Inventario.update(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    },
    async delete (data) {
      let inventario;
      try{
        inventario = await Inventario.delete(data.id);
      } catch(error){
        //console.log(error)
        inventario = false;
      }
      return inventario ? inventario.data : false;
    },
    async upload(data)
    {
      let result;
      try {
        result = await Inventario.upload(data)
      } catch (error) {
        //console.log(error)
        result = false;
      }
      return result ? result.data : false;
    }
}