import React from 'react';
import '../App.css';
import { AsyncPaginate } from "react-select-async-paginate";
import {  Col, FormGroup,Row } from 'reactstrap';
import Api from "../modules/activos"
import _ from "lodash"
const defaultAdditional = {
    page: 1
  };

export default class AssestLocationDetailsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: this.props.option,
            localizaciones : [],
            selectedOption: this.props.selected,
            currentLocalizacion: this.props.currentLocalizacion,
            localizacioError: "none",
            edit: this.props.edit,
        }
    }

    _onOptionChange(option) {
        const { onCheckedChange } = this.props;
        if(option==="optionB") this.setState({localizacioError: "none"})
        this.setState({
            checked: option,
        }, () => onCheckedChange(option==="optionA"));
    }

    handleChange = selectedOption => {
        if (selectedOption) {
            const { onChange } = this.props;
            this.setState(
              { selectedOption },
              () => onChange(selectedOption)
            );
        }
    };

    
    loadOptions = async(search, prevOptions, { page }) => {  
        let localizaciones = []
        if(search==="")search="a";
        const res = await Api.search({search , page});
        const response = res.data
        const totalPages = res.totalPages;
        const currentPage = res.currentPage;
        if(response)
        {
            for(let key in response)
            {
                localizaciones.push({
                    value : response[key].localizacionId,
                    label : response[key].nombre + " ["+response[key].codigo+"]",
                    descripcion: response[key].localizacionDescripcion
                })
            }
        }
        const results = {
            options: _.filter(localizaciones,(item)=> item.value !== this.state.currentLocalizacion),
            hasMore: currentPage !== totalPages,
            additional: {
                page: page + 1
            }
            }
        return results;        
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    validate = () =>{
        const { selectedOption, checked } = this.state;
        this.setState({
            localizacioError: checked==="optionA" && selectedOption === null ? "block" : "none",
        })
        return checked==="optionA" && selectedOption === null ? "block" : "none";
    }

    render(){
        const { checked } = this.state
        return(
        <div className="card">
            <div className="header">
                <h2><strong>Datos</strong> de Localización</h2>
            </div>
            <div className="body">
                    <Row className="clearfix">
                        <Col sm={6}>
                            <div className="radio">
                                <input type="radio" name="radio1" id="radio1" onChange={this._onOptionChange.bind(this, 'optionA')} checked={this.state.checked === 'optionA'} disabled={this.state.edit}/>
                                <label htmlFor="radio1">Esta instalación forma parte de:</label>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <AsyncPaginate noOptionsMessage={() => 'Sin Resultados'}
                                    styles={{
                                        container: base => ({
                                            ...base,
                                            border: this.state.localizacioError === "block" ? "1px solid #ced4da": "none",
                                            borderRadius: ".25rem",
                                            borderColor: "#dc3545" 
                                        }),
                                    }}
                                    additional={defaultAdditional}
                                    loadingMessage={() => "Buscando localizaciones.."}  
                                    // ref={ref => {this.localizacionRef = ref;}}
                                    isDisabled={checked==="optionB" || this.state.edit}
                                    placeholder={"Escriba para buscar localización"}
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange} 
                                    loadOptions={this.loadOptions}
                                    cacheOptions
                                    defaultOptions
                                    onInputChange={this.handleInputChange}
                                    onBlur={()=> {checked==="optionA" && this.state.selectedOption===null ? this.setState({localizacioError: "block"}) : this.setState({localizacioError: "none"})}}
                                />
                                <div style={{display: this.state.localizacioError,marginBottom: "1rem"}}className="invalid-feedback">Seleccione la localización del activo</div>
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row className="clearfix">
                        <Col sm={12}>
                            <div className="radio">
                                <input type="radio" name="radio2" id="radio2" onChange={this._onOptionChange.bind(this, 'optionB')} checked={this.state.checked === 'optionB'} disabled={this.state.edit}/>
                                <label htmlFor="radio2">Esta instalación no forma parte de otra Localización</label>
                            </div>
                        </Col>

                    </Row>
                    
            </div>
        </div>
        )
    }
}